<div class="page-banner-area ptb-25" [style]="background">
    <div class="container">
        <div class="page-banner-content text-center">
            <h1>Dorsais</h1>
            <ul class="ps-0 mb-0 list-unstyled">
                <li class="d-inline-block position-relative"><a routerlink="/" href="/">Inicio</a></li>
                <li class="d-inline-block position-relative">Dorsais</li>
            </ul>
        </div>
    </div>
</div>
<div class="p-5">
    <div class="position-relative bg-ig-primary overflow-hidden">
        <div
            class="container position-relative zi-2 content-space-t-3 content-space-t-lg-4 content-space-b-2 content-space-b-md-3">
            <div class="check-dorsal" *ngIf="event.showBibs">
                <h3>Xa podes consultar o teu dorsal</h3>
                <a routerLink="/bibs" class="default-btn out default-btn-sm">Dorsais</a>
            </div>

            <div class="bib-content" [innerHtml]="event.bibsWeb"></div>

            <div class="row align-items-lg-center" *ngIf="event && event.id == 'rVm8Y9aN'">
                <google-map class="google-map-container" [center]="center" [zoom]="zoom">
                    <map-marker #marker="mapMarker" *ngFor="let store of stores; let i = index" [position]="store"
                        (mapClick)="openInfoWindow(marker, store)">
                    </map-marker>
                    <map-info-window>{{selectedStore?.name}}<br /> {{selectedStore?.address}}</map-info-window>
                </google-map>
            </div>
        </div>
    </div>
</div>