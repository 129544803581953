<div class="page-banner-area ptb-25" [style]="background">
  <div class="container">
    <div class="page-banner-content text-center">
      <h1>Contacto</h1>
      <ul class="ps-0 mb-0 list-unstyled">
        <li class="d-inline-block position-relative"><a routerlink="/" href="/">Inicio</a></li>
        <li class="d-inline-block position-relative">Contacto</li>
      </ul>
    </div>
  </div>
</div>
<div class="p-5">
  <!-- End Hero -->
  <!-- Contact Form -->
  <div class="container content-space-2 content-space-lg-3">
    <div class="mx-auto" style="max-width: 50rem;">
      <!-- Card -->
      <div class="contact-form">
        <!-- Heading -->
        <div class="text-center mb-5 mb-md-9">
          <h2>Cóntanos que che preocupa</h2>
          <p>Necesitas información sobre a carreira, inscrición ou outro tema? Este é o lugar axeitado para
            preguntalo.</p>
        </div>
        <!-- End Heading -->

        <!-- Form -->
        <form [formGroup]="contactForm">
          <div class="row gx-3">
            <div class="col-sm-6">
              <!-- Form -->
              <div class="mb-3">
                <label class="form-label" for="name">Nome</label>
                <input type="text" class="form-control form-control-lg" name="name" id="name" formControlName="name"
                  placeholder="Nome" aria-label="Nome" required />
              </div>
              <!-- End Form -->
            </div>
            <!-- End Col -->

            <div class="col-sm-6">
              <!-- Form -->
              <div class="mb-3">
                <label class="form-label" for="lastname">Apelidos</label>
                <input type="text" class="form-control form-control-lg" name="lastname" id="lastname"
                  formControlName="lastname" placeholder="Apelidos" aria-label="Apelidos" required />
              </div>
              <!-- End Form -->
            </div>
            <!-- End Col -->
          </div>
          <!-- End Row -->

          <div class="row gx-3">
            <div class="col-sm-6">
              <!-- Form -->
              <div class="mb-3">
                <label class="form-label" for="email">Email</label>
                <input type="email" class="form-control form-control-lg" name="email" id="email" formControlName="email"
                  placeholder="oteuemail@web.com" aria-label="Email" required />
              </div>
              <!-- End Form -->
            </div>
            <!-- End Col -->

            <div class="col-sm-6">
              <!-- Form -->
              <div class="mb-3">
                <label class="form-label" for="phone">Teléfono <span
                    class="form-label-secondary">(Opcional)</span></label>
                <input type="text" class="form-control form-control-lg" name="phone" id="phone" formControlName="phone"
                  placeholder="+34 600 600 600" aria-label="+xx xxx xxx xxx" />
              </div>
              <!-- End Form -->
            </div>
            <!-- End Col -->
          </div>
          <!-- End Row -->

          <!-- Form -->
          <div class="mb-3">
            <label class="form-label" for="message">Mensaxe</label>
            <textarea class="form-control form-control-lg" name="message" id="message" formControlName="message"
              placeholder="Quero recibir información sobre ..." aria-label="Mensaxe" rows="4" required></textarea>
          </div>
          <!-- End Form -->

          <div class="d-grid">
            <button type="submit" class="default-btn" [disabled]="sending" (click)="send()">{{sending ?
              "Enviando..." : "Enviar"}}</button>
          </div>
          <div class="text-center" *ngIf="sent">
            <p class="form-text">Mensaxe enviada correctamente.</p>
          </div>
          <div class="text-center">
            <p class="form-text">Contactaremos contigo o máis rápido posible.</p>
          </div>
        </form>
        <!-- End Form -->

      </div>
      <!-- End Card -->
    </div>
  </div>
  <!-- End Contact Form -->
</div>