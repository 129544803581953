<main id="content" role="main">
    <!-- Hero -->
    <div class="bg-img-start" style="background-image: url(./assets/svg/components/card-11.svg);">
        <div class="container content-space-t-3 content-space-t-lg-5 content-space-b-2">
            <div class="w-md-75 w-lg-50 text-center mx-md-auto">
                <h1 class="text-primary">Política de Cookies</h1>
                <p class="text-light">Actualizado a 12 de Maio de 2022</p>
            </div>
        </div>
    </div>
    <!-- End Hero -->

    <!-- Content -->
    <div class="container content-space-1 content-space-md-3">
        <div class="mb-7">
            <p>Una cookie es un pequeño fichero de texto que se almacena en su navegador cuando visita casi cualquier
                página web. Su utilidad es que la web sea capaz de recordar su visita cuando vuelva a navegar por esa
                página. Las cookies suelen almacenar información de carácter técnico, preferencias personales,
                personalización de contenidos, estadísticas de uso, enlaces a redes sociales, acceso a cuentas de
                usuario, etc. El objetivo de la cookie es adaptar el contenido de la web a su perfil y necesidades, sin
                cookies los servicios ofrecidos por cualquier página se verían mermados notablemente.</p>
        </div>

        <div id="informacion" class="mb-7">
            <h4>¿Qué información almacena una cookie?</h4>

            <p>Las cookies no suelen almacenar información sensible sobre usted, como tarjetas de crédito o datos
                bancarios, fotografías, su DNI o información personal, etc. Los datos que guardan son de carácter
                técnico, preferencias personales, personalización de contenidos, etc.</p>

            <p>El servidor web no le asocia a usted como persona si no a su navegador web. De hecho, si usted navega
                habitualmente con Internet Explorer y prueba a navegar por la misma web con Firefox o Chrome verá que la
                web no se da cuenta que es usted la misma persona porque en realidad está asociando al navegador, no a
                la persona.
            </p>

            <p>¿Qué tipo de cookies existen?</p>

            <ul>
                <li>Cookies técnicas: Son las más elementales y permiten, entre otras cosas, saber cuándo está navegando
                    un humano o una aplicación automatizada, cuándo navega un usuario anónimo y uno registrado, tareas
                    básicas para el funcionamiento de cualquier web dinámica.</li>
                <li>Cookies de análisis: Recogen información sobre el tipo de navegación que está realizando, las
                    secciones que más utiliza, productos consultados, franja horaria de uso, idioma, etc.</li>
                <li>Cookies publicitarias: Muestran publicidad en función de su navegación, su país de procedencia,
                    idioma, etc.</li>
            </ul>

        </div>

        <div id="propias-terceros" class="mb-7">
            <h4>¿Qué son las cookies propias y las de terceros?</h4>

            <p>Las cookies propias son las generadas por la página que está visitando y las de terceros son las
                generadas por servicios o proveedores externos como Facebook, Twitter, Google, etc.</p>
        </div>

        <div id="utilizadas" class="mb-7">
            <h4>Cookies utilizadas en este sitio web</h4>

            <p>Siguiendo las directrices de la Agencia Española de Protección de Datos procedemos a detallar el uso de
                cookies que hace esta web con el fin de informarle con la máxima exactitud posible.</p>

            <p>Este sitio web utiliza las siguientes cookies propias:</p>

            <ul>
                <li>Cookies de sesión, para garantizar que los usuarios que escriban comentarios en el blog sean humanos
                    y no aplicaciones automatizadas. De esta forma se combate el spam.</li>
            </ul>

            <p>Este sitio web utiliza las siguientes cookies de terceros:
            </p>

            <ul>
                <li>Google Analytics: Almacena cookies para poder elaborar estadísticas sobre el tráfico y volumen de
                    visitas de esta web. Al utilizar este sitio web está consintiendo el tratamiento de información
                    acerca de usted por Google. Por tanto, el ejercicio de cualquier derecho en este sentido deberá
                    hacerlo comunicando directamente con Google.</li>
                <li>Redes sociales: Cada red social utiliza sus propias cookies para que usted pueda pinchar en botones
                    del tipo Me gusta o Compartir.</li>
            </ul>
        </div>

        <div id="eliminar" class="mb-7">
            <h4>¿Se pueden eliminar las cookies?</h4>

            <p>Sí. No sólo eliminar, también bloquear, de forma general o particular para un dominio específico.</p>

            <p>Para eliminar las cookies de un sitio web debe ir a la configuración de su navegador y allí podrá buscar
                las asociadas al dominio en cuestión y proceder a su eliminación.
            </p>
        </div>

        <div id="navegadores" class="mb-7">
            <h4>Configuración de cookies para los navegadores más populares</h4>

            <p>
                A continuación le indicamos cómo acceder a una cookie determinada del navegador Chrome. Nota: estos
                pasos pueden variar en función de la versión del navegador:
            </p>

            <ol>
                <li>Vaya a Configuración o Preferencias mediante el menú Archivo o bien pinchando el icono de
                    personalización que aparece arriba a la derecha.</li>
                <li>Verá diferentes secciones, pinche la opción Mostrar opciones avanzadas.</li>
                <li>Vaya a Privacidad, Configuración de contenido.</li>
                <li>Seleccione Todas las cookies y los datos de sitios.</li>
                <li>Aparecerá un listado con todas las cookies ordenadas por dominio. Para que le sea más fácil
                    encontrar las cookies de un determinado dominio introduzca parcial o totalmente la dirección en el
                    campo Buscar cookies.</li>
                <li>Tras realizar este filtro aparecerán en pantalla una o varias líneas con las cookies de la web
                    solicitada. Ahora sólo tiene que seleccionarla y pulsar la X para proceder a su eliminación.</li>
            </ol>

            <p>Para acceder a la configuración de cookies del navegador Internet Explorer siga estos pasos (pueden
                variar en función de la versión del navegador):</p>

            <ol>
                <li>Vaya a Herramientas, Opciones de Internet</li>
                <li>Haga click en Privacidad.</li>
                <li>Mueva el deslizador hasta ajustar el nivel de privacidad que desee.</li>
            </ol>

            <p>Para acceder a la configuración de cookies del navegador Firefox siga estos pasos (pueden variar en
                función de la versión del navegador):</p>

            <ol>
                <li>Vaya a Opciones o Preferencias según su sistema operativo.</li>
                <li>Haga click en Privacidad.</li>
                <li>En Historial elija Usar una configuración personalizada para el historial.</li>
                <li>Ahora verá la opción Aceptar cookies, puede activarla o desactivarla según sus preferencias.</li>
            </ol>

            <p>Para acceder a la configuración de cookies del navegador Safari para OSX siga estos pasos (pueden variar
                en función de la versión del navegador):</p>

            <ol>
                <li>Vaya a Preferencias, luego Privacidad.</li>
                <li>En este lugar verá la opción Bloquear cookies para que ajuste el tipo de bloqueo que desea realizar.
                </li>
            </ol>


            <p>Para acceder a la configuración de cookies del navegador Safari para iOS siga estos pasos (pueden variar
                en función de la versión del navegador):</p>
            <ol>
                <li>Vaya a Ajustes, luego Safari.</li>
                <li>Vaya a Privacidad y Seguridad, verá la opción Bloquear cookies para que ajuste el tipo de bloqueo
                    que
                    desea realizar.</li>
            </ol>

            <p>Para acceder a la configuración de cookies del navegador para dispositivos Android siga estos pasos
                (pueden variar en función de la versión del navegador):</p>
            <ol>
                <li>Ejecute el navegador y pulse la tecla Menú, luego Ajustes.</li>
                <li>Vaya a Seguridad y Privacidad, verá la opción Aceptar cookies para que active o desactive la
                    casilla.
                </li>
            </ol>

            <p>Para acceder a la configuración de cookies del navegador para dispositivos Windows Phone siga estos pasos
                (pueden variar en función de la versión del navegador):</p>
            <ol>
                <li>Abra Internet Explorer, luego Más, luego Configuración</li>
                <li>Ahora puede activar o desactivar la casilla Permitir cookies.</li>
            </ol>

        </div>

        <div id="desactivacion" class="mb-7">
            <h4>Desactivación o eliminación de cookies</h4>
            <p>En cualquier momento podrá ejercer su derecho de desactivación o eliminación de cookies de este sitio
                web. Estas acciones se realizan de forma diferente en función del navegador que esté usando.</p>
        </div>

        <div id="notas" class="mb-7">
            <h4>Notas adicionales</h4>
            <ul>
                <li>Ni esta web ni sus representantes legales se hacen responsables ni del contenido ni de la veracidad
                    de las políticas de privacidad que puedan tener los terceros mencionados en esta política de
                    cookies.</li>
                <li>Los navegadores web son las herramientas encargadas de almacenar las cookies y desde este lugar debe
                    efectuar su derecho a eliminación o desactivación de las mismas. Ni esta web ni sus representantes
                    legales pueden garantizar la correcta o incorrecta manipulación de las cookies por parte de los
                    mencionados navegadores.</li>
                <li>En algunos casos es necesario instalar cookies para que el navegador no olvide su decisión de no
                    aceptación de las mismas.</li>
                <li>En el caso de las cookies de Google Analytics, esta empresa almacena las cookies en servidores
                    ubicados en Estados Unidos y se compromete a no compartirla con terceros, excepto en los casos en
                    los que sea necesario para el funcionamiento del sistema o cuando la ley obligue a tal efecto. Según
                    Google no guarda su dirección IP. Google Inc. es una compañía adherida al Acuerdo de Puerto Seguro
                    que garantiza que todos los datos transferidos serán tratados con un nivel de protección acorde a la
                    normativa europea. Puede consultar información detallada a este respecto en <a
                        href="http://safeharbor.export.gov/companyinfo.aspx?id=16626" target="_blank">este enlace</a>.
                    Si desea información sobre el uso que Google da a las cookies <a
                        href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=es&amp;csw=1"
                        target="_blank">le adjuntamos este otro enlace</a>.</li>
                <li>Para cualquier duda o consulta acerca de esta política de cookies no dude en comunicarse con
                    nosotros a través de la sección de contacto.</li>
            </ul>

        </div>

    </div>
    <!-- End Content -->
</main>