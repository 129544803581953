export enum RaceStatus {
    Draft = "draft",
    Published = "published",
    Disabled = "disabled"
}

export class RaceStatusType {
    label: string;
    value: RaceStatus;
    static types: RaceStatusType[] = [
        { value: RaceStatus.Draft, label: 'Borrador' },
        { value: RaceStatus.Published, label: 'Publicada' },
        { value: RaceStatus.Disabled, label: 'Desactivada' }
    ]
    static mapStatus(status: RaceStatus): string {
        return this.types.filter(t => t.value == status).map<string>(f => f.label)[0];
    }
}

