import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpParamsBuilder } from 'src/shared/http-params.builder';

@Injectable()
export class CircuitsService {

    version = "api-version=2";

    constructor(private http: HttpClient) { }

    private buildUrlCircuit(circuitId: string): string {
        return `${environment.api}/circuits/${circuitId}`;
    }

    private getFilterParams(event: LazyLoadEvent): HttpParams {
        let params = HttpParamsBuilder.fromLazyEvent(event);

        return params;
    }

    /**** Circuits****/
    getCircuitResults(circuitId: string, event: LazyLoadEvent) {
        let params = HttpParamsBuilder.fromLazyEvent(event);
        return this.http.get<any>(`${this.buildUrlCircuit(circuitId)}/results`, {
            params: params
        })
    }
    getCategoriesByCircuitResults(circuitId: string, race?: string) {
        return this.http.get<string[]>(`${this.buildUrlCircuit(circuitId)}/categories` + (race ? '?race=' + race : ''));
    }
    getRacesByCircuit(circuitId: string) {
        return this.http.get<string[]>(`${this.buildUrlCircuit(circuitId)}/races`);
    }
    getCompetitionsByCircuit(id: string): Observable<any> {
        return this.http.get<any>(`${this.buildUrlCircuit(id)}/competitions`);
    }

    getCircuitNews(id: string): Observable<any> {
        return this.http.get<any>(`${this.buildUrlCircuit(id)}/news`);
    }

    getNews(newsId: string): Observable<any> {
        return this.http.get<any>(`${this.buildUrlCircuit(newsId)}`);
    }
}
