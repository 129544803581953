import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { saveAs } from 'file-saver';
import { DomSanitizer } from '@angular/platform-browser';
import { EventModel } from 'src/app/core/models/event';
import { ResultModel } from 'src/app/core/models/result';
import { EventsService } from 'src/app/services/events.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-runner-result',
  templateUrl: './runner-result.component.html',
  styleUrls: ['./runner-result.component.scss']
})

export class RunnerResultComponent implements OnInit {

  eventId: string;
  bib: string;
  result: ResultModel;
  loading: boolean;
  event: EventModel;
  saving: boolean;
  hasDiploma = false;
  hasTimes = false;
  background: string = "";
  storageUrl: string = "";

  constructor(private eventsService: EventsService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer) {
    this.route.params.subscribe(params => {
      this.bib = params['bib'];
    });

  }

  ngOnInit() {
    this.loading = true;
    this.getCompetiton();
    this.eventsService.getResult(this.eventId, this.bib).subscribe({
      next: result => {
        this.result = result;
        this.result.hasTimes = result.times && result.times.filter(t => t).length > 0;
        this.loading = false;
        this.hasDiploma = this.event.races!.find(r => r.id == this.result.raceId)?.hasDiploma!;
      },
      error: error => {
        console.log(error);
        this.loading = false;
      }
    });

    this.configUrls();
  }

  configUrls() {
    if (this.event?.id) {
      this.storageUrl = `${environment.storage.basicUrl}/${environment.storage.competitions}/${this.event.id}/`;

      if (this.event.backgroundImageUrl) {
        this.background = `background-image: url(` + this.storageUrl + this.event.id + `/background-image/` + this.event.backgroundImageUrl + `);`;
      }
    }
  }

  getCompetiton() {
    var event = localStorage.getItem('event');
    if (event) {
      this.event = JSON.parse(event);
      this.eventId = this.event.id!;
    }
  }

  getStep(result: ResultModel, index: number): string {
    return result.checkpoints && result.checkpoints.length > index && result.checkpoints[index] ?
      result.checkpoints[index]
      : `Paso ${index + 1}`;

  }

  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  downloadDiploma() {
    this.saving = true;
    this.eventsService.downloadDiploma(this.result.id!, "p5aR888g_").subscribe({
      next: (x) => {
        var fileName = `diploma-${this.result.name}.pdf`;
        var blob = new Blob([x], { type: 'application/pdf' });
        saveAs(blob, fileName);
        this.saving = false;
      },
      error: error => {
        this.saving = false;
        return false;
      }
    });
  }


}
