<!-- Page Banner -->
<div class="page-banner-area ptb-25" [style]="background">
    <div class="container">
        <div class="page-banner-content text-center">
            <h1>Novas</h1>
            <ul class="ps-0 mb-0 list-unstyled">
                <li class="d-inline-block position-relative"><a routerLink="/">Inicio</a></li>
                <li class="d-inline-block position-relative">Novas</li>
            </ul>
        </div>
    </div>
</div>

<!-- Blog Grid -->
<div class="blog-area ptb-100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6" *ngFor="let new of news">
                <div class="blog-card">
                    <div class="image">
                        <a routerLink="/news/{{new.id}}" class="d-block">
                            <img [src]="storageNewsUrl + new.id + '/featured-image/' + new.featuredImage"
                                [alt]="new.title">
                        </a>
                    </div>
                    <div class="content">
                        <h3>
                            <a routerLink="/news/{{new.id}}">
                                {{new.title}}
                            </a>
                        </h3>
                        <ul class="meta ps-0 mb-0 list-unstyled">
                            <li class="d-inline-block position-relative">
                                <i class='bx bx-calendar'></i>
                                {{new.date | date}}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <nav class="pagination-area text-center">
            <p-paginator [rows]="20" [totalRecords]="totalRecords" (onPageChange)="selectPage($event)"></p-paginator>
        </nav>
        <!--<nav class="pagination-area text-center">
            <ul class="pagination mb-0 list-unstyled ps-0 d-block border-0 rounded-0">
                <li class="page-item disabled"><a class="page-link" routerLink="/blog-grid">
                        <i class="flaticon-left-arrow"></i>
                    </a></li>
                <li class="page-item"><a class="page-link active" routerLink="/blog-grid">1</a></li>
                <li class="page-item"><a class="page-link" routerLink="/blog-grid">2</a></li>
                <li class="page-item"><a class="page-link" routerLink="/blog-grid">3</a></li>
                <li class="page-item"><a class="page-link" routerLink="/blog-grid">4</a></li>
                <li class="page-item"><a class="page-link" routerLink="/blog-grid">
                        <i class="flaticon-next"></i>
                    </a></li>
            </ul>
        </nav>-->
    </div>
</div>