import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { EventResultsComponent } from './pages/results/results.component';
import { FaqComponent } from './pages/faq/faq.component';
import { ContactComponent } from './pages/contact/contact.component';
import { NewsDetailComponent } from './pages/news/detail/news-detail.component';
import { BibComponent } from './pages/bib/bib.component';

import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { LegalComponent } from './pages/legal/legal.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { ContactService } from './pages/contact/contact.service';
import { CircuitComponent } from './pages/circuit/circuit.component';
import { AppInitService } from './services/app-init-service';
import { CommonModule, registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import localeExtraEs from '@angular/common/locales/extra/es';
import localeExtraGl from '@angular/common/locales/extra/gl';
import { AppService } from './app.service';
import { InfoComponent } from './pages/info/info.component';
import { CircuitsService } from './services/circuits.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { USE_EMULATOR as USE_FIRESTORE_EMULATOR } from '@angular/fire/compat/firestore';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { environment } from 'src/environments/environment';

import { DropdownModule } from "primeng/dropdown";
import { InputTextModule } from "primeng/inputtext";
import { PaginatorModule } from "primeng/paginator";
import { CookiesComponent } from './cookies/cookies.component';
import { RunnerResultComponent } from './pages/results/runner-result/runner-result.component';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { NavbarComponent } from './components/common/navbar/navbar.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { NotFoundComponent } from './components/common/not-found/not-found.component';
import { TopHeaderComponent } from './components/common/top-header/top-header.component';
import { EventsService } from './services/events.service';
import localeGl from '@angular/common/locales/gl';
import { NewsComponent } from './pages/news/news.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { MapsComponent } from './pages/maps/maps.component';
import { LeafletModule } from "@asymmetrik/ngx-leaflet";
import { NgChartsModule } from 'ng2-charts';
import { BibsComponent } from './pages/bibs/bibs.component';
import { EnrollmentsService } from './services/enrollments.service';
import { StreamingComponent } from './pages/streaming/streaming.component';
import { TabViewModule } from "primeng/tabview";
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { AutoCompleteModule } from "primeng/autocomplete";
import { TrackingComponent } from './pages/tracking/tracking.component';
import { RoutesComponent } from './pages/routes/routes.component';
registerLocaleData(localeEs, 'es-ES', localeExtraEs);
registerLocaleData(localeGl, 'gl', localeExtraGl);

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: window.location.hostname // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  palette: {
    popup: {
      background: '#282828'
    },
    button: {
      background: '#fff'
    }
  },
  theme: 'edgeless',
  type: 'info',
  content: {
    message: 'Esta web utiliza cookies para obtener la mejor experiencia de uso.',
    link: 'Saber más',
    href: '/cookies',
    dismiss: 'Aceptar'
  }
};

const appConfigFactory = (appInitService: AppInitService) => {
  return () => {
    appInitService.init();
  };
}

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    NotFoundComponent,
    TopHeaderComponent,
    EventResultsComponent,
    ContactComponent,
    NewsComponent,
    NewsDetailComponent,
    FaqComponent,
    InfoComponent,
    LegalComponent,
    CookiesComponent,
    PrivacyComponent,
    CircuitComponent,
    RunnerResultComponent,
    BibComponent,
    MapsComponent,
    BibsComponent,
    StreamingComponent,
    TrackingComponent,
    RoutesComponent
  ],
  imports: [

    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    BrowserAnimationsModule,
    DropdownModule,
    InputTextModule,
    PaginatorModule,
    NgxScrollTopModule,
    GoogleMapsModule,
    LeafletModule,
    NgChartsModule,
    PaginatorModule,
    TabViewModule,
    ProgressSpinnerModule,
    AutoCompleteModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
  ],
  providers: [
    AppService,
    ContactService,
    CircuitsService,
    EventsService,
    EnrollmentsService,
    {
      provide: APP_INITIALIZER,
      useFactory: appConfigFactory,
      deps: [AppInitService],
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: "gl"
    },
    { provide: FIREBASE_OPTIONS, useValue: environment.firebase },
    { provide: USE_FIRESTORE_EMULATOR, useValue: environment.useEmulators ? ['localhost', 8081] : undefined },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
