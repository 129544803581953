export enum Gender {
  Male = "male",
  Female = "female"
}

export class GenderType {
  label: string;
  shortLabel: string;
  value: Gender;
  names: string[];
  static types: GenderType[] = [
    { value: Gender.Male, label: 'Masculino', shortLabel: 'M', names: ["male", "hombre", "h", "masculino", "home"] },
    { value: Gender.Female, label: 'Feminino', shortLabel: 'F', names: ["female", "mujer", "m", "femenino", "feminino", "muller"] }
  ]
  static mapGender(gender: Gender): string {
    return this.types.filter(t => t.value == gender).map<string>(f => f.label)[0];
  }
  static getShortLabel(gender: Gender): string {
    return this.types.filter(t => t.value == gender).map<string>(f => f.shortLabel)[0];
  }

  static getGenderTypeByName(genderName: string): GenderType {
    genderName = genderName.toLocaleLowerCase().replace(/\s/g, "");
    let genderFound = this.types.find(t => t.names.findIndex(v => v == genderName) >= 0);
    return genderFound ? genderFound : null;
  }

  static getAllowedNames(): string[] {
    let names: string[] = [];
    this.types.forEach(type => {
      type.names.forEach(name => {
        names.push(name);
      });
    });

    return names;
  }
}
