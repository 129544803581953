import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import * as L from 'leaflet';
import 'leaflet-gpx';
import { DOMParser } from 'xmldom';
import toGeoJSON from '@mapbox/togeojson';
import { HttpClient } from '@angular/common/http';
import { Chart, ChartConfiguration } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { RaceModel } from 'src/app/core/models/race';
import { SignalRService } from 'src/app/core/services/signal-r.service';
import { take } from 'rxjs';
import { LazyLoadEvent } from 'primeng/api';
import * as moment from 'moment';
import { EventsService } from 'src/app/services/events.service';

interface RunnerState {
    lastEstimatedDistance: number;
    marker: L.Marker;
    // ... cualquier otra información relevante
}

@Component({
    selector: 'app-tracking',
    templateUrl: './tracking.component.html',
    styleUrls: ['./tracking.component.scss']
})
export class TrackingComponent implements OnInit, AfterViewInit {
    @ViewChild(BaseChartDirective) chart: BaseChartDirective;

    options = {
        layers: [L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '...' })],
        zoom: 8,
        center: [46.879966, -121.726909]
    };

    eventId: string;

    gpxUrl = "";
    gpxContent: string;
    elevations: number[] = [];
    coordinatesArray: [number, number][] = [];
    distances: number[] = [];
    chartLoaded: boolean = false;
    map: L.Map;

    progressValue: number = 0;
    maxProgressValue: number = 0; // Puedes asignar esto después de cargar el GPX.

    originalZoom: number;

    isPlaying: boolean = false;

    xLabels: string[] = [];
    yValues: number[] = [];

    lineChartData: ChartConfiguration<'line'>['data'] = {
        datasets: [
            {
                data: [],
                label: 'Elevación',
                fill: true,
                borderColor: 'black',
                backgroundColor: 'rgba(255,0,0,0.3)',
                pointRadius: 0,  // <-- Añade esta línea para ocultar los puntos
            },
        ],
    };

    chartOptions = {}

    storageUrl: string;

    event: any;

    selectedRace: RaceModel;

    loadingMap: boolean = true;

    private runnersState: Map<string, RunnerState> = new Map();

    runnersTracking: any[] = [];
    searchTerm: string = "";
    waypointsDistances: any[] = [];

    suggestions: any[] = [];
    selectedItem: any;

    raceTime: any;

    results: any;
    resultsMale: any;
    resultsFemale: any;

    constructor(private http: HttpClient,
        private resultsService: EventsService,
        private signalRService: SignalRService,
        private route: ActivatedRoute) {

        this.route.params.subscribe(params => {
            this.eventId = params.eventId;
        });

        this.storageUrl = `${environment.storage.basicUrl}/${environment.storage.races}/`;
    }

    ngOnInit(): void {

        //Escuchamos las actualizaciones en tiempo real
        this.signalRService.startConnection();
        this.signalRService.addRaceUpdateListener((update) => this.handleRaceUpdate(update));
        this.signalRService.addRaceRemoveListener(() => this.handleRaceRemove());

        //Carga el evento y el fichero gpx
        this.getEvent();

        console.log("this.event.races", this.event.races);

        this.selectRace(this.event.races.find(r => r.routeGpxUrl != null && r.routeGpxUrl != undefined));

        const verticalLinePlugin = {
            id: 'verticalLinePlugin',
            afterDraw: function (chart) {
                const activeElements = chart.getActiveElements();
                if (activeElements?.length) {

                    const activePoint = activeElements[0].element;
                    const ctx = chart.ctx;
                    const x = activePoint.x; // Cambio aquí
                    const topY = chart.scales.y.top;
                    const bottomY = chart.scales.y.bottom;

                    // draw line
                    ctx.save();
                    ctx.beginPath();
                    ctx.moveTo(x, topY);
                    ctx.lineTo(x, bottomY);
                    ctx.lineWidth = 2;
                    ctx.strokeStyle = '#ff0000';
                    ctx.stroke();
                    ctx.restore();
                }

            }
        };
        Chart.register(verticalLinePlugin);

        this.getTop(10, 'all');

        this.getTopFemale(10, 'all');

        this.getTopMale(10, 'all');
    }

    ngAfterViewInit(): void {

    }

    getTop(number, type) {

        let query = '';

        if (type != 'all') {
            query = type;
        }

        let lazyEvent: LazyLoadEvent = { globalFilter: query, first: 0, rows: number, sortField: "position" };

        console.log("this.eventId", this.eventId);

        this.resultsService.getAll(this.eventId, lazyEvent, true).pipe(take(1)).subscribe({
            next: (results) => {
                console.log("results", results);
                this.results = results;

                let firstTime = this.results.items[0].createdOn;
                console.log("primer tiempo", firstTime);
                let firstTimeMoment = moment.utc(firstTime).local().toDate();
                console.log("firstTimeMoment", firstTimeMoment);

                let now = new Date();

                console.log("now", now);

                let seconds = (now.getTime() - firstTimeMoment.getTime()) / 1000 + this.convertTimeToSeconds(this.results.items[0].times[0]);
                console.log("seconds", seconds);

                this.startTime = now.getTime() - seconds * 1000;

                if (results.count > 0) {
                    //Leemos el estado inicial de los corredores
                    let runners = JSON.parse(sessionStorage.getItem("runnersTracking"));
                    if (runners) {
                        this.runnersTracking = runners;

                        this.runnersTracking.forEach(element => {
                            this.resultsService.getResult(this.eventId, element.bib).pipe(take(1)).subscribe({
                                next: (result) => {
                                    console.log("result", result);
                                    this.handleRaceUpdate(result);
                                    sessionStorage.setItem("runnersTracking", JSON.stringify(this.runnersTracking));
                                },
                                error: (error) => {
                                    console.log(error);
                                }
                            })
                        });

                        this.runnersTracking.sort((a, b) => a.position - b.position);

                    }
                }

            }
        })
    }

    getTopMale(number, type) {

        let query = '';

        if (type != 'all') {
            query = type;
        }

        let filters = {

        };

        filters["gender"] = { value: 'male' };

        let lazyEvent: LazyLoadEvent = { globalFilter: query, first: 0, rows: number, sortField: "position" };

        this.resultsService.getAll(this.eventId, lazyEvent, true).pipe(take(1)).subscribe({
            next: (results) => {
                this.resultsMale = results;
            }
        })
    }

    getTopFemale(number, type) {

        let query = '';

        if (type != 'all') {
            query = type;
        }

        let filters = {

        };

        filters["gender"] = { value: 'female' };

        let lazyEvent: LazyLoadEvent = { filters, globalFilter: query, first: 0, rows: number, sortField: "position" };

        this.resultsService.getAll(this.eventId, lazyEvent, true).pipe(take(1)).subscribe({
            next: (results) => {
                this.resultsFemale = results;
            }
        })
    }

    search(event) {
        console.log("event", event);
        let lazyEvent: LazyLoadEvent = { globalFilter: event.query, first: 0, rows: 50, sortField: "name" };
        this.resultsService.getAll(this.eventId, lazyEvent, true).pipe(take(1)).subscribe({
            next: (result) => {
                this.suggestions = result.items;
            },
            error: (error) => {
                console.log(error);
            }
        });
    }

    selectBib(event) {
        console.log("añadido: ", event);
        if (this.runnersTracking.length < 10) {
            this.runnersTracking.push(event);
            this.handleRaceUpdate(event);
            sessionStorage.setItem("runnersTracking", JSON.stringify(this.runnersTracking));
        }
        this.selectedItem = null;
    }

    getEvent() {
        var event = localStorage.getItem('event');
        if (event) {
            this.event = JSON.parse(event);
            this.eventId = this.event.id!;
        }
    }

    getStep(runner) {
        console.log("runner", runner);
        let runnerState = this.runnersState.get(runner.bib);
        if (runnerState?.lastEstimatedDistance >= this.distances[this.distances.length - 1]) {
            return "Meta";
        }
        else {
            return runner.checkpoints[runner.checkpoints.length
                - 1];
        }
    }

    remove(runner) {
        this.runnersTracking = this.runnersTracking.filter(item => item.bib !== runner.bib);
        if (this.runnersTracking.length == 0) {
            sessionStorage.removeItem("runnersTracking");
        }
        else {
            sessionStorage.setItem("runnersTracking", JSON.stringify(this.runnersTracking));
        }

        this.runnersState.get(runner.bib).marker.remove();

        console.log("runner", runner);
        this.runnersState.delete(runner.bib);
    }

    customIcon = L.icon({
        iconUrl: 'assets/img/map/runner.webp', // Cambia esto a la ruta de tu imagen
        iconSize: [35, 52],
        iconAnchor: [17, 52],
        popupAnchor: [0, -52]
    });

    // Método para actualizar o crear un marcador para un corredor
    private updateOrCreateMarker(runner: any, index: number): void {

        const [lat, lon] = this.coordinatesArray[index];

        const tooltipContent = `${runner.name} ${runner.surname} [${runner.bib}]`;

        let state = this.runnersState.get(runner.bib);

        if (state) {
            // Actualizar la posición del marcador existente
            state.marker.setLatLng([lat, lon]);
            state.marker.setTooltipContent(tooltipContent);
        } else {
            state = {
                // Crear un nuevo marcador y añadirlo al mapa
                lastEstimatedDistance: 0,
                marker: L.marker([lat, lon], { icon: this.customIcon, zIndexOffset: 10000 }).bindTooltip(tooltipContent, {
                    permanent: false, // El tooltip solo se muestra al pasar el ratón por encima
                    direction: 'top'  // Puedes ajustar la dirección si es necesario
                })
            }
            state.marker.addTo(this.map);
            this.runnersState.set(runner.bib, state);
        }
    }

    private handleRaceRemove(): void {
        console.log("remove");
    }

    private startTime: number; // Tiempo en que el corredor pasa por el primer punto de control

    private playbackInterval: any;

    private startPlayback() {
        // Cancela el intervalo anterior si existe
        if (!this.playbackInterval) {
            this.playbackInterval = setInterval(() => {
                let allRunnersFinished = true;
                this.runnersTracking.forEach((runner, bib) => {
                    if (runner.times) {
                        let currentTime = this.getCurrentRaceTime();
                        this.raceTime = currentTime;

                        console.log("current time", currentTime);

                        let positionIndex = this.interpolateRunnerPosition(runner, currentTime);
                        let estimatedDistance = this.distances[positionIndex];

                        console.log("runner", runner.name);

                        let totalDistance = this.distances[this.distances.length - 1];

                        console.log("total distance", totalDistance);

                        // Verifica si el corredor ha alcanzado la meta
                        if (estimatedDistance >= totalDistance) {
                            //clearInterval(this.playbackInterval); // Detiene el temporizador
                            this.updateOrCreateMarker(runner, (this.coordinatesArray.length - 1)); // Mueve al corredor a la meta
                        } else {
                            this.updateOrCreateMarker(runner, positionIndex);
                        }

                        // Verifica si este corredor ha terminado
                        if (estimatedDistance < this.distances[this.distances.length - 1]) {
                            allRunnersFinished = false;
                        }
                    }
                });

                // Si todos los corredores han terminado, detiene el temporizador
                if (allRunnersFinished) {
                    clearInterval(this.playbackInterval);
                }
            }, 1000); // Actualiza cada segundo
        }
    }

    private handleRaceUpdate(update): void {
        console.log("update", update);
        let runner = this.runnersTracking.find(r => r.bib == update.bib);

        if (runner) {
            runner.name = update.name;
            runner.surname = update.surname;
            runner.position = update.calculatedGeneralPosition ? update.calculatedGeneralPosition : update.position;
            runner.times = update.times;
            runner.time = update.provisionalTime ? update.provisionalTime : update.time;
            runner.checkpoints = update.checkpoints;

            console.log("runner", runner);

            // Asumir que el tiempo en el primer punto de control es el inicio

            if (!this.startTime && runner.times.length > 0) {
                this.startTime = new Date().getTime() - this.convertTimeToSeconds(runner.times[0]) * 1000;
                console.log("this.startTime", this.startTime);
            }

            runner.times = runner.times.map(timeString => this.convertTimeToSeconds(timeString));

            let runnerPositionIndex = this.findIndexByControlPointTimes(runner.times);

            if (runner.time) {
                runnerPositionIndex = this.distances.length - 1;
            }
            console.log("runnerPositionIndex", runnerPositionIndex);
            this.updateOrCreateMarker(runner, runnerPositionIndex);

            this.startPlayback();

            console.log("this.runnersTracking", this.runnersTracking);

            this.runnersTracking.sort((a, b) => a.position - b.position);
        }
    }

    private getCurrentRaceTime(): number {
        let currentTime = new Date().getTime();
        return (currentTime - this.startTime) / 1000; // Tiempo transcurrido en segundos
    }

    private findIndexByControlPointTimes(times: number[]): number {
        // Obtén el índice del último punto de control alcanzado
        let lastControlPointIndex = times.length - 1;

        // Si el corredor ha alcanzado al menos un punto de control
        if (lastControlPointIndex >= 0) {
            // Supongamos que waypointsDistances es un array que contiene la distancia acumulada en cada waypoint
            // El índice de la posición actual sería igual al índice del último waypoint alcanzado
            let currentPositionIndex = this.findIndexByDistance(this.waypointsDistances[lastControlPointIndex]);

            return currentPositionIndex;
        }

        // Si el corredor no ha alcanzado ningún punto de control aún
        return 0; // o puedes devolver otro valor que represente esta situación
    }

    private findIndexByDistance(distance: number): number {
        // Si la distancia dada supera o es igual a la última distancia en el recorrido
        if (distance >= this.distances[this.distances.length - 1]) {
            return this.coordinatesArray.length - 1; // Retorna el índice de la última coordenada (la meta)
        }

        // De lo contrario, busca el índice como antes
        for (let i = 0; i < this.distances.length; i++) {
            if (this.distances[i] >= distance) {
                return i;
            }
        }

        // En caso de que algo falle, devuelve el índice de la última posición por defecto
        return this.coordinatesArray.length - 1;
    }

    private interpolateRunnerPosition(runner: any, currentTime: number): number {

        let state = this.runnersState.get(runner.bib);
        if (!state) {
            return 0; // O manejar de otra manera si el estado no existe
        }

        // Si el corredor ha pasado el primer punto de control pero no el segundo
        if (runner.times.length === 1) {
            let timeAtFirstPoint = runner.times[0]; // Tiempo en segundos al llegar al primer punto
            let distanceToFirstPoint = this.waypointsDistances[0]; // Distancia al primer punto en metros
            let timeSinceFirstPoint = currentTime - timeAtFirstPoint; // Tiempo transcurrido desde el primer punto
            let estimatedSpeed = distanceToFirstPoint / timeAtFirstPoint; // Velocidad promedio hasta el primer punto
            let estimatedDistance = distanceToFirstPoint + (timeSinceFirstPoint * estimatedSpeed); // Estimación de la distancia recorrida después del primer punto

            // Asegúrate de que la estimación no exceda la distancia al segundo punto
            if (estimatedDistance > this.waypointsDistances[1]) {
                estimatedDistance = this.waypointsDistances[1];
            }

            if (estimatedDistance < state.lastEstimatedDistance) {
                estimatedDistance = state.lastEstimatedDistance;
            } else {
                // Actualiza la última distancia estimada si es mayor
                state.lastEstimatedDistance = estimatedDistance;
            }

            console.log("estimatedSpeed", estimatedSpeed);

            console.log("estimatedDistance", estimatedDistance);

            return this.findIndexByDistance(estimatedDistance);
        } else if (runner.times.length > 1) {
            // Encuentra entre qué waypoints se encuentra el corredor
            let segmentIndex = runner.times.findIndex(time => time > currentTime);
            if (segmentIndex === -1) {
                segmentIndex = runner.times.length - 1; // El corredor ha pasado el último waypoint
            }

            // Índices para el tiempo y la distancia
            let prevIndex = Math.max(0, segmentIndex - 1);
            let nextIndex = Math.min(runner.times.length - 1, segmentIndex);

            let previousTime = runner.times[prevIndex];
            let nextTime = runner.times[nextIndex];

            // Evitar división por cero
            if (nextTime === previousTime) {

                return this.findIndexByDistance(this.waypointsDistances[prevIndex]);
            }


            let distanceToFirstPoint = this.waypointsDistances[nextIndex]; // Distancia al primer punto en metros
            let timeSinceNextPoint = nextTime; // Tiempo transcurrido desde el punto anterior
            let estimatedSpeed = distanceToFirstPoint / timeSinceNextPoint; // Velocidad promedio hasta el primer punto

            console.log("estimatedSpeed", estimatedSpeed);

            // Calcula la fracción del trayecto recorrido
            let fraction = (currentTime - previousTime) / (nextTime - previousTime);

            // Calcula la distancia estimada recorrida
            let previousDistance = this.waypointsDistances[prevIndex];
            let nextDistance = this.waypointsDistances[nextIndex];
            let estimatedDistance = previousDistance + fraction * (nextDistance - previousDistance);

            console.log("estimatedDistance", estimatedDistance);

            if (estimatedDistance > this.waypointsDistances[nextIndex + 1]) {
                estimatedDistance = this.waypointsDistances[nextIndex + 1];
            }

            if (estimatedDistance < state.lastEstimatedDistance) {
                estimatedDistance = state.lastEstimatedDistance;
            } else {
                // Actualiza la última distancia estimada si es mayor
                state.lastEstimatedDistance = estimatedDistance;
            }

            return this.findIndexByDistance(estimatedDistance);
        }

        // Si el corredor no ha alcanzado el primer punto de control
        return 0;
    }

    private convertTimeToSeconds(timeString: string): number {
        let parts = timeString.split(':');
        let hours = parseInt(parts[0]);
        let minutes = parseInt(parts[1]);
        let seconds = parseInt(parts[2]);

        return hours * 3600 + minutes * 60 + seconds;
    }


    addBib(bib: string) {
        this.runnersTracking.push({ bib: bib });
        this.searchTerm = "";
    }

    onMapReady(map: L.Map) {
        this.map = map;
        const gpxTrack = new (L as any).GPX(this.gpxUrl, {
            async: true,
            marker_options: {
                startIcon: new L.Icon({
                    iconUrl: 'assets/img/map/start.webp',
                    iconSize: [52, 52], // Tamaño del ícono de inicio
                    iconAnchor: [26, 52] // Punto de anclaje del ícono de inicio
                }),
                endIcon: new L.Icon({
                    iconUrl: 'assets/img/map/finish.webp',
                    iconSize: [52, 52], // Tamaño del ícono de fin
                    iconAnchor: [26, 52] // Punto de anclaje del ícono de fin
                }),
                wptIcons: {
                    '': new L.Icon({
                        iconUrl: 'assets/img/map/checkpoint.webp',
                        iconSize: [23, 41], // Tamaño del ícono de los puntos de paso
                        iconAnchor: [5, 41] // Punto de anclaje del ícono de los puntos de paso
                    })
                },
            }
        });

        let t = this;

        gpxTrack.on('loaded', function (event) {
            map.fitBounds(event.target.getBounds());
        }).addTo(map);

        this.runnersTracking.forEach(runner => {
            this.updateOrCreateMarker(runner, 0);
        })
    }

    loadGPX() {

        this.http.get(this.gpxUrl, { responseType: 'text' })
            .subscribe({
                next: (data: string) => {
                    this.gpxContent = data;

                    this.elevations = [];
                    this.distances = [];
                    this.coordinatesArray = [];

                    const parser = new DOMParser();
                    const xmlDoc = parser.parseFromString(this.gpxContent, 'text/xml'); // gpxData es tu archivo GPX como string
                    const geojsonData = toGeoJSON.gpx(xmlDoc);

                    const coordinates = geojsonData?.features?.[0]?.geometry?.coordinates ?? [];

                    for (const element of coordinates) {
                        const elevation = element?.[2];  // 2 es el índice de la elevación en [longitud, latitud, elevación]
                        const lon = element?.[0];
                        const lat = element?.[1];
                        this.elevations.push(elevation);
                        this.coordinatesArray.push([lat, lon]);
                    }

                    let totalDistance = 0;

                    this.distances.push(0);

                    for (let i = 0; i < coordinates.length - 1; i++) {
                        const [lon1, lat1] = coordinates[i];
                        const [lon2, lat2] = coordinates[i + 1];

                        const distance = this.haversineDistance(lat1, lon1, lat2, lon2);
                        totalDistance += distance;
                        this.distances.push(totalDistance);
                    }

                    let kmWaypoints = geojsonData.features
                        .filter(feature => feature.geometry.type === "Point")
                        .map(feature => {
                            return {
                                lat: feature.geometry.coordinates[1],
                                lon: feature.geometry.coordinates[0],
                                // Puedes agregar más propiedades si las necesitas
                                name: feature.properties.name // por ejemplo
                            };
                        });

                    console.log("kmWaypoints", kmWaypoints);

                    let parent = this;
                    // Función para encontrar la coordenada más cercana a un punto dado.
                    function findClosestCoordinate(lat, lon, coordinates) {
                        let minDistance = Infinity;
                        let closestCoordinate = null;

                        for (const coord of coordinates) {
                            const distance = parent.haversineDistance(lat, lon, coord[1], coord[0]);
                            if (distance < minDistance) {
                                minDistance = distance;
                                closestCoordinate = coord;
                            }
                        }

                        return closestCoordinate;
                    }

                    // Suponiendo que 'coordinates' es tu lista de coordenadas de la ruta
                    // y 'kmWaypoints' son tus waypoints con la distancia esperada desde el inicio (e.g., 1.4 km para PK 1,4)

                    let errorMargin = 0.5;
                    // Suponiendo que 'coordinates' es tu lista de coordenadas de la ruta
                    // y 'kmWaypoints' son tus waypoints (PK 1,4, etc.)

                    this.waypointsDistances = kmWaypoints.map((waypoint, waypointIndex) => {
                        let accumulatedDistance = 0.0;
                        let closestDistance = Number.MAX_VALUE;
                        let closestAccumulatedDistance = 0.0;

                        // Limita la búsqueda para el primer waypoint (PK 1,4)
                        let searchLimit = waypointIndex === 0 ? (1.4 + errorMargin) * 1000 : Number.MAX_VALUE;

                        for (let i = 0; i < coordinates.length - 1; i++) {
                            if (i > 0) {
                                let segmentDistance = this.haversineDistance(coordinates[i][1], coordinates[i][0], coordinates[i + 1][1], coordinates[i + 1][0]);
                                if (accumulatedDistance + segmentDistance > searchLimit) break;
                                accumulatedDistance += segmentDistance;
                            }

                            let distanceToWaypoint = this.haversineDistance(coordinates[i][1], coordinates[i][0], waypoint.lat, waypoint.lon);
                            if (distanceToWaypoint < closestDistance) {
                                closestDistance = distanceToWaypoint;
                                closestAccumulatedDistance = accumulatedDistance;
                            }
                        }

                        return closestAccumulatedDistance;
                    });

                    console.log("waypointDistances", this.waypointsDistances);

                    // Calcula el número de ticks completos de 1000 que tendrás.
                    let numberOfTicks = Math.floor(totalDistance / 1000);

                    // Genera los ticks de 1000 en 1000.
                    let ticks: number[] = [];
                    for (let i = 0; i <= numberOfTicks; i++) {
                        ticks.push(i * 1000);
                    }

                    // Si el último tick no es igual a maxDistance, añade maxDistance a la lista.
                    if (ticks[ticks.length - 1] !== totalDistance) {
                        ticks.push(totalDistance);
                    }

                    this.lineChartData.datasets[0].data = this.elevations;

                    this.lineChartData.labels = this.distances;

                    this.maxProgressValue = this.coordinatesArray.length;

                    this.chartOptions = {
                        responsive: true,
                        hover: {
                            mode: 'index',  // Mostrar el tooltip para el punto más cercano
                            intersect: false  // No se requiere que el ratón intersecte con el punto
                        },
                        plugins: {
                            tooltip: {
                                enabled: true,  // activa o desactiva el tooltip
                                mode: 'index',
                                intersect: false,
                                position: 'nearest',  // Desactiva el tooltip por defecto
                                callbacks: {
                                    label: (context) => {
                                        const km = this.getExactKilometer(context.dataIndex);
                                        return `Elevación: ${context.parsed.y.toFixed(2)} m (Km ${km.toFixed(2)})`;
                                    },
                                    title: () => ''  // Este callback es opcional, pero lo puse aquí para que no se muestre ningún título en el tooltip
                                }
                            },
                            verticalLinePlugin: {},  // Esto activa tu plugin
                            annotation: {
                                annotations: kmWaypoints.map((waypoint, index) => {
                                    return {
                                        type: 'line',
                                        mode: 'vertical',
                                        scaleID: 'x',
                                        value: this.waypointsDistances[index], // la distancia que calculaste para este waypoint
                                        borderColor: 'rgba(255, 0, 0, 0.5)', // elige un color para la línea
                                        borderWidth: 2,
                                        label: {
                                            content: waypoint.name, // nombre del waypoint
                                            enabled: true,
                                            position: 'top'
                                        }
                                    };
                                })
                            }
                        },
                        scales: {
                            x: {
                                type: 'linear',
                                position: 'bottom',
                                min: 0,
                                max: totalDistance,
                                ticks: {
                                    autoSkip: false,
                                    source: 'data',

                                    // Especifica el conjunto de ticks
                                    callback: function (value, index) {

                                        const formatValue = (value) => {
                                            let kmValue = value / 1000;

                                            // Verifica si kmValue es un entero
                                            if (Number.isInteger(kmValue)) {
                                                return `${kmValue}km`;
                                            } else {
                                                return `${kmValue.toFixed(1)}km`;
                                            }
                                        };

                                        return formatValue(value);
                                    },
                                    // Establecer la rotación del tick
                                    maxRotation: 90,  // Rotación máxima (90 grados hace que el texto quede vertical)
                                    minRotation: 90   // Rotación mínima (mantenerlo igual para asegurar que siempre esté vertical)
                                },
                                afterBuildTicks: function (scale) {
                                    scale.ticks = ticks.map(t => ({ value: t }));
                                },
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Distancia (km)'
                                }
                            },
                            y: {
                                beginAtZero: false,
                                title: {
                                    display: true,
                                    text: 'Elevación (m)',
                                },
                            },
                        }
                    }

                    this.chartLoaded = true;

                    this.loadingMap = false;
                },
                error: (error: any) => {
                    console.error('Error al obtener el archivo GPX:', error);
                }
            });
    }

    haversineDistance(lat1, lon1, lat2, lon2) {
        const R = 6371e3; // radio de la Tierra en metros
        const φ1 = lat1 * Math.PI / 180;
        const φ2 = lat2 * Math.PI / 180;
        const Δφ = (lat2 - lat1) * Math.PI / 180;
        const Δλ = (lon2 - lon1) * Math.PI / 180;

        const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) + Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        return R * c;
    }

    getExactKilometer(index: number): number {
        // Asume que "distances" es la matriz que contiene la distancia acumulada en metros
        return this.distances[index] / 1000;
    }

    createGradient(ctx: CanvasRenderingContext2D) {
        const gradient = ctx.createLinearGradient(0, 0, 0, 200);  // Asume que el gráfico tiene una altura de 200px. Ajusta según tus necesidades.
        gradient.addColorStop(0, 'rgba(13, 90, 165, 1)');  // parte superior
        gradient.addColorStop(1, 'rgba(255, 255, 255, 1)');  // parte inferior

        return gradient;
    }

    selectRace(race: RaceModel) {

        this.selectedRace = race;
        this.loadingMap = true;
        this.chartLoaded = false;

        if (race.routeGpxUrl)
            this.gpxUrl = this.storageUrl + this.selectedRace.id + '/route-gpx/' + race.routeGpxUrl;

        if (this.gpxUrl)
            this.loadGPX();
    }

    convertSeconds(totalSeconds: number): string {
        let hours = Math.floor(totalSeconds / 3600).toString().padStart(2, '0');
        let minutes = Math.floor((totalSeconds % 3600) / 60).toString().padStart(2, '0');
        let seconds = Math.floor(totalSeconds % 60).toString().padStart(2, '0');

        return `${hours}:${minutes}:${seconds}`;
    }

    formatTime(date) {
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let seconds = date.getSeconds();

        // Agregar un cero al inicio si el número es menor de 10
        hours = (hours < 10) ? '0' + hours : hours;
        minutes = (minutes < 10) ? '0' + minutes : minutes;
        seconds = (seconds < 10) ? '0' + seconds : seconds;

        return `${hours}:${minutes}:${seconds}`;
    }

    getLastEstimatedDistance(bib) {
        return this.runnersState.get(bib).lastEstimatedDistance;
    }

}
