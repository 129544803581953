// signalr.service.ts
import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SignalRService {
    private hubConnection: HubConnection;

    public startConnection = () => {
        this.hubConnection = new HubConnectionBuilder()
            .withUrl(environment.apiTp + '/racehub') // Asegúrate de que la URL coincida con tu configuración del servidor
            .build();

        this.hubConnection
            .start()
            .then(() => console.log('Connection Started'))
            .catch(err => console.log('Error while starting connection: ' + err));
    }

    public addRaceUpdateListener = (onUpdateReceived: (update: any) => void) => {
        this.hubConnection.on('RaceUpdate', (update: any) => {
            onUpdateReceived(update);
        });
    }

    public addRaceRemoveListener = (onRemoveReceived: () => void) => {
        this.hubConnection.on('RaceRemove', () => {
            onRemoveReceived();
        });
    }

    // Agrega más listeners según sea necesario
}
