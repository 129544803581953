<div class="page-banner-area ptb-25" [style]="background">
  <div class="container">
    <div class="page-banner-content text-center">
      <h1>Información da Carreira</h1>
      <ul class="ps-0 mb-0 list-unstyled">
        <li class="d-inline-block position-relative"><a routerlink="/" href="/">Inicio</a></li>
        <li class="d-inline-block position-relative">Información</li>
      </ul>
    </div>
  </div>
</div>

<div class="p-5">
  <div class="position-relative bg-ig-primary overflow-hidden">
    <div
      class="container position-relative zi-2 content-space-t-3 content-space-t-lg-4 content-space-b-2 content-space-b-md-3">
      <div class="row align-items-lg-center">
        <div class="col-lg-7 mb-7 mb-lg-0">
          <div class="circuit-competition-info">
            <div class="mb-3">
              <h1 *ngIf="circuit" class="mb-3">
                <span>{{circuit!.title}}</span>
                <br>
                <span class="subtitle">{{circuit!.subtitle}}</span>
              </h1>
              <h1 *ngIf="event" class="mb-3">
                <span>{{event!.name}}</span>
                <br>
                <span class="subtitle">{{event!.place}}</span>
              </h1>
              <div class="race-description" *ngIf="event?.description">
                <h4>Información xeral</h4>
                <div [innerHtml]="event.description"></div>
              </div>
              <div class="race-description" *ngIf="event?.enrollmentInfo">
                <h4>Inscrición</h4>
                <div [innerHtml]="event.enrollmentInfo"></div>
              </div>
              <div class="race-description" *ngIf="event?.bibPickup">
                <h4>Recollida de dorsais</h4>
                <div [innerHtml]="event.bibPickup"></div>
              </div>
              <div class="race-description" *ngIf="event?.services">
                <h4>Servizos</h4>
                <div [innerHtml]="event.services"></div>
              </div>
              <div class="race-description" *ngIf="event?.awards">
                <h4>Premiacións</h4>
                <div [innerHtml]="event.awards"></div>
              </div>
            </div>
            <div class="race-description" *ngIf="circuit?.description">
              <h4>Información xeral</h4>
              <div [innerHtml]="circuit.description"></div>
            </div>
          </div>
          <div class="d-grid d-sm-flex gap-3">
            <a class="default-btn" [routerLink]="['/results']" *ngIf="event && finished">Resultados</a>
            <a class="default-btn" *ngIf="event && !finished" [href]="
            event.enrollmentUrl" target="_blank">Inscribirme</a>
            <a class="default-btn out" *ngIf="event?.regulations"
              [href]="storageUrl + 'regulations/' + event.regulations[0].url" target="_blank">Regulamento</a>
            <a class="default-btn out" *ngIf="circuit" [href]="storageRegulationsUrl + '/' + circuit.regulations[0].url"
              target="_blank">Regulamento</a>
          </div>
        </div>
        <div class="col-lg-5">
          <div class="poster">
            <div class="position-relative">

              <figure class="device-browser">
                <div class="device-browser-header">
                  <div class="device-browser-header-btn-list">
                    <span class="device-browser-header-btn-list-btn"></span>
                    <span class="device-browser-header-btn-list-btn"></span>
                    <span class="device-browser-header-btn-list-btn"></span>
                  </div>
                  <div class="device-browser-header-browser-bar">www.carreirasgalegas.com</div>
                </div>

                <div class="device-browser-frame">
                  <img class="device-browser-img" [src]="storageUrl + 'poster/' + circuit.posterUrl" alt="Cartel"
                    *ngIf="circuit">
                  <img class="device-browser-img" [src]="storageUrl + 'poster/' + event.posterUrl" alt="Cartel"
                    *ngIf="event">
                </div>
              </figure>


            </div>
          </div>
        </div>
      </div>

    </div>

  </div>


</div>