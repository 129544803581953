<!-- Hero -->
<div class="bg-img-start" style="background-image: url(../assets/svg/components/card-11.svg);">
    <div class="container content-space-t-3 content-space-t-lg-5 content-space-b-2">
        <div class="w-md-75 w-lg-50 text-center mx-md-auto">
            <h1 class="text-primary">FAQ</h1>
            <p class="text-light">Respondemos todas as dúbidas que teñas</p>
        </div>
    </div>
</div>
<!-- End Hero -->
<!-- FAQ -->
<div class="container content-space-2 content-space-b-lg-3">
    <div class="w-lg-75 mx-lg-auto">
        <div class="d-grid gap-10">
            <div class="d-grid gap-3" *ngIf="event.name">
                <h2>A Carreira</h2>

                <!-- Accordion -->
                <div class="accordion accordion-flush accordion-lg" id="accordionFAQBasics">
                    <!-- Accordion Item -->
                    <div class="accordion-item">
                        <div class="accordion-header" id="headingBasicsOne">
                            <a class="accordion-button" role="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseBasicsOne" aria-expanded="true"
                                aria-controls="collapseBasicsOne">
                                Cómo podo participar?
                            </a>
                        </div>
                        <div id="collapseBasicsOne" class="accordion-collapse collapse show"
                            aria-labelledby="headingBasicsOne" data-bs-parent="#accordionFAQBasics">
                            <div class="accordion-body" *ngIf="competition.status != 'finished'">
                                Podes participar inscribíndote mediante o seguinte
                                enlace:
                                <a [href]="competition.enrollmentUrl" target="_blank">inscrición</a>
                            </div>
                            <div class="accordion-body" *ngIf="competition.status == 'finished'">
                                O periodo de inscrición da carreira xa rematou.
                            </div>
                        </div>
                    </div>
                    <!-- End Accordion Item -->

                    <!-- Accordion Item -->
                    <div class="accordion-item">
                        <div class="accordion-header" id="headingBasicsTwo">
                            <a class="accordion-button collapsed" role="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseBasicsTwo" aria-expanded="false"
                                aria-controls="collapseBasicsTwo">
                                Cando é a carreira?
                            </a>
                        </div>
                        <div id="collapseBasicsTwo" class="accordion-collapse collapse"
                            aria-labelledby="headingBasicsTwo" data-bs-parent="#accordionFAQBasics" *ngIf="competition">
                            <div class="accordion-body">
                                A carreira celébrase o {{competition.date
                                | date:
                                'longDate'}} ·
                                {{competition.races[0].time.substring(0,5)}}h. A saída terá lugar dende a
                                zona do Obelisco.
                            </div>
                        </div>
                    </div>
                    <!-- End Accordion Item -->

                    <!-- Accordion Item -->
                    <div class="accordion-item">
                        <div class="accordion-header" id="headingBasicsThree">
                            <a class="accordion-button collapsed" role="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseBasicsThree" aria-expanded="false"
                                aria-controls="collapseBasicsThree">
                                Podo facer un donativo para a asociación colaboradoira da carreira ainda que non corra?
                            </a>
                        </div>
                        <div id="collapseBasicsThree" class="accordion-collapse collapse"
                            aria-labelledby="headingBasicsThree" data-bs-parent="#accordionFAQBasics">
                            <div class="accordion-body">
                                Por suposto, toda axuda é benvida e podes facer a túa aportación a través do dorsal 0 no
                                seguinte enlace: <a [href]="competition.bib0Url">dorsal
                                    0</a>
                            </div>
                        </div>
                    </div>
                    <!-- End Accordion Item -->

                </div>
                <!-- End Accordion -->
            </div>

            <div class="d-grid gap-3" *ngIf="event.name  && showCircuit">
                <h2>Inscrición</h2>

                <!-- Accordion -->
                <div class="accordion accordion-flush accordion-lg" id="accordionFAQSupport">
                    <!-- Accordion Item -->
                    <div class="accordion-item">
                        <div class="accordion-header" id="headingSupportOne">
                            <a class="accordion-button collapsed" role="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseSupportOne" aria-expanded="false"
                                aria-controls="collapseSupportOne">
                                Canto custa a inscrición?
                            </a>
                        </div>
                        <div id="collapseSupportOne" class="accordion-collapse collapse"
                            aria-labelledby="headingSupportOne" data-bs-parent="#accordionFAQSupport">
                            <div class="accordion-body">
                                A inscrición na carreira ten un custo de 10 euros para os adultos e 5 euros para os
                                nenos. A inscrición na andaina ten un custo de 10 euros.
                            </div>
                        </div>
                    </div>
                    <!-- End Accordion Item -->

                    <!-- Accordion Item -->
                    <div class="accordion-item">
                        <div class="accordion-header" id="headingSupportTwo">
                            <a class="accordion-button collapsed" role="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseSupportTwo" aria-expanded="false"
                                aria-controls="collapseSupportTwo">
                                Cando podo recoller o meu dorsal?
                            </a>
                        </div>
                        <div id="collapseSupportTwo" class="accordion-collapse collapse"
                            aria-labelledby="headingSupportTwo" data-bs-parent="#accordionFAQSupport">
                            <div class="accordion-body">
                                Uns días antes da carreira avisaremos a tódolos participantes do procedemento para a
                                recollida dos dorsais.
                            </div>
                        </div>
                    </div>
                    <!-- End Accordion Item -->

                    <!-- Accordion Item -->
                    <div class="accordion-item">
                        <div class="accordion-header" id="headingSupportThree">
                            <a class="accordion-button collapsed" role="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseSupportThree" aria-expanded="false"
                                aria-controls="collapseSupportThree">
                                Podo recoller o dorsal dun familiar ou amigo?
                            </a>
                        </div>
                        <div id="collapseSupportThree" class="accordion-collapse collapse"
                            aria-labelledby="headingSupportThree" data-bs-parent="#accordionFAQSupport">
                            <div class="accordion-body">
                                Por suposto, no momento da recollida do dorsal poderás recoller tamén os dorsais de
                                familiares ou amigos.
                            </div>
                        </div>
                    </div>
                    <!-- End Accordion Item -->

                </div>
                <!-- End Accordion -->
            </div>

            <div class="d-grid gap-3" *ngIf="circuit.name && showCircuit">
                <h2>Circuito</h2>

                <!-- Accordion -->
                <div class="accordion accordion-flush accordion-lg" id="accordionFAQSupport">
                    <!-- Accordion Item -->
                    <div class="accordion-item">
                        <div class="accordion-header" id="headingSupportOne">
                            <a class="accordion-button collapsed" role="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseSupportOne" aria-expanded="false"
                                aria-controls="collapseSupportOne">
                                Canto custa a inscrición?
                            </a>
                        </div>
                        <div id="collapseSupportOne" class="accordion-collapse collapse"
                            aria-labelledby="headingSupportOne" data-bs-parent="#accordionFAQSupport">
                            <div class="accordion-body">
                                A inscrición na carreira ten un custo de 10 euros para os adultos e 5 euros para os
                                nenos. A inscrición na andaina ten un custo de 10 euros.
                            </div>
                        </div>
                    </div>
                    <!-- End Accordion Item -->

                    <!-- Accordion Item -->
                    <div class="accordion-item">
                        <div class="accordion-header" id="headingSupportTwo">
                            <a class="accordion-button collapsed" role="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseSupportTwo" aria-expanded="false"
                                aria-controls="collapseSupportTwo">
                                Cando podo recoller o meu dorsal?
                            </a>
                        </div>
                        <div id="collapseSupportTwo" class="accordion-collapse collapse"
                            aria-labelledby="headingSupportTwo" data-bs-parent="#accordionFAQSupport">
                            <div class="accordion-body">
                                Uns días antes da carreira avisaremos a tódolos participantes do procedemento para a
                                recollida dos dorsais.
                            </div>
                        </div>
                    </div>
                    <!-- End Accordion Item -->

                    <!-- Accordion Item -->
                    <div class="accordion-item">
                        <div class="accordion-header" id="headingSupportThree">
                            <a class="accordion-button collapsed" role="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseSupportThree" aria-expanded="false"
                                aria-controls="collapseSupportThree">
                                Podo recoller o dorsal dun familiar ou amigo?
                            </a>
                        </div>
                        <div id="collapseSupportThree" class="accordion-collapse collapse"
                            aria-labelledby="headingSupportThree" data-bs-parent="#accordionFAQSupport">
                            <div class="accordion-body">
                                Por suposto, no momento da recollida do dorsal poderás recoller tamén os dorsais de
                                familiares ou amigos.
                            </div>
                        </div>
                    </div>
                    <!-- End Accordion Item -->

                </div>
                <!-- End Accordion -->
            </div>

            <div class="d-grid gap-3">
                <h2>Contacto</h2>

                <!-- Accordion -->
                <div class="accordion accordion-flush accordion-lg" id="accordionFAQPayments">
                    <!-- Accordion Item -->
                    <div class="accordion-item">
                        <div class="accordion-header" id="headingPaymentsOne">
                            <a class="accordion-button collapsed" role="button" data-bs-toggle="collapse"
                                data-bs-target="#collapsePaymentsOne" aria-expanded="false"
                                aria-controls="collapsePaymentsOne">
                                Qué teño que facer para poñerme en contacto con vos?
                            </a>
                        </div>
                        <div id="collapsePaymentsOne" class="accordion-collapse collapse"
                            aria-labelledby="headingPaymentsOne" data-bs-parent="#accordionFAQPayments">
                            <div class="accordion-body">
                                Podes facelo a través do formulario de contacto habilitado para a carreira: <a
                                    target="_blank"
                                    href="https://www.corunaenmarchacontraocancro.com/contact">contacto</a>
                            </div>
                        </div>
                    </div>
                    <!-- End Accordion Item -->
                </div>
                <!-- End Accordion -->
            </div>
        </div>
    </div>
</div>
<!-- End FAQ -->