import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ContactService } from './contact.service';
import { Email } from './email';
import { EventModel } from 'src/app/core/models/event';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
})
export class ContactComponent implements OnInit {

    email: Email = { email: '', name: '', lastname: '', message: '', phone: '' };

    contactForm!: UntypedFormGroup;

    prueba: string = "";

    sending: boolean = false;
    sent: boolean = false;

    storageUrl: string = "";
    background: string = "";
    event: EventModel;

    constructor(private fb: UntypedFormBuilder, private contactService: ContactService) {
        this.createForm();
    }
    ngOnInit(): void {
        this.configUrls();

        this.getEvent();
    }

    configUrls() {
        if (this.event?.id) {

            this.storageUrl = `${environment.storage.basicUrl}/${environment.storage.competitions}/${this.event.id}/`;

            if (this.event.backgroundImageUrl) {
                this.background = `background-image: url(` + this.storageUrl + this.event.id + `/background-image/` + this.event.backgroundImageUrl + `);`;
            }
        }
    }

    getEvent() {
        var event = localStorage.getItem('event');
        if (event) {
            this.event = JSON.parse(event);
        }
    }

    createForm() {
        this.contactForm = this.fb.group({
            name: ['', Validators.required],
            lastname: ['', Validators.required],
            phone: [''],
            email: ['', Validators.required],
            message: ['', Validators.required]
        });
    }

    send() {
        if (this.contactForm.valid) {
            this.sending = true;
            this.email = { ...this.email, ...this.contactForm.value };
            this.contactService.sendMail(this.event.id, this.email).subscribe(() => {
                this.email = { email: '', name: '', lastname: '', message: '', phone: '' };
                this.sending = false;
                this.sent = true;
            });
        }
    }
}
