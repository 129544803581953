import { Component, OnInit, ViewChild } from '@angular/core';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';
import { EventModel } from 'src/app/core/models/event';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-bib',
  templateUrl: './bib.component.html',
  styleUrls: ['./bib.component.scss']
})

export class BibComponent implements OnInit {
  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow;

  display: any;
  center: google.maps.LatLngLiteral = {
    lat: 42.86039659484313,
    lng: -8.158312429835767
  };
  zoom = 8;

  selectedStore: { lat: number, lng: number, name: string, address: string } | null = null;

  markerOptions: google.maps.MarkerOptions = { draggable: false };
  markerPositions: google.maps.LatLngLiteral[] = [];

  storageUrl: string = "";
  event: EventModel;
  background: string = "";

  stores = [{
    'name': 'Decathlon Marineda City',
    'lat': 43.34367532988014,
    'lng': -8.429116287440639,
    'address': 'Centro Comercial Marineda City, Av. de Arteixo, 43, 15008 A Coruña'
  }, {
    'name': 'Decathlon Oleiros',
    'lat': 43.320293087846686,
    'lng': -8.316549364708916,
    'address': 'Centro Comercial Parque Oleiros, Avenida das Mariñas 53A, 15171 Oleiros'
  }, {
    'name': 'Decathlon City Ourense',
    'lat': 42.33986708141505,
    'lng': -7.861983450985628,
    'address': 'Rúa do Paseo, 8, 32003 Ourense'
  }
    , {
    'name': 'Decathlon Ferrol',
    'lat': 43.537840709124815,
    'lng': -8.109315598842555,
    'address': 'Est. de San Pedro, s/n, 15405 Ferrol, A Coruña'
  }, {
    'name': 'Decathlon Lugo',
    'lat': 43.037369532916365,
    'lng': -7.552321001516358,
    'address': 'Rúa A Parque Empresarial As Gándaras Via A, Parcela R, 27003 Lugo'
  }, {
    'name': 'Decathlon Santiago de Compostela',
    'lat': 42.90223478857976,
    'lng': -8.509722916854388,
    'address': 'Rúa de Polonia, 2, 15707 Santiago de Compostela, A Coruña'
  }, {
    'name': 'Decathlon Vigo',
    'lat': 42.23840587650159,
    'lng': -8.629438470069001,
    'address': 'Av. de Madrid, 158, 36214 Vigo, Pontevedra'
  }]

  constructor() {
    const event = JSON.parse(localStorage.getItem('event') || '{}');
    this.event = event;
  }

  ngOnInit() {
    this.storageUrl = `${environment.storage.basicUrl}/${environment.storage.competitions}/${this.event.id}/`;

    if (this.event.backgroundImageUrl) {
      this.background = `background-image: url(` + this.storageUrl + this.event.id + `/background-image/` + this.event.backgroundImageUrl + `);`;
    }

  }

  openInfoWindow(marker: MapMarker, store: any) {
    this.selectedStore = store;
    this.infoWindow.open(marker);
  }

  moveMap(event: google.maps.MapMouseEvent) {
    if (event.latLng != null) this.center = (event.latLng.toJSON());
  }

  move(event: google.maps.MapMouseEvent) {
    if (event.latLng != null) this.display = event.latLng.toJSON();
  }

  addMarker(event: google.maps.MapMouseEvent) {
    if (event.latLng != null) this.markerPositions.push(event.latLng.toJSON());
  }

}