export enum ResultStatus {
    Ok = "ok", //ACT
    Disqualified = "disqualified",//DQ
    Abandon = "abandon",//DNF
    NoShow = "noShow"// en balnco
}


export class ResultStatusType {
    label: string;
    value: ResultStatus;
    names: string[];
    static types: ResultStatusType[] = [
        { value: ResultStatus.Ok, label: 'ACT', names: ["act", "ok"] },
        { value: ResultStatus.Disqualified, label: 'DQ', names: ["descalificado", "dq"] },
        { value: ResultStatus.Abandon, label: 'DNF', names: ["abandono", "dnf"] },
        { value: ResultStatus.NoShow, label: 'DNS', names: ["no presentado"] }
    ]
    static mapStatus(status: ResultStatus): string {
        return this.types.filter(t => t.value == status).map<string>(f => f.label)[0];
    }

    static getStatusTypeByStatus(status: ResultStatus): ResultStatusType {
        return this.types.find(t => t.value == status);
    }

    static getStatusTypeByName(statusName: string): ResultStatusType {
        statusName = statusName.toLocaleLowerCase().replace(/\s/g, "");
        let statusFound = this.types.find(t => t.names.findIndex(v => v == statusName) >= 0);
        return statusFound ? statusFound : null;
    }
    static getAllowedNames(): string[] {
        let names: string[] = [];
        this.types.forEach(type => {
            type.names.forEach(name => {
                names.push(name);
            });
        });

        return names;
    }
}