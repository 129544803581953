import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { AppService } from 'src/app/app.service';
import { addNormalizedUrl } from 'src/app/core/functions';
import { CircuitModel } from 'src/app/core/models/circuit';
import { EventModel } from 'src/app/core/models/event';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

    // Navbar Sticky
    isSticky: boolean = false;
    isExpanded = false;
    event?: EventModel;
    circuit?: CircuitModel;
    storageUrl: string = "";
    finished: boolean;

    @HostListener('window:scroll', ['$event'])
    checkScroll() {
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (scrollPosition >= 50) {
            this.isSticky = true;
        } else {
            this.isSticky = false;
        }
    }

    constructor(
        public router: Router, private appService: AppService
    ) { }

    ngOnInit(): void {
        this.appService.tenantSubject.subscribe(() => {
            var event = localStorage.getItem('event');
            if (event) {
                this.event = JSON.parse(event);
                console.log("navbar event", this.event);
                this.event = addNormalizedUrl(this.event);
                this.event.enrollmentUrl = 'https://www.carreirasgalegas.com/events/' + this.event.normalizedName + '/' + this.event.id;
                let eventDate = moment(this.event.date).startOf("day");
                let today = moment().startOf("day");
                console.log("eventDate", eventDate);
                console.log("today", today);
                this.finished = eventDate.isSameOrBefore(today);


                console.log("this.finished", this.finished);
            }
            var circuit = localStorage.getItem('circuit');
            if (circuit)
                this.circuit = JSON.parse(circuit);

            this.configUrls();
        });
    }

    configUrls() {
        if (this.event?.id) {
            this.storageUrl = `${environment.storage.basicUrl}/${environment.storage.competitions}/${this.event.id}/logo/`;
        }

        if (this.circuit?.id) {
            this.storageUrl = `${environment.storage.basicUrl}/${environment.storage.circuits}/${this.circuit.id}/logo/`;
        }
    }

    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }

    searchClassApplied = false;
    toggleSearchClass() {
        this.searchClassApplied = !this.searchClassApplied;
    }

    sidebarClassApplied = false;
    toggleSidebarClass() {
        this.sidebarClassApplied = !this.sidebarClassApplied;
    }

    getUrl(name: string) {
        if (this.event) {
            return `${this.event.url}/${name}`;
        }
        else
            return `${name}`;

    }

}