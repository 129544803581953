import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { addNormalizedUrl } from 'src/app/core/functions';
import { CircuitModel } from 'src/app/core/models/circuit';
import { EventModel } from 'src/app/core/models/event';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

    event?: EventModel;
    circuit?: CircuitModel;
    storageUrl: string = "";
    circuitEvent?: EventModel;

    constructor(private appService: AppService) {
    }

    ngOnInit(): void {
        this.appService.tenantSubject.subscribe(() => {
            var event = localStorage.getItem('event');
            if (event) {
                this.event = addNormalizedUrl(this.event);
                this.event.enrollmentUrl = 'https://www.carreirasgalegas.com/events/' + this.event.normalizedName + '/' + this.event.id;
                this.event = JSON.parse(event);
            }
            var circuit = localStorage.getItem('circuit');
            if (circuit) {
                this.circuit = JSON.parse(circuit);
                console.log("navbar circuit", this.circuit);
                this.circuitEvent = this.getClosestAvailableEvent(this.circuit);
                this.circuitEvent = addNormalizedUrl(this.circuitEvent);
                console.log("navbar circuitEvent", this.circuitEvent);
                this.circuitEvent.enrollmentUrl = 'https://www.carreirasgalegas.com/events/' + this.circuitEvent.normalizedName + '/' + this.circuitEvent.id;
            }

            this.configUrls();


        });
    }

    configUrls() {
        if (this.event?.id) {
            this.storageUrl = `${environment.storage.basicUrl}/${environment.storage.competitions}/${this.event.id}/logo/`;
        }

        if (this.circuit?.id) {
            this.storageUrl = `${environment.storage.basicUrl}/${environment.storage.circuits}/${this.circuit.id}/logo/`;
        }
    }

    getClosestAvailableEvent(circuit: CircuitModel): EventModel | undefined {
        if (!circuit.events || circuit.events.length === 0) {
            return undefined;
        }

        // Obtener la fecha actual
        const now = new Date();

        // Filtrar eventos cuya fecha de inscripción aún no ha expirado
        const availableEvents = circuit.events.filter(event => !event.isClosed);

        // Ordenar los eventos por la cercanía de su fecha respecto a la fecha actual
        const sortedEvents = availableEvents.sort((a, b) => {
            const diffA = Math.abs(now.getTime() - new Date(a.date).getTime());
            const diffB = Math.abs(now.getTime() - new Date(b.date).getTime());
            return diffA - diffB;
        });

        // Devolver el evento más cercano con inscripción abierta
        return sortedEvents[0];
    }
}