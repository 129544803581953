<main id="content" role="main">
    <div class="gradient-x-overlay-sm-primary position-relative overflow-hidden bg-img-black">
        <div class="container content-space-3">
            <div class="w-md-75 text-center mx-md-auto mx-5 py-5">

                <h1 class="display-4 mb-3 text-light">{{circuit.title}} <br /><span
                        class="text-primary">{{circuit.subtitle}}</span>
                </h1>
            </div>

            <div class="row">
                <div class="col-md-4 mb-3 mb-md-5" *ngFor="let event of sortBy(circuit.events); let i = index">


                    <a class="card card-transition text-center h-100"
                        [href]="event.url && event.url.split('|')[1] ? event.url.split('|')[1] : event.url">
                        <img class="card-img-top" [src]="storageUrl + '/' + event.id + '/title-image/' + event.titleUrl"
                            [alt]="event.subtitle" *ngIf="event.titleUrl">
                        <div class="card-body">
                            <div class="stats-box">
                                <div class="icon ms-auto me-auto rounded-circle position-relative"><span
                                        class="race-number">{{ i+1
                                        }}</span></div>
                            </div>

                            <div class="d-flex align-items-center">
                                <h3 class="card-title mx-auto my-3">{{event.name}}</h3>
                            </div>
                            <ul class="info mb-0 list-unstyled">
                                <li class="d-inline-block position-relative"><span *ngIf="event.enrollmentsCount > 2"
                                        class="fw-medium text-primary">{{event.enrollmentsCount}}
                                        Inscritos</span>

                                </li>
                            </ul>
                        </div>

                        <div class="card-footer py-0">
                            <a class="btn btn-primary btn-sm fw-bold"
                                [href]="event.url && event.url.split('|')[1] ? event.url.split('|')[1] : event.url"><span
                                    class="align-middle"><i class="flaticon-calendar"></i></span> {{event.date
                                | date:
                                'longDate'}} ·
                                {{event.races ? event.races[0].time.substring(0,5) + 'h' :
                                'Hora sen confirmar'}}</a>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</main>
<div class="container content-space-2 content-space-b-lg-3 sponsors">
    <div *ngFor="let sponsorType of sponsorTypes">
        <div class="w-md-75 w-lg-50 text-center mx-md-auto mb-6">
            <h2 class="text-primary" *ngIf="findSponsorType(sponsorType, sponsors).length > 0">{{sponsorType.name}}</h2>
        </div>
        <div class="row justify-content-center align-items-center text-center sponsors">
            <div class="col-4 col-sm-3 col-md-3 py-3" *ngFor="let sponsor of findSponsorType(sponsorType, sponsors)">
                <img class="img-fluid" [src]="storageSponsorsUrl + '/' + sponsor.id + '/logo/' + sponsor.logoUrl"
                    [alt]="sponsor.name">
            </div>
        </div>
    </div>
</div>