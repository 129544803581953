import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { EventModel } from 'src/app/core/models/event';

@Component({
    selector: 'app-top-header',
    templateUrl: './top-header.component.html',
    styleUrls: ['./top-header.component.scss']
})
export class TopHeaderComponent implements OnInit {

    state: string = "register";
    event: EventModel;

    constructor(
        public router: Router
    ) {


    }
    ngOnInit(): void {
        this.getEvent();

        if (this.event?.date) {
            console.log("this.event", this.event);
            let competitionDate = moment(this.event.date);
            console.log("competitionDate", competitionDate);
            let today = moment();
            if (today.isSameOrBefore(competitionDate)) {
                this.state = "race";
            }
            else {
                this.state = "results";
            }
        }
    }

    getEvent() {
        let event = localStorage.getItem('event');
        if (event) {
            this.event = JSON.parse(event);
        }
    }

}