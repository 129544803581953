import { Component, OnInit } from '@angular/core';
import { CircuitModel } from 'src/app/core/models/circuit';
import { EventModel } from 'src/app/core/models/event';
import { CircuitsService } from 'src/app/services/circuits.service';
import { EventsService } from 'src/app/services/events.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

  event?: EventModel;
  circuit?: CircuitModel;
  storageUrl: string = "";
  storageNewsUrl: string = "";
  news: any[] = [];
  page: number = 1;
  totalRecords: number = 0;
  background: string = "";

  constructor(private eventsService: EventsService,
    private circuitsService: CircuitsService) {
    const event = JSON.parse(localStorage.getItem('event') || '{}');
    this.event = event;
    var circuit = localStorage.getItem('circuit');
    if (circuit)
      this.circuit = JSON.parse(circuit);
  }

  ngOnInit(): void {
    this.configUrls();
    this.paginate(this.page);
  }


  configUrls() {
    if (this.event?.id) {
      this.storageNewsUrl = `${environment.storage.basicUrl}/${environment.storage.news}/`;

      this.storageUrl = `${environment.storage.basicUrl}/${environment.storage.competitions}/${this.event.id}/`;

      if (this.event.backgroundImageUrl) {
        this.background = `background-image: url(` + this.storageUrl + this.event.id + `/background-image/` + this.event.backgroundImageUrl + `);`;
      }
      console.log("is event");
    }

    if (this.circuit?.id) {
      this.storageNewsUrl = `${environment.storage.basicUrl}/${environment.storage.news}/`;
      this.storageUrl = `${environment.storage.basicUrl}/${environment.storage.circuits}/${this.circuit.id}/logo/`;
      console.log("is circuit");
    }

  }

  selectPage(event) {
    this.paginate(event.first)
  }

  paginate(page: number) {
    this.page = page;
    if (this.event?.id) {
      this.eventsService.getCompetitionNews(this.event.id).subscribe({
        next: (result) => {
          this.news = result.items;
        },
        error: (error) => {

        }
      })
    }

    if (this.circuit?.id) {
      this.circuitsService.getCircuitNews(this.circuit.id).subscribe({
        next: (result) => {
          this.news = result.items;
        },
        error: (error) => {

        }
      })
    }
  }

}
