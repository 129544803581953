import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import * as L from 'leaflet';
import 'leaflet-gpx';
import { DOMParser } from 'xmldom';
import toGeoJSON from '@mapbox/togeojson';
import { HttpClient } from '@angular/common/http';
import { Chart, ChartConfiguration } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { EventsService } from 'src/app/services/events.service';
import { EventModel } from 'src/app/core/models/event';
import { RaceModel } from 'src/app/core/models/race';


@Component({
    selector: 'app-maps',
    templateUrl: './maps.component.html',
    styleUrls: ['./maps.component.scss']
})
export class MapsComponent implements OnInit, AfterViewInit {
    @ViewChild(BaseChartDirective) chart: BaseChartDirective;

    options = {
        layers: [L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '...' })],
        zoom: 8,
        center: [46.879966, -121.726909]
    };

    eventId: string;

    gpxUrl = "";
    gpxContent: string;
    elevations: number[] = [];
    coordinatesArray: [number, number][] = [];
    distances: number[] = [];
    chartLoaded: boolean = false;
    map: L.Map;
    private playbackInterval;
    private currentIndex = 0;
    private mapMarker: L.Marker;

    progressValue: number = 0;
    maxProgressValue: number = 0; // Puedes asignar esto después de cargar el GPX.

    originalZoom: number;

    isPlaying: boolean = false;

    xLabels: string[] = [];
    yValues: number[] = [];

    lineChartData: ChartConfiguration<'line'>['data'] = {
        datasets: [
            {
                data: [],
                label: 'Elevación',
                fill: true,
                borderColor: 'black',
                backgroundColor: 'rgba(255,0,0,0.3)',
                pointRadius: 0,  // <-- Añade esta línea para ocultar los puntos
            },
        ],
    };

    chartOptions = {}

    storageUrl: string;

    event: EventModel;

    selectedRace: RaceModel;

    loadingMap: boolean = true;

    constructor(private http: HttpClient, private eventService: EventsService, private route: ActivatedRoute) {

        const event = JSON.parse(localStorage.getItem('event') || '{}');
        this.event = event;

        this.storageUrl = `${environment.storage.basicUrl}/${environment.storage.races}/`;
    }

    ngOnInit(): void {

        if (this.event?.races)
            this.selectRace(this.event?.races[0]);

        const verticalLinePlugin = {
            id: 'verticalLinePlugin',
            afterDraw: function (chart) {
                const activeElements = chart.getActiveElements();
                if (activeElements?.length) {

                    const activePoint = activeElements[0].element;
                    const ctx = chart.ctx;
                    const x = activePoint.x; // Cambio aquí
                    const topY = chart.scales.y.top;
                    const bottomY = chart.scales.y.bottom;

                    // draw line
                    ctx.save();
                    ctx.beginPath();
                    ctx.moveTo(x, topY);
                    ctx.lineTo(x, bottomY);
                    ctx.lineWidth = 2;
                    ctx.strokeStyle = '#ff0000';
                    ctx.stroke();
                    ctx.restore();
                }

            }
        };
        Chart.register(verticalLinePlugin);
    }

    ngAfterViewInit(): void {
        const checkChartInitialized = setInterval(() => {
            if (this.chart) {
                const ctx = this.chart?.chart?.ctx;

                if (ctx) {
                    const gradient = this.createGradient(ctx);
                    this.lineChartData.datasets[0].backgroundColor = gradient;
                    this.chart?.chart?.update();
                }
                clearInterval(checkChartInitialized);  // Detén el intervalo una vez que se haya hecho el trabajo.
            }
        }, 50);  // Verifica cada 50ms. Ajusta según tus necesidades.
    }


    onMapReady(map: L.Map) {
        this.map = map;
        const gpxTrack = new (L as any).GPX(this.gpxUrl,
            {
                async: true,
                marker_options: {
                    startIcon: new L.Icon({
                        iconUrl: 'assets/img/map/start.webp',
                        iconSize: [52, 52], // Tamaño del ícono de inicio
                        iconAnchor: [26, 52] // Punto de anclaje del ícono de inicio
                    }),
                    endIcon: new L.Icon({
                        iconUrl: 'assets/img/map/finish.webp',
                        iconSize: [52, 52], // Tamaño del ícono de fin
                        iconAnchor: [26, 52] // Punto de anclaje del ícono de fin
                    }),
                    shadowUrl: 'assets/img/map/pin-shadow.png',
                    wptIcons: {
                        '': new L.Icon({
                            iconUrl: 'assets/img/map/checkpoint.webp',
                            iconSize: [23, 41], // Tamaño del ícono de los puntos de paso
                            iconAnchor: [5, 41] // Punto de anclaje del ícono de los puntos de paso
                        })
                    },
                }
            }
        );
        gpxTrack.on('loaded', function (event) {
            map.fitBounds(event.target.getBounds());
        }).addTo(map);
    }

    loadGPX() {

        this.http.get(this.gpxUrl, { responseType: 'text' })
            .subscribe({
                next: (data: string) => {
                    this.gpxContent = data;

                    this.elevations = [];
                    this.distances = [];
                    this.coordinatesArray = [];

                    const parser = new DOMParser();
                    const xmlDoc = parser.parseFromString(this.gpxContent, 'text/xml'); // gpxData es tu archivo GPX como string
                    const geojsonData = toGeoJSON.gpx(xmlDoc);

                    const coordinates = geojsonData?.features?.[0]?.geometry?.coordinates ?? [];

                    for (const element of coordinates) {
                        const elevation = element?.[2];  // 2 es el índice de la elevación en [longitud, latitud, elevación]
                        const lon = element?.[0];
                        const lat = element?.[1];
                        this.elevations.push(elevation);
                        this.coordinatesArray.push([lat, lon]);
                    }

                    let totalDistance = 0;

                    this.distances.push(0);

                    for (let i = 0; i < coordinates.length - 1; i++) {
                        const [lon1, lat1] = coordinates[i];
                        const [lon2, lat2] = coordinates[i + 1];

                        const distance = this.haversineDistance(lat1, lon1, lat2, lon2);
                        totalDistance += distance;
                        this.distances.push(totalDistance);
                    }

                    let kmWaypoints = geojsonData.features
                        .filter(feature => feature.geometry.type === "Point")
                        .map(feature => {
                            return {
                                lat: feature.geometry.coordinates[1],
                                lon: feature.geometry.coordinates[0],
                                // Puedes agregar más propiedades si las necesitas
                                name: feature.properties.name // por ejemplo
                            };
                        });

                    let parent = this;
                    // Función para encontrar la coordenada más cercana a un punto dado.
                    function findClosestCoordinate(lat, lon, coordinates) {
                        let minDistance = Infinity;
                        let closestCoordinate = null;

                        for (const coord of coordinates) {
                            const distance = parent.haversineDistance(lat, lon, coord[1], coord[0]);
                            if (distance < minDistance) {
                                minDistance = distance;
                                closestCoordinate = coord;
                            }
                        }

                        return closestCoordinate;
                    }

                    // Calcula la distancia total para cada waypoint.
                    let waypointsDistances = kmWaypoints.map(waypoint => {
                        // Encuentra la coordenada más cercana.
                        const closestCoord = findClosestCoordinate(waypoint.lat, waypoint.lon, coordinates);

                        // Encuentra el índice de esta coordenada.
                        if (closestCoord) {
                            const index = coordinates.findIndex(coord => coord[0] === closestCoord[0] && coord[1] === closestCoord[1]);

                            // Calcula la distancia desde la coordenada anterior hasta el waypoint.
                            let distanceFromPreviousCoord = 0;
                            if (index > 0) {
                                const previousCoord = coordinates[index - 1];
                                distanceFromPreviousCoord = this.haversineDistance(previousCoord[1], previousCoord[0], waypoint.lat, waypoint.lon);
                            }

                            // Calcula la distancia acumulada hasta esa coordenada.
                            const distanceUpToClosestCoord = this.distances[index];

                            return distanceUpToClosestCoord + distanceFromPreviousCoord;
                        }
                        else return 0;
                    });

                    // Calcula el número de ticks completos de 1000 que tendrás.
                    let numberOfTicks = Math.floor(totalDistance / 1000);

                    // Genera los ticks de 1000 en 1000.
                    let ticks: number[] = [];
                    for (let i = 0; i <= numberOfTicks; i++) {
                        ticks.push(i * 1000);
                    }

                    // Si el último tick no es igual a maxDistance, añade maxDistance a la lista.
                    if (ticks[ticks.length - 1] !== totalDistance) {
                        ticks.push(totalDistance);
                    }

                    this.lineChartData.datasets[0].data = this.elevations;

                    this.lineChartData.labels = this.distances;

                    this.maxProgressValue = this.coordinatesArray.length;

                    this.chartOptions = {
                        responsive: true,
                        hover: {
                            mode: 'index',  // Mostrar el tooltip para el punto más cercano
                            intersect: false  // No se requiere que el ratón intersecte con el punto
                        },
                        plugins: {
                            tooltip: {
                                enabled: true,  // activa o desactiva el tooltip
                                mode: 'index',
                                intersect: false,
                                position: 'nearest',  // Desactiva el tooltip por defecto
                                callbacks: {
                                    label: (context) => {
                                        const km = this.getExactKilometer(context.dataIndex);
                                        return `Elevación: ${context.parsed.y.toFixed(2)} m (Km ${km.toFixed(2)})`;
                                    },
                                    title: () => ''  // Este callback es opcional, pero lo puse aquí para que no se muestre ningún título en el tooltip
                                }
                            },
                            verticalLinePlugin: {},  // Esto activa tu plugin
                            annotation: {
                                annotations: kmWaypoints.map((waypoint, index) => {
                                    return {
                                        type: 'line',
                                        mode: 'vertical',
                                        scaleID: 'x',
                                        value: waypointsDistances[index], // la distancia que calculaste para este waypoint
                                        borderColor: 'rgba(255, 0, 0, 0.5)', // elige un color para la línea
                                        borderWidth: 2,
                                        label: {
                                            content: waypoint.name, // nombre del waypoint
                                            enabled: true,
                                            position: 'top'
                                        }
                                    };
                                })
                            }
                        },
                        onHover: (event, chartElement) => {

                            if (!this.isPlaying) {
                                if (chartElement.length > 0) {
                                    const index = chartElement[0].index;
                                    this.moveMarker(index);
                                }
                            }
                        },
                        scales: {
                            x: {
                                type: 'linear',
                                position: 'bottom',
                                min: 0,
                                max: totalDistance,
                                ticks: {
                                    autoSkip: false,
                                    source: 'data',

                                    // Especifica el conjunto de ticks
                                    callback: function (value, index) {

                                        console.log("value", value);
                                        const formatValue = (value) => {
                                            let kmValue = value / 1000;

                                            // Verifica si kmValue es un entero
                                            if (Number.isInteger(kmValue)) {
                                                return `${kmValue}km`;
                                            } else {
                                                return `${kmValue.toFixed(1)}km`;
                                            }
                                        };

                                        return formatValue(value);
                                    },
                                    // Establecer la rotación del tick
                                    maxRotation: 90,  // Rotación máxima (90 grados hace que el texto quede vertical)
                                    minRotation: 90   // Rotación mínima (mantenerlo igual para asegurar que siempre esté vertical)
                                },
                                afterBuildTicks: function (scale) {
                                    scale.ticks = ticks.map(t => ({ value: t }));
                                },
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Distancia (km)'
                                }
                            },
                            y: {
                                beginAtZero: false,
                                title: {
                                    display: true,
                                    text: 'Elevación (m)',
                                },
                            },
                        }
                    }

                    this.chartLoaded = true;

                    this.loadingMap = false;
                },
                error: (error: any) => {
                    console.error('Error al obtener el archivo GPX:', error);
                }
            });
    }

    moveMarker(index: number) {

        console.log("mover");

        const [lat, lon] = this.coordinatesArray[index];

        const customIcon = L.icon({
            iconUrl: 'assets/img/map/runner.webp', // Cambia esto a la ruta de tu imagen
            iconSize: [35, 52],
            iconAnchor: [17, 52],
            popupAnchor: [0, -52]
        });

        console.log("this.mapMarker", this.mapMarker);

        if (this.mapMarker) {
            // Si ya hay un marcador, simplemente actualiza su posición
            this.mapMarker.setLatLng([lat, lon]);
        } else {
            // Si no hay marcador, crea uno nuevo
            this.mapMarker = L.marker([lat, lon], { icon: customIcon });
            console.log("custom icon");
            this.mapMarker.addTo(this.map);
        }

        this.map.panTo([lat, lon], { animate: true });
    }

    startPlayback() {

        this.isPlaying = true;

        this.originalZoom = this.map.getZoom();

        this.map.setZoom(15);

        // Si hay un intervalo previo en marcha, detenerlo
        if (this.playbackInterval) {
            clearInterval(this.playbackInterval);
        }

        const customIcon = L.icon({
            iconUrl: 'assets/img/map/runner.webp', // Cambia esto a la ruta de tu imagen
            iconSize: [35, 52],
            iconAnchor: [17, 52],
            popupAnchor: [0, -52]
        });

        // Crear un intervalo que actualice el marcador en el mapa
        this.playbackInterval = setInterval(() => {
            this.currentIndex++;
            this.progressValue = this.currentIndex;
            if (this.currentIndex < this.coordinatesArray.length) {
                const [lat, lon] = this.coordinatesArray[this.currentIndex];

                // Mover o crear el marcador
                if (this.mapMarker) {
                    this.mapMarker.setLatLng([lat, lon]);
                } else {
                    this.mapMarker = L.marker([lat, lon], { icon: customIcon }).addTo(this.map);
                }

                this.map.panTo([lat, lon], { animate: true });

                this.currentIndex++;
            } else {
                // Llegamos al final del recorrido, detener el playback
                clearInterval(this.playbackInterval);
            }
        }, 200); // 100ms de intervalo para actualizar, ajústalo según tus necesidades
    }

    pausePlayback() {
        if (this.playbackInterval) {
            clearInterval(this.playbackInterval);
        }

        this.map.setZoom(this.originalZoom);

        this.isPlaying = false;
    }

    resetPlayback() {
        this.currentIndex = 0;
        this.progressValue = this.currentIndex;
        if (this.mapMarker) {
            const [lat, lon] = this.coordinatesArray[this.currentIndex];
            this.mapMarker.setLatLng([lat, lon]);
        }
        if (this.playbackInterval) {
            clearInterval(this.playbackInterval);
        }

        this.map.setZoom(this.originalZoom);

        this.isPlaying = false;
    }

    haversineDistance(lat1, lon1, lat2, lon2) {
        const R = 6371e3; // radio de la Tierra en metros
        const φ1 = lat1 * Math.PI / 180;
        const φ2 = lat2 * Math.PI / 180;
        const Δφ = (lat2 - lat1) * Math.PI / 180;
        const Δλ = (lon2 - lon1) * Math.PI / 180;

        const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) + Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        return R * c;
    }

    getExactKilometer(index: number): number {
        // Asume que "distances" es la matriz que contiene la distancia acumulada en metros
        return this.distances[index] / 1000;
    }

    createGradient(ctx: CanvasRenderingContext2D) {
        const gradient = ctx.createLinearGradient(0, 0, 0, 200);  // Asume que el gráfico tiene una altura de 200px. Ajusta según tus necesidades.
        gradient.addColorStop(0, 'rgba(255, 0, 0, 1)');  // parte superior
        gradient.addColorStop(1, 'rgba(255, 255, 255, 1)');  // parte inferior

        return gradient;
    }

    selectRace(race: RaceModel) {

        if (this.isPlaying) {
            this.resetPlayback();
        }

        this.mapMarker = null;

        this.selectedRace = race;

        this.loadingMap = true;

        this.chartLoaded = false;

        this.gpxUrl = this.storageUrl + this.selectedRace.id + '/route-gpx/' + race.routeGpxUrl;

        if (this.gpxUrl)
            this.loadGPX();
    }


}
