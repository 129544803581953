import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { LazyLoadEvent } from 'primeng/api';
import { HttpParamsBuilder } from 'src/shared/http-params.builder';
import { ResultModel } from '../core/models/result';
import { EventModel } from '../core/models/event';

@Injectable()
export class EventsService {

    version = "api-version=2";

    constructor(private http: HttpClient, private afs: AngularFirestore) { }

    private buildUrlNews(newsId: string): string {
        return `${environment.api}/news/${newsId}`;
    }
    private buildUrlCompetitons(competitionId: string): string {
        return `${environment.api}/competitions/${competitionId}`;
    }
    private buildDiplomasUrl(resultId: string, shortId: string): string {
        return `${environment.api}/diplomas/${resultId}/${shortId}`;
    }

    private getFilterParams(event: LazyLoadEvent): HttpParams {
        let params = HttpParamsBuilder.fromLazyEvent(event);
        return params;
    }

    /**** Competitions****/

    get(competitionId: string) {
        return this.http.get<EventModel>(`${this.buildUrlCompetitons(competitionId)}`);
    }
    getAll(competitionId: string, event: LazyLoadEvent, includeExtras: boolean) {
        let params = HttpParamsBuilder.fromLazyEvent(event);
        if (includeExtras)
            params = params.set("includeExtras", true);
        return this.http.get<any>(`${this.buildUrlCompetitons(competitionId)}/results`, {
            params: params
        })
    }

    getRunnersCount(competitionId: string, event: LazyLoadEvent, includeExtras: true): Observable<number> {
        let params = HttpParamsBuilder.fromLazyEvent(event);
        if (includeExtras) {
            params = params.set("includeExtras", true);
        };

        return this.http.get<number>(
            `${this.buildUrlCompetitons(competitionId)}/results/total`,
            {
                params: params
            }
        )
    }

    getAllCompetitionCategories(competitionId: string, raceId?: string) {
        return this.http.get<any[]>(`${this.buildUrlCompetitons(competitionId)}/categories` + (raceId ? '?raceId=' + raceId : ''));
    }
    getResult(competitionId: string, bib: string) {
        return this.http.get<ResultModel>(`${this.buildUrlCompetitons(competitionId)}/results/${bib}`);
    }
    downloadDiploma(resultId: string, shortId: string): Observable<any> {
        return this.http.get<any>(`${this.buildDiplomasUrl(resultId, shortId)}`, { responseType: 'blob' as 'json' })
    }

    countEnrollments(competitionId: string) {
        try {
            return this.afs.collection('competitions-statistics', ref => ref.where('id', '==', competitionId)).valueChanges();
        }
        catch (error) {
            console.error('Error al contar los elementos de la colección:', error)
            throw error;
        }
    }

    getCompetitionStatistics(competitionId: string): Observable<any> {
        return this.afs.collection('competitions-statistics', ref => ref.where('id', '==', competitionId)).valueChanges();
    }

    getCompetitionNews(competitionId: string): Observable<any> {
        return this.http.get<any>(`${this.buildUrlCompetitons(competitionId)}/news`);
    }

    getNews(newsId: string): Observable<any> {
        return this.http.get<any>(`${this.buildUrlNews(newsId)}`);
    }
}
