import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CircuitModel } from '../../core/models/circuit';
import { EventModel } from '../../core/models/event';
import * as moment from 'moment';

@Component({
    selector: 'app-info',
    templateUrl: './info.component.html',
    styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {

    event?: EventModel;
    circuit?: CircuitModel;
    storageUrl: string = "";
    storageRegulationsUrl: string = "";
    finished: boolean;
    background: string = "";

    constructor() { }

    ngOnInit() {
        console.log("info");
        let event = localStorage.getItem('event');
        if (event) {
            this.event = JSON.parse(event);
            let competitionDate = moment(this.event.date).startOf("day");
            let today = moment().startOf("day");
            this.finished = competitionDate.isSameOrBefore(today);
        }
        let circuit = localStorage.getItem('circuit');
        if (circuit)
            this.circuit = JSON.parse(circuit);

        this.configUrls();
    }

    configUrls() {
        if (this.event?.id) {
            this.storageUrl = `${environment.storage.basicUrl}/${environment.storage.competitions}/${this.event.id}/`;

            if (this.event.backgroundImageUrl) {
                this.background = `background-image: url(` + this.storageUrl + this.event.id + `/background-image/` + this.event.backgroundImageUrl + `);`;
            }
        }

        if (this.circuit?.id) {
            this.storageUrl = `${environment.storage.basicUrl}/${environment.storage.circuits}/${this.circuit.id}/`;
            this.storageRegulationsUrl = `${environment.storage.basicUrl}/${environment.storage.circuits}/` + this.circuit.id + `/${environment.storage.regulations}`;
        }
    }


}
