import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable()
export class AppService {

    public tenantSubject = new Subject<string>();

    tenantLoaded() {
        this.tenantSubject.next("true");
    }

}