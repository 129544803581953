<div class="page-banner-area ptb-25" style="background-image: url(assets/images/shapes/page-banner-bg.jpg);">
    <div class="container">
        <div class="page-banner-content text-center">
            <h1>Mapas</h1>
            <ul class="ps-0 mb-0 list-unstyled">
                <li class="d-inline-block position-relative"><a routerlink="/" href="/">Inicio</a></li>
                <li class="d-inline-block position-relative">Mapas</li>
            </ul>
        </div>
    </div>
</div>
<div class="p-5">
    <div class="position-relative bg-ig-primary overflow-hidden">

        <section class="s-race">
            <div class="container">
                <div class="row">
                    <div class="map" *ngIf="event">
                        <div *ngIf="event?.races?.length>0" class="row align-items-center justify-content-center mb-5">
                            <div class="col-md-3 col-xl-auto mb-3 mb-xl-0" *ngFor="let race of event?.races">
                                <a class="default-btn out default-btn-sm" *ngIf="race.routeGpxUrl"
                                    [ngClass]="race.id == selectedRace?.id ? 'active' : ''"
                                    (click)="selectRace(race)">{{race.name}}</a>
                            </div>
                        </div>
                        <ng-container *ngIf="selectedRace && selectedRace.routeGpxUrl && !loadingMap">
                            <h3 class="text-center">{{selectedRace.name}}</h3>
                            <div leaflet [leafletOptions]="options" (leafletMapReady)="onMapReady($event)"></div>
                            <div class="buttons">
                                <a class="default-btn out default-btn-sm" *ngIf="!isPlaying"
                                    (click)="startPlayback()">Play</a>
                                <a class="default-btn out default-btn-sm" *ngIf="isPlaying"
                                    (click)="pausePlayback()">Pause</a>
                                <a class="default-btn out default-btn-sm" (click)="resetPlayback()">Reset</a>
                                <progress [value]="progressValue" [max]="maxProgressValue"></progress>
                            </div>
                            <canvas class="d-none d-lg-block" #chartRef *ngIf="chartLoaded" height="45" baseChart
                                [options]="chartOptions" [data]="lineChartData" [type]="'line'">
                            </canvas>
                            <canvas class="d-block d-lg-none" #chartRef *ngIf="chartLoaded" height="100" baseChart
                                [options]="chartOptions" [data]="lineChartData" [type]="'line'">
                            </canvas>
                        </ng-container>
                    </div>
                </div>
            </div>
        </section>

    </div>
</div>