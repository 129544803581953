import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { getInfoRecords } from 'src/app/core/functions';
import { CategoryModel } from 'src/app/core/models/category';
import { Gender, GenderType } from 'src/app/core/enums/gender-type';
import { EnrollmentSearchBasicModel } from 'src/app/core/models/enrollment';
import { environment } from 'src/environments/environment';
import { EnrollmentsService } from 'src/app/services/enrollments.service';
import { EventsService } from 'src/app/services/events.service';
import { EventModel } from 'src/app/core/models/event';

@Component({
  selector: 'app-bibs',
  templateUrl: './bibs.component.html',
  styleUrls: ['./bibs.component.scss']
})

export class BibsComponent implements OnInit {

  public loading: boolean;
  public results: EnrollmentSearchBasicModel[];
  public totalRecords: number = 0;
  public infoRecords: string = "";
  public actualIndex = 0;
  public size: number = 50;
  public searchTerm: string;
  public races: any = [];
  public enrollments: any = [];
  public categories: CategoryModel[] = [];
  public genderType = Gender;
  public event: EventModel;
  public eventId: string;
  public raceId: string;
  public error: boolean;
  public storageUrl: string = "";
  public storageBibs: string = '';
  public background: string = "";


  constructor(private enrollemntsService: EnrollmentsService,) {

    const event = JSON.parse(localStorage.getItem('event') || '{}');
    this.event = event;

  }

  ngOnInit() {

    this.storageBibs = `${environment.storage.basicUrl}/${environment.storage.competitions}/${this.event.id}/${environment.storage.bibsDocuments}/`
    this.paginate({ first: this.actualIndex, rows: this.size });

    this.storageUrl = `${environment.storage.basicUrl}/${environment.storage.competitions}/${this.event.id}/`;

    if (this.event.backgroundImageUrl) {
      this.background = `background-image: url(` + this.storageUrl + this.event.id + `/background-image/` + this.event.backgroundImageUrl + `);`;
    }
  }

  paginate(event) {
    this.actualIndex = event.first;
    this.loading = true;
    this.setFilter(event);
    this.enrollemntsService
      .getEnrollments(this.event.id, event)
      .subscribe({
        next: result => {
          this.enrollments = result.items;
          this.totalRecords = result.count;
          this.infoRecords = getInfoRecords(this.actualIndex, this.size, this.totalRecords, "dorsales");
          this.loading = false;
        },
        error: error => {
          this.loading = false;
        }
      })
  }


  //Búsqueda de resultados
  search() {
    this.paginate({ first: 0, rows: this.size });
  }

  setFilter(event) {
    event.globalFilter = this.searchTerm;
    event.sortField = 'bibNumber';
    event.sortOrder = 1;
    event.filters = {
      hasBib: { value: true }
    };
  }
  getCategory(enrollment) {
    return `${enrollment.category} ${GenderType.getShortLabel(enrollment.gender)}`
  }

}
