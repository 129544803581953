<div class="page-banner-area ptb-25" [style]="background">
  <div class="container">
    <div class="page-banner-content text-center">
      <h1>Resultados</h1>
      <ul class="ps-0 mb-0 list-unstyled">
        <li class="d-inline-block position-relative"><a routerlink="/" href="/">Inicio</a></li>
        <li class="d-inline-block position-relative">Resultados</li>
      </ul>
    </div>
  </div>
</div>
<section class="s-results">
  <p-tabView *ngIf="event && !error" (onChange)="selectTab($event)" [(activeIndex)]="raceIndex">
    <p-tabPanel [header]="race.name" *ngFor="let race of event.races">
      <div class="race-results mb-2">
        <div class="section-title-wrap">
          <div class="records-table-filter">
            <div class="row">
              <a class="btn pdf" *ngIf="race.resultsPdfUrl" target="_blank"
                href="{{storageRaceResults}}{{race.id}}/{{folderResults}}/{{race.resultsPdfUrl}}">Resultados
                en PDF</a>
            </div>
            <div class="row align-items-end justify-content-end">
              <div class="col-xl mb-3 mb-xl-0">
                <h2 class="section-title text-center text-xl-start">{{filter.gender == null ?
                  "Xeral" : (filter.gender == genderType.Male ? 'Masculino' : 'Feminino')}}
                  {{filter.category}}</h2>
              </div>
              <div class="col-md-6 col-xl-auto mb-3 mb-xl-0">
                <div class="btn-group w-100">
                  <a class="btn btn-outline-primary {{filter.gender == genderType.Male ? 'active' : ''}}"
                    (click)="selectGender(genderType.Male)">Masculino</a>
                  <a class=" btn btn-outline-primary {{filter.gender == genderType.Female ? 'active': ''}}"
                    (click)="selectGender(genderType.Female)">Feminino</a>
                </div>
              </div>
              <div class=" col-md-6 col-xl-2 mb-3 mb-xl-0">
                <p-dropdown [options]="categories" [(ngModel)]="filter.category" [autoDisplayFirst]="false"
                  (onChange)="search()" [showClear]="true" optionLabel="name" optionValue="name"
                  placeholder="categoría"></p-dropdown>

              </div>
              <div class="col-xl-4">
                <form>
                  <div class="search"> <i class="fa fa-search"></i> <input title="search" type="text" pInputText
                      [(ngModel)]="searchTerm" placeholder="Buscar" [ngModelOptions]="{standalone: true}"
                      (ngModelChange)="search()" class="form-control">
                  </div>
                </form>
              </div>
            </div>
            <div class="d-none d-xl-block section-title-separator"></div>
          </div>

        </div>
        <div class="race-results mb-2" *ngIf="totalRecords == 0 && !loading">
          Aínda non hai resultados
        </div>
        <div *ngIf="loading">
          <p-progressSpinner></p-progressSpinner>
        </div>
        <div class="records-table" *ngIf="totalRecords > 0 && !loading">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col" width="10">POSTO</th>
                  <th scope="col">ATLETA</th>
                  <th scope="col">CATEGORÍA</th>
                  <th scope="col" class="text-center d-none d-lg-block">TEMPOS</th>
                  <th scope=" col" class="d-none d-sm-table-cell"></th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let result of results; let i = index">
                  <tr class="main-tr">
                    <th scope="row">{{result.position}}</th>
                    <td>
                      <div class="d-flex align-items-center">
                        <div class="res-circle d-none d-md-block">
                          <div class="circle-txt">
                            {{result.name.trim()[0]}}{{result.surname.trim()[0]}}
                          </div>
                        </div>
                        <div class="flex-grow-1 ms-sm-3">
                          <div class="runner">{{result.name.trim()}}
                            {{result.surname.trim()}}<span>
                              ({{result.bib}})</span></div>
                          <div *ngIf="result.club" class="club">{{result.club| titlecase}}
                          </div>
                          <div class="club">
                            <span *ngIf="result.locality"><span class="city">{{result.locality}}</span></span>
                          </div>
                        </div>
                      </div>

                    </td>
                    <td [innerHTML]="getCategoryGender(result)">
                    <td>
                      <div class="row justify-content-center">
                        <ng-container *ngIf="result.hasTimes">
                          <ng-container *ngFor="let time of result.times; let j = index">
                            <div *ngIf="time != '00:00:00'" class="col-auto d-md-block"
                              [ngClass]="j != result.times.length - 1 || result.time ? 'd-none' : ''">
                              <div class="interval-wrapper">
                                <div class="interval">
                                  <i class="fa-solid fa-person-running"></i>
                                  <span> {{getStep(result, j)}}</span>
                                </div>
                                <div class="record-interval">
                                  {{time? time:"-"}}
                                </div>
                              </div>
                            </div>
                          </ng-container>
                        </ng-container>
                        <div class="col-auto" *ngIf="result.time">
                          <div class="interval-wrapper final">
                            <div class="interval">
                              <i class="fa-regular fa-clock"></i>
                              <span><strong> Tempo</strong></span>
                            </div>
                            <div class="record-interval">
                              {{
                              result.time ?
                              (result.time == '00:00:00' ? result.provisionalTime
                              :
                              result.time)
                              : result.provisionalTime }}
                            </div>
                          </div>
                        </div>
                        <div class="col-auto" *ngIf="result.netTime">
                          <div class="interval-wrapper final">
                            <div class="interval">
                              <i class="fa-regular fa-clock"></i>
                              <span><strong> Neto</strong></span>
                            </div>
                            <div class="record-interval">
                              {{ result.netTime }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="result">
                        <a class="btn" [routerLink]="'/result/' + result.bib">+</a>
                      </div>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
          <div class="pagination mb-5">
            <p-paginator [first]="actualIndex" [rows]="50" [totalRecords]="totalRecords"
              (onPageChange)="paginate($event)"></p-paginator>
            <span>{{infoRecords}}</span>
          </div>

        </div>
        <div class="records-table" *ngIf="totalRecordsIssues">
          <div class="table-responsive">
            <h2 class="section-title text-center text-xl-start">Sin clasificar</h2>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col" width="10">POSTO</th>
                  <th scope="col">ATLETA</th>
                  <th scope="col">CATEGORÍA</th>
                  <th scope="col" class="text-center d-none d-md-block">TEMPOS</th>
                  <th scope="col">ESTADO</th>
                  <th scope="col">MOTIVO</th>
                  <th scope="col" class="d-none d-sm-table-cell"></th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let result of resultsIssues; let i = index">
                  <tr class="main-tr">
                    <th scope="row">{{result.position}}</th>
                    <td>
                      <div class="d-flex align-items-center">
                        <div class="res-circle d-none d-md-block">
                          <div class="circle-txt">{{result.name[0]}}{{result.surname[0]}}
                          </div>
                        </div>
                        <div class="flex-grow-1 ms-sm-3">
                          <div class="runner">{{result.name}} {{result.surname}}<span>
                              ({{result.bib}})</span></div>
                          <div *ngIf="result.club" class="club">{{result.club| titlecase}}
                          </div>
                          <div class="club">
                            <span *ngIf="result.locality"><span class="city">{{result.locality}}</span></span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>{{result.gender == "male" ? "H" : "M"}} - {{result.category}}</td>
                    <td class="d-none d-md-block">
                      <div class="row justify-content-center">
                        <ng-container *ngIf="result.hasTimes">
                          <div *ngFor="let time of result.times; let j = index" class="col-auto d-none d-md-block">
                            <div class="interval-wrapper">
                              <div class="interval">
                                <i class="fa-solid fa-person-running"></i>
                                <span>{{getStep(result, j)}}</span>
                              </div>
                              <div class="record-interval">
                                {{time? time : "-"}}</div>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </td>
                    <td>{{getStatus(result.status)}}</td>
                    <td>{{result.reason}}
                    </td>
                    <td>
                      <div class="result">
                        <a class="btn" [routerLink]="'/result/' + result.bib">+</a>
                      </div>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
          <div class="pagination mb-5">
            <p-paginator [rows]="size" [totalRecords]="totalRecordsIssues"
              (onPageChange)="paginateIssues($event)"></p-paginator>
            <span>{{infoRecordsIssues}}</span>
          </div>

        </div>
      </div>

    </p-tabPanel>
  </p-tabView>
</section>