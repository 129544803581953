import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CircuitModel } from '../../core/models/circuit';
import { EventModel } from '../../core/models/event';
import { SponsorModel, SponsorType } from '../../core/models/sponsor';
import { EnrollmentsService } from 'src/app/services/enrollments.service';
import { LazyLoadEvent } from 'primeng/api';

@Component({
  selector: 'app-circuit',
  templateUrl: './circuit.component.html',
  styleUrls: ['./circuit.component.css']
})
export class CircuitComponent implements OnInit {

  circuit: CircuitModel;
  sponsorTypes: SponsorType[] = [{ name: 'Organizan', type: 'organizer' }, { name: 'Patrocinan', type: 'sponsor' }, { name: 'Colaboran', type: 'collaborator' }];
  sponsors: SponsorModel[];
  storageUrl: string = "";
  storageSponsorsUrl: string = "";

  constructor(private enrollmentsService: EnrollmentsService) {
    var circuit = JSON.parse(localStorage.getItem('circuit') || '{}');
    this.circuit = circuit;
    this.sponsors = circuit.sponsors;
  }

  ngOnInit() {
    this.configUrls();

    this.circuit.events.forEach(event => {
      this.getEnrollmentCount(event);
    })
  }

  configUrls() {
    if (this.circuit && this.circuit.id) {
      this.storageUrl = `${environment.storage.basicUrl}/${environment.storage.circuits}`;
      this.storageSponsorsUrl = `${environment.storage.basicUrl}/${environment.storage.sponsors}`;
    }
  }

  findSponsorType(sponsorType: SponsorType, sponsors: SponsorModel[]) {
    return sponsors ? sponsors.filter(s => s.type == sponsorType.type) : [];
  }

  sortBy(events: EventModel[]) {
    return events.sort((a, b) => a.date! > b.date! ? 1 : (a.date === b.date ? 0 : -1));
  }

  getEnrollmentCount(event: EventModel) {
    let eventFilter: LazyLoadEvent = { first: 0, rows: 10 }
    this.enrollmentsService.getEnrollments(event.id, eventFilter).subscribe({
      next: enrollments => {
        event.enrollmentsCount = enrollments.count;
      },
      error: error => {
      }
    });
  }
}
