export const environment = {
  production: true,
  hostname: "dev.corunaenmarchacontraocancro.com",
  api: "https://dev.api.web.carreirasgalegas.com",
  apiTp: "https://dev.api.tp.carreirasgalegas.com",
  storage:
  {
    basicUrl: 'https://racesdev.blob.core.windows.net',
    circuits: "circuits",
    competitions: "competitions",
    regulations: "regulations",
    sponsors: "sponsors",
    races: "races",
    news: "news",
    bibsDocuments: "bibs-documents",
    resultsPdf: "results-pdf"
  },
  firebase: {
    apiKey: "AIzaSyAjoRvF4dqdLdmfgndkMsvHMhJOnXIkYtI",
    authDomain: "racesdev.firebaseapp.com",
    projectId: "racesdev",
    storageBucket: "racesdev.appspot.com",
    messagingSenderId: "475683178110",
    appId: "1:475683178110:web:2ec203c9f127fd026dfa2f"
  },
  firebaseCollections: {
    circuits: "circuits",
    competitions: "competitions",
  },
  useEmulators: false
};
