import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CircuitModel } from 'src/app/core/models/circuit';
import { EventModel } from 'src/app/core/models/event';
import { EventsService } from 'src/app/services/events.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-news-detail',
    templateUrl: './news-detail.component.html',
    styleUrls: ['./news-detail.component.scss']
})

export class NewsDetailComponent implements OnInit {

    event?: EventModel;
    circuit?: CircuitModel;
    storageUrl: string = "";
    storageNewsUrl: string = "";
    id: string;
    news: any;
    newsList: any[];
    background: string = "";

    constructor(private route: ActivatedRoute, private eventsService: EventsService) {
        const event = JSON.parse(localStorage.getItem('event') || '{}');
        this.event = event;

        var circuit = localStorage.getItem('circuit');
        if (circuit)
            this.circuit = JSON.parse(circuit);

        this.route.params.subscribe(params => {
            console.log("params", params);
            this.id = params.id;
        })

    }

    ngOnInit() {


        this.configUrls();

        console.log("id", this.id);

        this.eventsService.getNews(this.id).subscribe({
            next: (next) => {
                this.news = next;
            },
            error: (error) => {
                console.log(error);
            }
        })

        if (this.event?.id) {
            this.eventsService.getCompetitionNews(this.event.id).subscribe({
                next: (next) => {
                    console.log("next", next);
                    this.newsList = next.items;
                },
                error: (error) => {
                    console.log(error);
                }
            })
        }
    }

    configUrls() {
        if (this.event?.id) {
            this.storageNewsUrl = `${environment.storage.basicUrl}/${environment.storage.news}/`;

            this.storageUrl = `${environment.storage.basicUrl}/${environment.storage.competitions}/${this.event.id}/`;

            if (this.event.backgroundImageUrl) {
                this.background = `background-image: url(` + this.storageUrl + this.event.id + `/background-image/` + this.event.backgroundImageUrl + `);`;
            }
        }

        if (this.circuit?.id) {
            this.storageNewsUrl = `${environment.storage.basicUrl}/${environment.storage.news}/`;
            this.storageUrl = `${environment.storage.basicUrl}/${environment.storage.circuits}/${this.circuit.id}/logo/`;
            console.log("is circuit");
        }
    }


}
