<div class="page-banner-area ptb-25" [style]="background">
    <div class="container">
        <div class="page-banner-content text-center">
            <h1>Resultados</h1>
            <ul class="ps-0 mb-0 list-unstyled">
                <li class="d-inline-block position-relative"><a routerlink="/" href="/">Inicio</a></li>
                <li class="d-inline-block position-relative">Resultados</li>
            </ul>
        </div>
    </div>
</div>
<div class="p-5">

    <div class="container content-space-2">
        <div class="container">
            <h2 class="section-title text-center text-xl-start mb-3">Resumo da túa carreira</h2>
            <div class="d-none d-xl-block section-title-separator mb-3"></div>
            <div *ngIf="!loading && result">

                <div class="row align-items-center">
                    <div class="col-12 col-xl-2">
                        <div class="res-circle">
                            <div class="circle-txt">{{result.name[0]}}{{result.surname[0]}}</div>
                        </div>
                    </div>
                    <div class="col-12 col-xl-7 py-4 text-center text-xl-start">
                        <div class="row">
                            <div class="col-4 mb-3">
                                <div class="results-detail-title">
                                    Nome
                                </div>
                                <div class="results-detail-text">
                                    {{result.name}}
                                </div>
                            </div>
                            <div class="col-4 mb-3">
                                <div class="results-detail-title">
                                    Apelidos
                                </div>
                                <div class="results-detail-text">
                                    {{result.surname}}
                                </div>
                            </div>
                            <div class="col-4 mb-3">
                                <div class="results-detail-title">
                                    Dorsal
                                </div>
                                <div class="results-detail-text">
                                    {{result.bib}}
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4 mb-3">
                                <div class="results-detail-title">
                                    Localidade
                                </div>
                                <div class="results-detail-text">
                                    {{result.locality}}
                                </div>
                            </div>
                            <div class="col-4 mb-3">
                                <div class="results-detail-title">
                                    Xénero
                                </div>
                                <div class="results-detail-text">
                                    {{result.gender == "male" ? "H" : "M"}}
                                </div>
                            </div>
                            <div class="col-4 mb-3">
                                <div class="results-detail-title">
                                    Categoría
                                </div>
                                <div class="results-detail-text">
                                    {{result.category}}
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4">
                                <div class="results-detail-title">
                                    Posición categoría
                                </div>
                                <div class="results-detail-text">
                                    {{result.categoryPosition}}
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="results-detail-title">
                                    Posición Xénero
                                </div>
                                <div class="results-detail-text">
                                    {{result.genderPosition}}
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="results-detail-title">
                                    Club
                                </div>
                                <div class="results-detail-text">
                                    {{result.club}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-xl-3 text-center">
                        <div class="row">
                            <div class="col-6">
                                <div class="results-detail-title f-time-title">
                                    <i class="fa-regular fa-clock"></i> TEMPO
                                </div>
                                <div class="results-detail-text f-time mb-3">
                                    {{result.time ? result.time : result.times[result.times.length - 1]}}
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="results-detail-title f-time-title">
                                    <i class="fa-regular fa-clock"></i> TEMPO NETO
                                </div>
                                <div class="results-detail-text f-time mb-3">
                                    {{result.netTime}}
                                </div>
                            </div>
                        </div>
                        <div class="position-circle">
                            <div class="results-detail-title f-position-title">
                                <i class="fa-solid fa-medal"></i> RANKING
                            </div>

                            <div class="res-circle-2">
                                <div class="circle-txt-2">{{result.position}}</div>
                            </div>
                        </div>

                    </div>
                </div>
                <ng-container *ngIf="result.hasTimes">
                    <h2 class="section-title text-center text-xl-start mt-4">Pasos</h2>
                    <div class="d-none d-xl-block section-title-separator mb-3"></div>
                    <div class="results-detail-times">
                        <div class="row">
                            <ng-container *ngFor="let time of result.times; let j = index">
                                <div class="col-3 col-xl">
                                    <div class="interval-wrapper">
                                        <div class="interval">
                                            <i class="fa-solid fa-person-running"></i>
                                            <span>{{getStep(result, j)}}</span>
                                        </div>
                                        <div *ngIf="time" class="record-interval">{{time}}</div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="row mt-4">
                <div class="col-12 col-xl-6" *ngIf="result && result.videoUrl">
                    <h2 class="section-title text-center text-xl-start">Chegada a meta</h2>
                    <div class="d-none d-xl-block section-title-separator mb-3"></div>
                    <iframe class="youtube" [src]="transform(result.videoUrl)" frameborder="0" allowfullscreen></iframe>
                </div>
                <div class="col-12 col-xl-6 text-center" *ngIf="hasDiploma">
                    <h2 class="section-title text-center text-xl-start">Descarga o teu diploma</h2>
                    <div class="d-none d-xl-block section-title-separator mb-3">
                    </div>
                    <p-button label="Descargar diploma" styleClass="btn diploma" [loading]="saving"
                        (click)="downloadDiploma()" type="submit">
                    </p-button>
                </div>
            </div>
        </div>
    </div>
</div>