import { Component, OnInit } from '@angular/core';
import { CircuitModel } from 'src/app/core/models/circuit';
import { EventModel } from 'src/app/core/models/event';
import { CircuitsService } from 'src/app/services/circuits.service';
import { EventsService } from 'src/app/services/events.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-routes',
  templateUrl: './routes.component.html',
  styleUrls: ['./routes.component.scss']
})
export class RoutesComponent implements OnInit {

  event?: EventModel;
  circuit?: CircuitModel;
  storageUrl: string = "";
  storageRouteUrl: string = "";
  news: any[] = [];
  page: number = 1;
  totalRecords: number = 0;
  background: string = "";
  routeUrls: { eventName: string, raceName: string, routeUrl: string }[] = [];

  constructor() {
    const event = JSON.parse(localStorage.getItem('event') || '{}');
    this.event = event;
    var circuit = localStorage.getItem('circuit');
    if (circuit)
      this.circuit = JSON.parse(circuit);
  }

  ngOnInit(): void {
    this.configUrls();
  }

  configUrls() {

    this.storageRouteUrl = `${environment.storage.basicUrl}/`;

    if (this.event?.id) {

      this.storageUrl = `${environment.storage.basicUrl}/${environment.storage.competitions}/${this.event.id}/`;

      if (this.event.backgroundImageUrl) {
        this.background = `background-image: url(` + this.storageUrl + this.event.id + `/background-image/` + this.event.backgroundImageUrl + `);`;
      }

      this.getAllEventRouteUrls(this.event);
    }

    if (this.circuit?.id) {
      this.storageUrl = `${environment.storage.basicUrl}/${environment.storage.circuits}/${this.circuit.id}/logo/`;
      this.getAllCircuitRouteUrls(this.circuit);
    }

  }

  getAllCircuitRouteUrls(circuit: CircuitModel) {

    // Recorrer cada evento en el circuito
    circuit.events.forEach(event => {
      // Verificar si el evento tiene carreras
      if (event.races) {
        // Recorrer cada carrera en el evento
        event.races.forEach(race => {
          // Añadir el nombre y la URL de la carrera a la lista de resultados
          if (race.routeUrl)
            this.routeUrls.push({ eventName: event.name, raceName: race.name, routeUrl: this.storageRouteUrl + 'races/' + race.id + '/route/' + race.routeUrl });
        });
      }
    });
  }

  getAllEventRouteUrls(event: EventModel) {

    // Verificar si el evento tiene carreras
    if (event.races) {
      // Recorrer cada carrera en el evento
      event.races.forEach(race => {
        // Añadir el nombre y la URL de la carrera a la lista de resultados
        if (race.routeUrl)
          this.routeUrls.push({ eventName: event.name, raceName: race.name, routeUrl: this.storageRouteUrl + 'races/' + race.id + '/route/' + race.routeUrl });
      });
    }
  }


}
