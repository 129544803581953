<!-- Footer -->
<div class="footer-area position-relative pt-100 pb-75">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-xl-4 col-sm-6 col-md-6">
                <div class="footer-widget">
                    <a routerLink="/" class="logo d-inline-block" *ngIf="event">
                        <img [src]="storageUrl + event.logoUrl" [alt]="event.title">
                    </a>
                    <p *ngIf="event">{{event.title}} {{event.subtitle}}</p>
                    <ul class="social-links ps-0 mb-0 list-unstyled" *ngIf="circuit || event">
                        <li class="d-inline-block">
                            <a href="{{circuit ? circuit.facebookUrl : event.facebookUrl}}" target="_blank"
                                class="position-relative d-block rounded-circle text-center">
                                <i class="bx bxl-facebook"></i>
                            </a>
                        </li>
                        <li class="d-inline-block">
                            <a href="{{circuit ? circuit.twitterUrl : event.twitterUrl}}" target="_blank"
                                class="position-relative d-block rounded-circle text-center">
                                <i class="bx bxl-twitter"></i>
                            </a>
                        </li>
                        <li class="d-inline-block">
                            <a href="{{circuit ? circuit.instagramUrl : event.instagramUrl}}" target="_blank"
                                class="position-relative d-block rounded-circle text-center">
                                <i class='bx bxl-instagram'></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-xl-2 col-sm-6 col-md-6">
                <div class="footer-widget">
                    <h3>Información</h3>
                    <ul class="footer-links ps-0 mb-0 list-unstyled">
                        <li>
                            <a routerLink="/info" class="d-inline-block position-relative">
                                <i class="flaticon-next"></i>
                                O Evento
                            </a>
                        </li>
                        <li>
                            <a routerLink="/info" class="d-inline-block position-relative">
                                <i class="flaticon-next"></i>
                                Regulamento
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-xl-3 col-sm-6 col-md-6">
                <div class="footer-widget">
                    <h3>Inscrición</h3>
                    <ul class="footer-links ps-0 mb-0 list-unstyled">
                        <li>
                            <a *ngIf="event" [href]="event.enrollmentUrl" class="d-inline-block position-relative">
                                <i class="flaticon-next"></i>
                                Inscribirse
                            </a>
                            <a *ngIf="circuitEvent" [href]="circuitEvent.enrollmentUrl"
                                class="d-inline-block position-relative">
                                <i class="flaticon-next"></i>
                                Inscribirse
                            </a>
                        </li>
                        <li>
                            <a routerLink="/results" class="d-inline-block position-relative">
                                <i class="flaticon-next"></i>
                                Resultados
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-xl-3 col-sm-6 col-md-6">
                <div class="footer-widget">
                    <h3>Contacto</h3>
                    <ul class="footer-contact-info ps-0 mb-0 list-unstyled">
                        <li class="position-relative">
                            <div class="icon text-center rounded-circle">
                                <i class='bx bx-phone-call'></i>
                            </div>
                            <a href="tel:+34981291683">
                                +34 981 291 683
                            </a>
                        </li>
                        <li class="position-relative">
                            <div class="icon text-center rounded-circle">
                                <i class='bx bx-envelope'></i>
                            </div>
                            <a href="mailto:hello@toledo.com">info@carreirasgalegas.com</a>
                        </li>
                        <li class="position-relative">
                            <div class="icon text-center rounded-circle">
                                <i class='bx bx-map'></i>
                            </div>
                            Avenida de Glasgow S/N, O Birloque, 15008 A Coruña
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Copyright -->
<div class="copyright-area text-center">
    <div class="container">
        <p>© 2023 - Federación Galega de Atletismo - Developed with ♥ by <a href="https://www.igasoft.es/"
                target="_blank">Igasoft</a> - <a routerLink="/legal">Aviso legal</a> - <a routerLink="/privacy">Política
                de
                privacidad</a></p>
    </div>
</div>