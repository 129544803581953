<div class="page-banner-area ptb-25" [style]="background">
    <div class="container">
        <div class="page-banner-content text-center">
            <h1>Dorsais</h1>
            <ul class="ps-0 mb-0 list-unstyled">
                <li class="d-inline-block position-relative"><a routerlink="/" href="/">Inicio</a></li>
                <li class="d-inline-block position-relative">Dorsais</li>
            </ul>
        </div>
    </div>
</div>
<div class="p-5">
    <section class="mb-3 mb-xl-5 s-enrollments ">
        <div class="container">
            <div class="pb-2 mb-3" *ngIf="event">
                <h4>{{event.place}}, {{event.date | date: 'longDate'}}</h4>
                <h4>{{event.name}}</h4>
            </div>
            <div *ngIf="error">Error </div>
            <div class="event-enrollments mb-2">
                <div class="section-title-wrap">
                    <div class="records-table-filter">
                        <div class="row">
                            <div class="col-md-8">
                                <a class="btn pdf default-btn default-btn-sm" *ngIf="event.bibsUrl" target="_blank"
                                    href="{{storageBibs + event.bibsUrl}}">Dorsais
                                    en PDF</a>
                            </div>
                            <div class="col-md-4 align-items-end justify-content-end">
                                <div class="search"> <i class="fa fa-search"></i> <input title="search" type="text"
                                        pInputText [(ngModel)]="searchTerm" placeholder="Buscar"
                                        [ngModelOptions]="{standalone: true}" (ngModelChange)="search()"
                                        class="form-control">
                                </div>
                            </div>
                            <div class="d-none d-xl-block section-title-separator"></div>
                        </div>
                    </div>
                </div>

                <div class="event-enrollments  mb-2" *ngIf="totalRecords == 0">
                    Non hai dorsais
                </div>
                <div class="mb-2 mt-2" *ngIf="totalRecords > 0">

                    <div class="table-responsive">
                        <table class="table table-striped table-condensed">
                            <thead>
                                <tr>
                                    <th class="text-xl-start"><i class="fa fa-id-card"></i>
                                        Dorsal
                                    </th>
                                    <th class="text-xl-start"><i class="fa-solid fa-person-running"></i>
                                        Nome
                                    </th>
                                    <th class="text-xl-start">
                                        Apelidos
                                    </th>
                                    <th class="text-xl-start"><i class="fa fa-list"></i>
                                        Categoría
                                    </th>
                                    <th class="text-xl-start"><i class="fa fa-arrows-h"></i>
                                        Carreira
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let enrollment of enrollments; let i = index">
                                    <tr class="main-tr">
                                        <td class="col-md-2 text-center text-xl-start">{{enrollment.bib}}</td>
                                        <td class="col-md-2 text-center text-xl-start">{{enrollment.name | titlecase}}
                                        </td>
                                        <td class="d-xl-table-cell col-md-2">{{enrollment.lastName | titlecase}}</td>
                                        <td class="d-xl-table-cell col-md-2">{{getCategory(enrollment)}}</td>
                                        <td class="d-xl-table-cell col-md-2"> {{enrollment.race}}
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>
                    <div class="pagination mb-5">
                        <p-paginator [rows]="size" [totalRecords]="totalRecords"
                            (onPageChange)="paginate($event)"></p-paginator>
                        <span>{{infoRecords}}</span>
                    </div>

                </div>

            </div>

        </div>
    </section>
</div>