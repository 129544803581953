import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ContactComponent } from './pages/contact/contact.component';
import { FaqComponent } from './pages/faq/faq.component';
import { InfoComponent } from './pages/info/info.component';
import { EventResultsComponent } from './pages/results/results.component';
import { LegalComponent } from './pages/legal/legal.component';
import { NewsComponent } from './pages/news/news.component';
import { BibComponent } from './pages/bib/bib.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { CircuitComponent } from './pages/circuit/circuit.component';
import { CookiesComponent } from './cookies/cookies.component';
import { RunnerResultComponent } from './pages/results/runner-result/runner-result.component';
import { NewsDetailComponent } from './pages/news/detail/news-detail.component';
import { MapsComponent } from './pages/maps/maps.component';
import { BibsComponent } from './pages/bibs/bibs.component';
import { StreamingComponent } from './pages/streaming/streaming.component';
import { TrackingComponent } from './pages/tracking/tracking.component';
import { RoutesComponent } from './pages/routes/routes.component';


@NgModule({
    imports: [
        RouterModule.forRoot([
            { path: 'info', component: InfoComponent },
            { path: 'results', component: EventResultsComponent },
            { path: 'results/:raceId', component: EventResultsComponent },
            { path: 'contact', component: ContactComponent },
            { path: 'faq', component: FaqComponent },
            { path: 'routes', component: RoutesComponent },
            { path: 'news', component: NewsComponent },
            { path: 'news/:id', component: NewsDetailComponent },
            { path: 'bib', component: BibComponent },
            { path: 'bibs', component: BibsComponent },
            { path: 'legal', component: LegalComponent },
            { path: 'privacy', component: PrivacyComponent },
            { path: 'cookies', component: CookiesComponent },
            { path: 'circuit', component: CircuitComponent },
            { path: 'result/:bib', component: RunnerResultComponent },
            { path: 'maps', component: MapsComponent },
            { path: 'streaming', component: StreamingComponent },
            { path: 'tracking', component: TrackingComponent },
            { path: ':tenant', loadChildren: () => import('src/app/pages/home/home.module').then(m => m.HomeModule) },
            {
                path: '',
                pathMatch: 'full',
                loadChildren: () => import('src/app/pages/home/home.module').then(m => m.HomeModule)
            },
            {
                path: '**',
                redirectTo: '',
                pathMatch: 'full',
            },
        ], { scrollPositionRestoration: 'enabled', initialNavigation: 'disabled' })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {

}