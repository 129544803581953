<div class="page-banner-area ptb-25" [style]="background">
    <div class="container">
        <div class="page-banner-content text-center">
            <h1>Novas</h1>
            <ul class="ps-0 mb-0 list-unstyled">
                <li class="d-inline-block position-relative"><a routerlink="/" href="/">Inicio</a></li>
                <li class="d-inline-block position-relative">Novas</li>
            </ul>
        </div>
    </div>
</div>
<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12" *ngIf="id && news?.id">
                <div class="blog-details-desc">
                    <div class="post-thumb">
                        <img [src]="storageNewsUrl + news.id + '/featured-image/' + news.featuredImage"
                            [alt]="news.title">
                    </div>
                    <h3>{{news.title}}</h3>
                    <div class="post-meta">
                        <ul class="ps-0 list-unstyled mb-0">
                            <li class="d-inline-block position-relative">
                                <i class='bx bx-calendar'></i>
                                {{news.date | date}}
                            </li>
                        </ul>
                    </div>
                    <div [innerHTML]="news.content"></div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12" *ngIf="newsList && newsList.length > 0">
                <aside class="widget-area">
                    <div class="widget widget_toledo_posts_thumb">
                        <h3 class="widget-title"><span>Últimas Novas</span></h3>
                        <article class="item" *ngFor="let new of newsList">
                            <a [routerLink]="'/news/' + new.id" class="thumb">
                                <img [src]="storageNewsUrl + new.id + '/featured-image/' + new.featuredImage"
                                    [alt]="new.title">
                            </a>
                            <div class="info">
                                <h4 class="title"><a routerLink="'/news/' + new.id">{{new.title}}</a></h4>
                                <span class="date">{{new.date | date}}</span>
                            </div>
                        </article>
                    </div>
                </aside>
            </div>
        </div>
    </div>
</div>