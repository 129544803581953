import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from "src/environments/environment";
import { map, takeUntil } from 'rxjs/operators';
import { ResultModel, ResultFilterModel } from 'src/app/core/models/result';
import { Gender, GenderType } from 'src/app/core/enums/gender-type';
import { RaceModel } from 'src/app/core/models/race';
import { EventModel } from 'src/app/core/models/event';
import { CategoryModel } from 'src/app/core/models/category';
import { ResultStatus, ResultStatusType } from 'src/app/core/enums/result-status';
import { RaceStatus } from 'src/app/core/enums/race-status';
import { getInfoRecords } from 'src/app/core/functions';
import { Subject } from 'rxjs';
import { SignalRService } from 'src/app/core/services/signal-r.service';
import { EventsService } from 'src/app/services/events.service';

@Component({
    selector: 'app-results',
    templateUrl: './results.component.html',
    styleUrls: ['./results.component.scss']
})

export class EventResultsComponent implements OnInit, OnDestroy {

    private destroy$: Subject<void> = new Subject<void>();

    public searchTerm: string;
    public updating: boolean;
    public loading: boolean;
    public loadingIssues: boolean;
    public results: ResultModel[];
    public resultsIssues: ResultModel[];
    public selectedResults: ResultModel[];
    public totalRecords: number = 0;
    public totalRecordsIssues: number = 0;
    public infoRecords: string;
    public infoRecordsIssues: string;
    public actualIndexIssues = 0;
    public actualIndex = 0;
    public size: number = 50;
    public filter: ResultFilterModel = {};
    public genders: GenderType[] = GenderType.types;
    public states: ResultStatusType[] = ResultStatusType.types;
    public races: any = [];
    public categories: CategoryModel[] = [];
    public selectedRace: RaceModel;
    public genderType = Gender;
    public event: EventModel;
    public eventId: string;
    public raceId: string;
    public raceIndex: number;
    public error: boolean;

    //Storage
    public storageCompetitionResults: string;
    public storageRaceResults: string;
    public folderResults: string;
    public control: boolean = false;

    public openRows: number[] = [];
    public openRowsIssues: number[] = [];

    public background: string = "";
    public storageUrl: string = "";

    constructor(private eventResultsService: EventsService,
        private router: Router,
        private route: ActivatedRoute,
        private signalRService: SignalRService) {
        this.route.params.subscribe(params => {
            this.raceId = params['raceId'];
        });
    }

    ngOnInit() {

        this.getEvent();

        this.signalRService.startConnection();
        this.signalRService.addRaceUpdateListener((update) => this.handleRaceUpdate(update));
        this.signalRService.addRaceRemoveListener(() => this.handleRaceRemove());

        this.storageCompetitionResults = `${environment.storage.basicUrl}/${environment.storage.competitions}/${this.eventId}/${environment.storage.resultsPdf}/`;
        this.storageRaceResults = `${environment.storage.basicUrl}/${environment.storage.races}/`;
        this.folderResults = environment.storage.resultsPdf;
        this.eventResultsService.get(this.eventId).subscribe({
            next: result => {
                this.event.races = this.event.races ? this.event.races.filter(r => r.hasResults && r.status == RaceStatus.Published) : [];
                if (this.event.races.length > 0) {
                    this.raceIndex = this.raceId && this.event.races.findIndex(r => r.id == this.raceId) >= 0 ?
                        this.event.races.findIndex(r => r.id == this.raceId) : 0;
                    this.selectedRace = this.event.races[this.raceIndex];
                    this.getCategories();
                    this.paginate({ first: this.actualIndex, rows: this.size });
                    this.paginateIssues({ first: this.actualIndexIssues, rows: this.size });
                }
                else {
                    this.error = true;
                }
            }
        });

        this.configUrls();

    }

    configUrls() {
        if (this.event?.id) {

            this.storageUrl = `${environment.storage.basicUrl}/${environment.storage.competitions}/${this.event.id}/`;

            if (this.event.backgroundImageUrl) {
                this.background = `background-image: url(` + this.storageUrl + this.event.id + `/background-image/` + this.event.backgroundImageUrl + `);`;
            }
        }
    }

    private handleRaceRemove(): void {
        console.log("delete");
        this.paginate({ first: 0, rows: this.size });
        this.paginateIssues({ first: 0, rows: this.size });
    }

    private handleRaceUpdate(update: any): void {
        console.log("update", update);
        const actualPage = Math.ceil((this.actualIndex + 1) / this.size);

        let position = update.position;

        if (this.filter.gender && !this.filter.category) {
            position = update.genderPosition;
        }

        if (this.filter.category && !this.filter.gender) {
            position = update.categoryPosition;
        }

        if (this.filter.category && this.filter.gender) {
            position = actualPage;
        }

        if (this.filter.global) {
            position = actualPage;
        }

        const updatedRunnerPage = Math.ceil(position / this.size);

        if (updatedRunnerPage === actualPage || update.status != null || update.status != undefined) {
            this.receiveNewData({ first: this.actualIndex, rows: this.size });
        }
        else {
            this.countRunners({ first: this.actualIndex, rows: this.size })
        }

        if (update.status) {
            this.paginateIssues({ first: 0, rows: this.size });
        }
    }

    //Selección de carrera
    selectTab(event) {

        // Cancela la suscripción anterior antes de cambiar de pestaña
        this.destroy$.next();
        this.destroy$.complete();
        this.destroy$ = new Subject<void>();

        this.loading = true;
        this.selectedRace = this.event.races[event.index];
        this.filter.category = "";

        console.log("this.selectedRace", this.selectedRace)

        this.router.navigate([`/results/${this.selectedRace.id}`]);
        this.getCategories();
        this.paginate({ first: 0, rows: this.size });
        this.paginateIssues({ first: 0, rows: this.size });

    }

    //Selección de género
    selectGender(gender) {
        this.filter.gender = gender != this.filter.gender ? gender : null;
        this.search();
    }

    //Búsqueda de resultados
    search() {
        // Cancela la suscripción anterior antes de buscar
        this.destroy$.next();
        this.destroy$.complete();
        this.destroy$ = new Subject<void>();

        this.paginate({ first: 0, rows: this.size });
        this.paginateIssues({ first: 0, rows: this.size });
    }

    getCategories() {
        this.eventResultsService.getAllCompetitionCategories(this.eventId, this.selectedRace.id).subscribe({
            next: result => {
                this.categories = result;
            },
            error: error => {
                console.log(error);
            }
        })
    }

    getStatus(status: ResultStatus): string {
        return ResultStatusType.mapStatus(status);
    }

    getCategoryGender(result: ResultModel) {
        let value = (result.gender == "male" ? "H" : "M") + " (" + result.genderPosition + ")"
            + "</br>" + result.category + " (" + result.categoryPosition + ")"
        return value;
    }

    getEvent() {
        var event = localStorage.getItem('event');
        if (event) {
            this.event = JSON.parse(event);
            this.eventId = this.event.id!;
        }
    }

    getStep(result: ResultModel, index: number): string {
        return result.checkpoints && result.checkpoints.length > index && result.checkpoints[index] ?
            result.checkpoints[index]
            : `Paso ${index + 1}`;

    }

    //Resultados OK
    paginate(event) {
        this.actualIndex = event.first;
        this.loading = true;
        this.setFilter(event, false);
        this.eventResultsService
            .getAll(this.eventId, event, true).pipe(
                takeUntil(this.destroy$),
                map(results => ({
                    ...results,
                    items: results.items.map(result => {
                        const lastIndexNonNullElement = result.times.reduceRight((lastIndex, value, index) => {
                            if (lastIndex === -1 && value !== null) {
                                return index;
                            }
                            return lastIndex;
                        }, -1);

                        if (lastIndexNonNullElement >= 0) {
                            result.paso = "Paso " + (lastIndexNonNullElement + 1);
                        }
                        return result;
                    }
                    )
                })
                ))
            .subscribe({
                next: result => {
                    this.results = result.items;
                    console.log("results", this.results);
                    this.results.forEach(result => { result.hasTimes = result.times && result.times.filter(r => r).length > 0; });
                    if (this.results.length > 0 && this.results[0].time == '00:00:00') {
                        this.control = true;
                    }
                    this.totalRecords = result.count;
                    this.infoRecords = getInfoRecords(this.actualIndex, this.size, this.totalRecords, "resultados");
                    this.loading = false;
                },
                error: error => {
                    console.log(error);
                    this.loading = false;
                }
            })
    }

    receiveNewData(event) {
        this.actualIndex = event.first;
        this.setFilter(event, false);
        this.getCategories();
        this.eventResultsService
            .getAll(this.eventId, event, true).pipe(
                takeUntil(this.destroy$),
                map(results => ({
                    ...results,
                    items: results.items.map(result => {
                        const lastIndexNonNullElement = result.times.reduceRight((lastIndex, value, index) => {
                            if (lastIndex === -1 && value !== null) {
                                return index;
                            }
                            return lastIndex;
                        }, -1);

                        if (lastIndexNonNullElement >= 0) {
                            result.paso = "Paso " + (lastIndexNonNullElement + 1);
                        }
                        return result;
                    }
                    )
                })
                ))
            .subscribe({
                next: result => {
                    this.results = result.items;
                    this.results.forEach(result => { result.hasTimes = result.times && result.times.filter(r => r).length > 0; });
                    if (this.results.length > 0 && this.results[0].time == '00:00:00') {
                        this.control = true;
                    }
                    this.totalRecords = result.count;
                    this.infoRecords = getInfoRecords(this.actualIndex, this.size, this.totalRecords, "resultados");
                },
                error: error => {
                    console.log(error);
                }
            })
    }

    countRunners(event) {
        this.actualIndex = event.first;
        this.setFilter(event, false);
        this.eventResultsService
            .getRunnersCount(this.eventId, event, true).pipe(
                takeUntil(this.destroy$))
            .subscribe({
                next: result => {
                    this.totalRecords = result;
                    this.infoRecords = getInfoRecords(this.actualIndex, this.size, this.totalRecords, "resultados");
                },
                error: error => {
                    console.log(error);
                }
            })
    }

    //Resultados Issues
    paginateIssues(event) {
        this.actualIndexIssues = event.first;
        this.loadingIssues = true;
        this.setFilter(event, true);
        this.eventResultsService
            .getAll(this.eventId, event, true).pipe(
                takeUntil(this.destroy$)
            )
            .subscribe({
                next: result => {
                    this.resultsIssues = result.items;
                    this.totalRecordsIssues = result.count;
                    this.resultsIssues.forEach(result => { result.hasTimes = result.times && result.times.filter(r => r).length > 0; });
                    this.infoRecordsIssues = getInfoRecords(this.actualIndexIssues, this.size, this.totalRecordsIssues, "resultados");
                    this.loadingIssues = false;
                },
                error: error => {
                    console.log(error);
                    this.loadingIssues = false;
                }
            })
    }

    setFilter(event, issues: boolean) {
        let filters = {
            raceId: { value: this.selectedRace.id }
        };

        if (issues) {
            filters["issuesStatus"] = { value: true };
        }
        if (this.filter.gender) {
            filters["gender"] = { value: this.filter?.gender };
        }

        if (this.filter.category) {
            filters["category"] = { value: this.filter?.category };
        }

        event.filters = filters;
        event.globalFilter = this.searchTerm;
    }

    ngOnDestroy() {
        // Asegúrate de cancelar la suscripción al destruir el componente
        this.destroy$.next();
        this.destroy$.complete();
    }


    getCheckpoints(result) {
        let times = this.removeTrailingZeros(result.times);
        let checkpoints = result.checkpoints[times.length - 1];
        if (checkpoints)
            return checkpoints;
        else return result.paso;
    }

    removeTrailingZeros(times: string[]): string[] {
        let lastNonZeroIndex = times.length - 1;

        // Iterate from the end of the array until a non-"00:00:00" time is found
        while (lastNonZeroIndex >= 0 && times[lastNonZeroIndex] === "00:00:00") {
            lastNonZeroIndex--;
        }

        // Cut the array to remove the trailing "00:00:00"
        return times.slice(0, lastNonZeroIndex + 1);
    }

    toggleRow(index: number): void {

        const i = this.openRows.indexOf(index);
        if (i > -1) {
            this.openRows.splice(i, 1); // Si la fila está abierta, ciérrala.
        } else {
            this.openRows.push(index); // Si la fila está cerrada, ábrela.
        }

        console.log("index", index);

        console.log("openRows", this.openRows);
    }

    getExpanded(index: number) {
        return this.openRows.indexOf(index) > -1;
    }

    toggleRowIssues(index: number): void {
        const i = this.openRowsIssues.indexOf(index);
        if (i > -1) {
            this.openRowsIssues.splice(i, 1); // Si la fila está abierta, ciérrala.
        } else {
            this.openRowsIssues.push(index); // Si la fila está cerrada, ábrela.
        }
    }

    getExpandedIssues(index: number) {
        return this.openRowsIssues.indexOf(index) > -1;
    }
}
