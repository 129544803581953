<!-- Page Banner -->
<div class="page-banner-area ptb-25" [style]="background">
    <div class="container">
        <div class="page-banner-content text-center">
            <h1>Percorridos</h1>
            <ul class="ps-0 mb-0 list-unstyled">
                <li class="d-inline-block position-relative"><a routerLink="/">Inicio</a></li>
                <li class="d-inline-block position-relative">Percorridos</li>
            </ul>
        </div>
    </div>
</div>
<!-- Blog Grid -->
<div class="blog-area ptb-100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6" *ngFor="let route of routeUrls">
                <div class="blog-card">
                    <div class="content">
                        <h3>
                            {{route.eventName}} {{route.raceName}}
                        </h3>
                    </div>
                    <div class="image">
                        <img [src]="route.routeUrl" [alt]="route.eventName + ' ' + route.raceName">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>