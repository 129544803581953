<div class="navbar-area" [ngClass]="{'sticky': isSticky}">
    <div class="container-fluid">
        <nav class="navbar navbar-expand-lg" [class.active]="classApplied">
            <a class="navbar-brand" [routerLink]="getUrl('/')">
                <img *ngIf="event" [src]="storageUrl + event.logoUrl" [alt]="event.title" class="me-3">
                <img *ngIf="circuit" [src]="storageUrl + circuit.logoUrl" [alt]="circuit.title" class="me-3">
                <!--<img class="me-3" src="/assets/images/mock/logo.png">-->
                <span class="title d-none d-sm-inline">{{event?.name}}{{circuit?.name}}</span>
            </a>
            <button class="navbar-toggler" type="button" (click)="toggleClass()">
                <span class="burger-menu">
                    <span class="top-bar"></span>
                    <span class="middle-bar"></span>
                    <span class="bottom-bar"></span>
                </span>
            </button>
            <div class="collapse navbar-collapse">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <a [routerLink]="getUrl('/')" class="nav-link" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}">
                            Inicio
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link" [routerLink]="getUrl('info')">
                            Información
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="event && (event.showBibs || event.bibsWeb)">
                        <a href="javascript:void(0)" class="nav-link" [routerLink]="getUrl('bib')">
                            Dorsais
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link" [routerLink]="getUrl('routes')">
                            Percorridos
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link" [routerLink]="getUrl('news')">
                            Novas
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link" [routerLink]="getUrl('results')">
                            Resultados
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link" [routerLink]="getUrl('contact')">
                            Contacto
                        </a>
                    </li>
                </ul>
                <ul class="navbar-nav for-responsive">
                    <li class="nav-item">
                        <a [routerLink]="getUrl('/')" class="nav-link" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">
                            Inicio
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link" [routerLink]="getUrl('info')"
                            (click)="toggleClass()">
                            Información
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link" [routerLink]="getUrl('bib')"
                            (click)="toggleClass()">
                            Dorsais
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link" [routerLink]="getUrl('routes')">
                            Percorridos
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link" [routerLink]="getUrl('news')"
                            (click)="toggleClass()">
                            Novas
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link" [routerLink]="getUrl('results')"
                            (click)="toggleClass()">
                            Resultados
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link" [routerLink]="getUrl('contact')"
                            (click)="toggleClass()">
                            Contacto
                        </a>
                    </li>
                </ul>
                <div class="others-option d-flex align-items-center" *ngIf="event && !finished">
                    <div class="option-item">
                        <a [href]="event.enrollmentUrl" class="default-btn two">Inscribirme</a>
                    </div>
                </div>
            </div>
        </nav>
    </div>
</div>