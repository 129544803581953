import { Component } from '@angular/core';
import { CircuitModel } from '../../core/models/circuit';
import { EventModel } from '../../core/models/event';

@Component({
    selector: 'app-faq',
    templateUrl: './faq.component.html',
})
export class FaqComponent {
    event: EventModel;
    circuit: CircuitModel;
    showCircuit: boolean = false;
    constructor() {
        const event = JSON.parse(localStorage.getItem('event') || '{}');
        this.event = event;
        console.log("event", this.event);
        var circuit = JSON.parse(localStorage.getItem('circuit') || '{}');
        this.circuit = circuit;
    }
}
