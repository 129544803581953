<div class="page-banner-area ptb-25" style="background-image: url(assets/images/shapes/page-banner-bg.jpg);">
    <div class="container">
        <div class="page-banner-content text-center">
            <h1>Política de Privacidad</h1>
            <p class="text-center">Actualizado a 12 de Maio de 2023</p>
            <ul class="ps-0 mb-0 list-unstyled">
                <li class="d-inline-block position-relative"><a routerlink="/" href="/">Inicio</a></li>
                <li class="d-inline-block position-relative">Dorsais</li>
            </ul>
        </div>
    </div>
</div>
<div class="p-5">

    <main id="content" role="main">

        <!-- Content -->
        <div class="container content-space-1 content-space-md-3">
            <div class="mb-7">
                <p>La presente declaración de privacidad explica qué datos personales se recopilan de los usuarios y
                    cómo se
                    utilizan.</p>
                <p>En esta web se respetan y cuidan los datos personales de los usuarios. Como usuario debes saber que
                    tus
                    derechos están garantizados.</p>
                <p>Nos hemos esforzado en crear un espacio seguro y confiable y por eso queremos compartir nuestros
                    principios respecto a la privacidad de los usuarios:</p>
                <ul>
                    <li>Nunca solicitamos información personal a menos que realmente sea necesaria para prestarte los
                        servicios que requiera el usuario.</li>
                    <li>Nunca compartimos información personal de los usuarios con nadie, excepto para cumplir con la
                        ley o
                        en caso que cuente con tu autorización expresa.</li>
                    <li>Nunca utilizamos los datos personales de los usuarios con una finalidad diferente a la expresada
                        en
                        esta política de privacidad.
                    </li>
                </ul>
                <p>Es preciso advertir que esta Política de Privacidad podría variar en función de exigencias
                    legislativas o
                    de autorregulación, por lo que se aconseja a los usuarios que la visiten periódicamente. Será
                    aplicable
                    en caso de que los usuarios decidan rellenar algún formulario de cualquiera de sus formularios de
                    contacto donde se recaben datos de carácter personal.</p>

                <p>Federación Galega de Atletismo ha adecuado esta web a las exigencias de la Ley Orgánica 3/2018, de 5
                    de
                    diciembre, de Protección de Datos Personales y garantía de los derechos digitales (LOPDGDD), y al
                    Real
                    Decreto 1720/2007, de 21 de diciembre, conocido como el Reglamento de desarrollo de la LOPD. Cumple
                    también con el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016
                    relativo a la protección de las personas físicas (RGPD), así como con la Ley 34/2002, de 11 de
                    julio, de
                    Servicios de la Sociedad de la Información y Comercio Electrónico (LSSICE o LSSI).</p>

                <p>Responsable del tratamiento de datos personales</p>

                <ul>
                    <li>Identidad del Responsable: Federación Galega de Atletismo</li>
                    <li>NIF/CIF: G15103500</li>
                    <li>Dirección: Avda. Glasgow, 13. 15008 A Coruña
                    </li>
                    <li>Correo electrónico: info@carreirasgalegas.com</li>
                    <li>Actividad: Gestión y organización de actividades relacionadas con el atletismo.</li>
                </ul>

                <p>A efectos de lo previsto en el Reglamento General de Protección de Datos antes citado, los datos
                    personales que se envíen a través de los formularios de la web, recibirán el tratamiento de datos de
                    “Usuarios de la web y suscriptores”.</p>

                <p>Para el tratamiento de datos de los usuarios, se implementan todas las medidas técnicas y
                    organizativas
                    de seguridad establecidas en la legislación vigente.</p>

                <p>En el tratamiento de los datos personales, se aplicarán los siguientes principios que se ajustan a
                    las
                    exigencias del nuevo reglamento europeo de protección de datos:</p>

                <ul>
                    <li>Principio de licitud, lealtad y transparencia: Siempre se requerirá el consentimiento del
                        usuario
                        para el tratamiento de sus datos personales para uno o varios fines específicos que se
                        informarán
                        previamente con absoluta transparencia.</li>
                    <li>Principio de minimización de datos: Solo se solicitarán datos estrictamente necesarios en
                        relación
                        con los fines para los que se requieren. Los mínimos posibles.</li>
                    <li>Principio de limitación del plazo de conservación: los datos serán mantenidos durante no más
                        tiempo
                        del necesario para los fines del tratamiento, en función a la finalidad, se informará del plazo
                        de
                        conservación correspondiente, en el caso de suscripciones, periódicamente se revisarán las
                        listas y
                        se eliminarán aquellos registros inactivos durante un tiempo considerable.
                    </li>
                    <li>Principio de integridad y confidencialidad: Los datos serán tratados de tal manera que se
                        garantice
                        una seguridad adecuada de los datos personales y se garantice confidencialidad. Se toman todas
                        las
                        precauciones necesarias para evitar el acceso no autorizado o uso indebido de los datos de los
                        usuarios por parte de terceros.</li>
                </ul>

                <p>¿Cómo he obtenido los datos?</p>

                <p>Los datos personales que se tratan en Federación Galega de Atletismo proceden de:</p>

                <ul>
                    <li>Formulario de contacto</li>
                    <li>Comunicaciones directas con los usuarios</li>
                </ul>
            </div>

            <div id="derechos" class="mb-7">
                <h4>Derechos sobre los datos:</h4>

                <p>Cualquier persona tiene derecho a obtener confirmación sobre si en Federación Galega de Atletismo se
                    están tratando datos personales.</p>

                <p>Las personas interesadas tienen derecho a:</p>

                <ul>
                    <li>Solicitar el acceso a los datos personales relativos al interesado</li>
                    <li>Solicitar su rectificación o supresión</li>
                    <li>Solicitar la limitación de su tratamiento</li>
                    <li>Oponerse al tratamiento</li>
                    <li>Solicitar la portabilidad de los datos</li>
                </ul>

                <p>Los interesados podrán acceder a sus datos personales, así como a solicitar la rectificación de los
                    datos
                    inexactos o, en su caso, solicitar su supresión cuando, entre otros motivos, los datos ya no sean
                    necesarios para los fines que fueron recogidos. En determinadas circunstancias, los interesados
                    podrán
                    solicitar la limitación del tratamiento de sus datos, en cuyo caso únicamente se conservarán para el
                    ejercicio o la defensa de reclamaciones.</p>

                <p>En determinadas circunstancias y por motivos relacionados con su situación particular, los
                    interesados
                    podrán oponerse al tratamiento de sus datos. Federación Galega de Atletismo dejará de tratar los
                    datos,
                    salvo por motivos legítimos imperiosos, o el ejercicio o la defensa de posibles reclamaciones. Cómo
                    interesado, el usuario tiene derecho a recibir los datos personales que le incumban, que hayan sido
                    facilitadoo y en un formato estructurado, de uso común y lectura mecánica, y a transmitirlos a otro
                    responsable del tratamiento cuando:</p>

                <ul>
                    <li>El tratamiento esté basado en el consentimiento</li>
                    <li>Los datos hayan sido facilitados por la persona interesada.</li>
                    <li>El tratamiento se efectúe por medios automatizados.</li>
                </ul>

                <p>Al ejercer el derecho a la portabilidad de los datos, el usuario tendrá derecho a que los datos
                    personales se transmitan directamente de responsable a responsable cuando sea técnicamente posible.
                </p>

                <p>Los interesados también tendrán derecho a la tutela judicial efectiva y a presentar una reclamación
                    ante
                    la autoridad de control, en este caso, la Agencia Española de Protección de Datos, si consideran que
                    el
                    tratamiento de datos personales que le conciernen infringe el Reglamento.</p>
            </div>

            <div id="finalidad" class="mb-7">
                <h4>Finalidad de los datos personales</h4>

                <p>Cuando un usuario se conecta con esta web por ejemplo para mandar un correo al titular, suscribirse o
                    realizar alguna contratación, está facilitando información de carácter personal de la que es
                    responsable
                    Federación Galega de Atletismo. Esa información puede incluir datos de carácter personal como pueden
                    ser: dirección IP, nombre, dirección física, dirección de correo electrónico, número de teléfono, y
                    otra
                    información. Al facilitar esta información, el usuario da su consentimiento para que su información
                    sea
                    recopilada, utilizada, gestionada y almacenada por Federación Galega de Atletismo, sólo como se
                    describe
                    en el Aviso Legal y en la presente Política de Privacidad.</p>

                <p>Existen otras finalidades por la que se tratan los datos personales: </p>
                <ul>
                    <li>Para garantizar el cumplimiento de las condiciones de uso y la ley aplicable. Esto puede incluir
                        el
                        desarrollo de herramientas y algoritmos que ayudan a esta web a garantizar la confidencialidad
                        de
                        los datos personales que recoge.</li>
                    <li>Para apoyar y mejorar los servicios que ofrece esta web.</li>
                    <li>También se recogen otros datos no identificativos que se obtienen mediante algunas cookies que
                        se
                        descargan en el ordenador del usuario cuando navega en esta web que detallo en la política de
                        cookies.</li>
                    <li>Para gestionar las redes sociales. Federación Galega de Atletismo puede tener presencia en redes
                        sociales. El tratamiento de los datos que se lleve a cabo de las personas que se hagan
                        seguidoras en
                        las redes sociales de las páginas oficiales de https://www.sanmartino.gal, se regirá por
                        este
                        apartado. Así como por aquellas condiciones de uso, políticas de privacidad y normativas de
                        acceso
                        que pertenezcan a la red social que proceda en cada caso y aceptadas previamente por el usuario
                        de
                        Federación Galega de Atletismo. Se tratarán los datos con las finalidades de administrar
                        correctamente su presencia en la red social, informando de actividades, productos o servicios de
                        Federación Galega de Atletismo. Así como para cualquier otra finalidad que las normativas de las
                        redes sociales permitan. En ningún caso se utilizarán los perfiles de seguidores en redes
                        sociales
                        para enviar publicidad de manera individual.
                    </li>
                </ul>

                <p>De acuerdo a lo establecido en el reglamento general de protección de datos europeo (RGPD) 2016/679,
                    Federación Galega de Atletismo con domicilio en Avda. Glasgow, 13. 15008 A Coruña será responsable
                    del
                    tratamiento de los datos correspondientes a Usuarios de la web y suscriptores.</p>

                <p>Federación Galega de Atletismo, no vende, alquila ni cede datos de carácter personal que puedan
                    identificar al usuario, ni lo hará en el futuro, a terceros sin el consentimiento previo. Sin
                    embargo,
                    en algunos casos se pueden realizar colaboraciones con otros profesionales, en esos casos, se
                    requerirá
                    consentimiento a los usuarios informando sobre la identidad del colaborador y la finalidad de la
                    colaboración. Siempre se realizará con los más estrictos estándares de seguridad.</p>
            </div>

            <div id="legitimacion" class="mb-7">
                <h4>Legitimación para el tratamiento de tus datos</h4>

                <p>La base legal para el tratamiento de sus datos es: el consentimiento.</p>

                <p>Para contactar o realizar comentarios en esta web se requiere el consentimiento con esta política de
                    privacidad.</p>

                <p>La oferta prospectiva o comercial de productos y servicios está basada en el consentimiento que se le
                    solicita, sin que en ningún caso la retirada de este consentimiento condicione la ejecución del
                    contrato
                    de suscripción.</p>
            </div>

            <div id="categoria" class="mb-7">
                <h4>Categoría de datos</h4>

                <p>Las categorías de datos que se tratan son datos identificativos.</p>

                <p>No se tratan categorías de datos especialmente protegidos.</p>
            </div>

            <div id="tiempo" class="mb-7">
                <h4>Tiempo de conservación de los datos</h4>

                <p>Los datos personales proporcionados se conservarán:</p>

                <ul>
                    <li>Hasta que no se solicite su supresión por el interesado.</li>
                </ul>
            </div>

            <div id="almacenamiento" class="mb-7">
                <h4>Almacenamiento de los datos</h4>

                <p>Muchas herramientas que se utilizan para gestionar los datos son contratadas a terceros.</p>

                <p>Para prestar servicios estrictamente necesarios para el desarrollo de la actividad, Federación Galega
                    de
                    Atletismo, comparte datos con los siguientes prestadores bajo sus correspondientes condiciones de
                    privacidad, cumpliendo con el Reglamento General de Protección de Datos “RGPD”:</p>

                <p>Google Analytics: Google LLC, una compañía subsidiaria de Alphabet, Inc con domicilio en 1600
                    Amphitheatre Parkway, Mountain View, CA 94043, Estados Unidos. Google Analytics utiliza “cookies”,
                    que
                    son archivos de texto ubicados en tu ordenador, para ayudar a Federación Galega de Atletismo a
                    analizar
                    el uso que hacen los usuarios del sitio web. La información que genera la cookie acerca de su uso de
                    https://www.sanmartino.gal (incluyendo tu dirección IP) será directamente transmitida y
                    archivada
                    por Google en sus servidores.

                </p>

                <p>G Suite: Google Ireland Limited, con domicilio en Barrow Street, Dublín, 4, Ireland.</p>

                <p>Web: Ingeniería Gallega de Software SLU con domicilio en Calle Pasteur, 20 2ºIzq. 15008 A Coruña</p>
                <p>App Android: Ingeniería Gallega de Software SLU con domicilio en Calle Pasteur, 20 2ºIzq. 15008 A
                    Coruña</p>
                <p>App iOS: Ingeniería Gallega de Software SLU con domicilio en Calle Pasteur, 20 2ºIzq. 15008 A Coruña
                </p>

                <p>Windows Azure: Microsoft Ireland Operations Ltd con domicilio en Atrium Building Block B, Carmenhall
                    Road, Sandyford Industrial Estate, Dublín, 18, Ireland.</p>

                <p>Sendgrid: Sendgrid, Inc con domicilio en 1801 California Street, Suite 500 Denver, Colorado 80202
                    United
                    States</p>
            </div>

            <div id="navegacion" class="mb-7">
                <h4>Navegación</h4>

                <p>Al navegar por https://www.sanmartino.gal se pueden recoger datos no identificables, que pueden
                    incluir, direcciones IP, ubicación geográfica (aproximadamente), un registro de cómo se utilizan los
                    servicios y sitios, y otros datos que no pueden ser utilizados para identificar al usuario. Entre
                    los
                    datos no identificativos están también los relacionados a tus hábitos de navegación a través de
                    servicios de terceros. Esta web utiliza los siguientes servicios de análisis de terceros:</p>

                <ul>
                    <li>Google analytics</li>
                </ul>

                <p>Se utiliza esta información para analizar tendencias, administrar el sitio, rastrear los movimientos
                    de
                    los usuarios alrededor del sitio y para recopilar información demográfica sobre mi base de usuarios
                    en
                    su conjunto.</p>

            </div>

            <div id="seguridad" class="mb-7">
                <h4>Secreto y seguridad de los datos</h4>
                <p>Federación Galega de Atletismo se compromete en el uso y tratamiento de los datos incluidos
                    personales de
                    los usuarios, respetando su confidencialidad y a utilizarlos de acuerdo con la finalidad del mismo,
                    así
                    como a dar cumplimiento a su obligación de guardarlos y adaptar todas las medidas para evitar la
                    alteración, pérdida, tratamiento o acceso no autorizado, de conformidad con lo establecido en la
                    normativa vigente de protección de datos.</p>

                <p>Esta web incluye un certificado SSL. Se trata de un protocolo de seguridad que hace que tus datos
                    viajen
                    de manera íntegra y segura, es decir, la transmisión de los datos entre un servidor y usuario web, y
                    en
                    retroalimentación, es totalmente cifrada o encriptada.</p>
                <p>Federación Galega de Atletismo no puede garantizar la absoluta inexpugnabilidad de la red Internet y
                    por
                    tanto la violación de los datos mediante accesos fraudulentos a ellos por parte de terceros.</p>
                <p>Con respecto a la confidencialidad del procesamiento, Federación Galega de Atletismo se asegurará de
                    que
                    cualquier persona que esté autorizada por Federación Galega de Atletismo para procesar los datos del
                    cliente (incluido su personal, colaboradores y prestadores), estará bajo la obligación apropiada de
                    confidencialidad (ya sea un deber contractual o legal).</p>
                <p>Cuando se presente algún incidente de seguridad, al darse cuenta Federación Galega de Atletismo,
                    deberá
                    notificar al Cliente sin demoras indebidas y deberá proporcionar información oportuna relacionada
                    con el
                    Incidente de Seguridad tal como se conozca o cuando el Cliente lo solicite razonablemente.</p>

            </div>

            <div id="veracidad" class="mb-7">
                <h4>Exactitud y veracidad de los datos</h4>

                <p>Como usuario, eres el único responsable de la veracidad y corrección de los datos que remitas a
                    Federación Galega de Atletismo exonerando a Federación Galega de Atletismo, de cualquier
                    responsabilidad
                    al respecto.</p>
                <p>Los usuarios garantizan y responden, en cualquier caso, de la exactitud, vigencia y autenticidad de
                    los
                    datos personales facilitados, y se comprometen a mantenerlos debidamente actualizados. El usuario
                    acepta
                    proporcionar información completa y correcta en el formulario de contacto o suscripción.</p>
            </div>

            <div id="consentimiento" class="mb-7">
                <h4>Aceptación y consentimiento</h4>

                <p>El usuario declara haber sido informado de las condiciones sobre protección de datos de carácter
                    personal, aceptando y consintiendo el tratamiento de los mismos por parte de Federación Galega de
                    Atletismo en la forma y para las finalidades indicadas en esta política de privacidad.</p>

            </div>

            <div id="revocabilidad" class="mb-7">
                <h4>Revocabilidad</h4>

                <p>El consentimiento prestado, tanto para el tratamiento como para la cesión de los datos de los
                    interesados, es revocable en cualquier momento comunicándolo a Federación Galega de Atletismo en los
                    términos establecidos en esta Política para el ejercicio de los derechos ARCO (acceso,
                    rectificación,
                    cancelación y oposición). Esta revocación en ningún caso tendrá carácter retroactivo.</p>

            </div>

            <div id="cambios" class="mb-7">
                <h4>Cambios en la política de privacidad</h4>

                <p>Federación Galega de Atletismo se reserva el derecho a modificar la presente política para adaptarla
                    a
                    novedades legislativas o jurisprudenciales, así como a prácticas de la industria. En dichos
                    supuestos,
                    Federación Galega de Atletismo anunciará en esta página los cambios introducidos con razonable
                    antelación a su puesta en práctica.</p>

            </div>

            <div id="correos" class="mb-7">
                <h4>Correos comerciales</h4>

                <p>De acuerdo con la LSSICE, Federación Galega de Atletismo no realiza prácticas de SPAM, por lo que no
                    envía correos comerciales por vía electrónica que no hayan sido previamente solicitados o
                    autorizados
                    por el usuario. En consecuencia, en cada uno de los formularios habidos en la web, el usuario tiene
                    la
                    posibilidad de dar su consentimiento expreso para recibir el boletín, con independencia de la
                    información comercial puntualmente solicitada.</p>

                <p>Conforme a lo dispuesto en la Ley 34/2002 de Servicios de la Sociedad de la Información y de comercio
                    electrónico, Federación Galega de Atletismo se compromete a no enviar comunicaciones de carácter
                    comercial sin identificarlas debidamente.</p>

                <p>La presente política de privacidad ha sido actualizado por última vez el 05-02-2019.</p>

            </div>

        </div>
        <!-- End Content -->
    </main>
</div>