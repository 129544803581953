<div class="page-banner-area ptb-25" style="background-image: url(assets/images/shapes/page-banner-bg.jpg);">
    <div class="container">
        <div class="page-banner-content text-center">
            <h1>Aviso Legal</h1>
            <p class="text-center">Actualizado a 12 de Maio de 2023</p>
            <ul class="ps-0 mb-0 list-unstyled">
                <li class="d-inline-block position-relative"><a routerlink="/" href="/">Inicio</a></li>
                <li class="d-inline-block position-relative">Dorsais</li>
            </ul>
        </div>
    </div>
</div>
<div class="p-5">

    <main id="content" role="main">
        <!-- Content -->
        <div class="container content-space-1 content-space-md-3">
            <div class="mb-7">
                <h4>Datos del Responsable</h4>
                <ul>
                    <li><strong>Identidad del Responsable:</strong> Federación Galega de Atletismo</li>
                    <li><strong>NIF/CIF:</strong> G15103500</li>
                    <li><strong>Dirección:</strong> Avda. Glasgow, 13 15008 A Coruña</li>
                    <li><strong>Correo electrónico:</strong> info@carreirasgalegas.com</li>
                </ul>
                <p>En este espacio, el usuario, podrá encontrar toda la información relativa a los términos y
                    condiciones
                    legales que definen las relaciones entre los usuarios y yo como responsable de esta web. Como
                    usuario,
                    es importante que conozcas estos términos antes de continuar tu navegación.</p>

                <p>Federación Galega de Atletismo como responsable de esta web, asume el compromiso de procesar la
                    información de los usuarios y clientes con plenas garantías y cumplir con los requisitos nacionales
                    y
                    europeos que regulan la recopilación y uso de los datos personales de los usuarios.</p>

                <p>Esta web, por tanto, cumple rigurosamente con la Ley Orgánica 3/2018, de 5 de diciembre, de
                    Protección de
                    Datos Personales y garantía de los derechos digitales (LOPDGDD), y con el Real Decreto 1720/2007, de
                    21
                    de diciembre, conocido como el Reglamento de desarrollo de la LOPD. Cumple también con el Reglamento
                    (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016 relativo a la protección
                    de
                    las personas físicas (RGPD), así como con la Ley 34/2002, de 11 de julio, de Servicios de la
                    Sociedad de
                    la Información y Comercio Electrónico (LSSICE ó LSSI).</p>
            </div>

            <div id="condiciones" class="mb-7">
                <h4>Condiciones generales de uso</h4>

                <p>Las presentes Condiciones Generales regulan el uso (incluyendo el mero acceso) de las páginas de web,
                    integrantes del sitio web de Federación Galega de Atletismo incluidos los contenidos y servicios
                    puestos
                    a disposición en ellas. Toda persona que acceda a la web, https://www.sanmartino.gal
                    (“usuario”)
                    acepta someterse a las Condiciones Generales vigentes en cada momento del portal
                    https://www.sanmartino.gal.</p>
            </div>

            <div id="datos" class="mb-7">
                <h4>Datos personales que se recaban</h4>

                <p>Leer Política de Privacidad.</p>
            </div>

            <div id="compromisos" class="mb-7">
                <h4>Compromisos y obligaciones de los usuarios</h4>

                <p>El usuario queda informado, y acepta, que el acceso a la presente web no supone, en modo alguno, el
                    inicio de una relación comercial con Federación Galega de Atletismo. De esta forma, el usuario se
                    compromete a utilizar el sitio web, sus servicios y contenidos sin contravenir la legislación
                    vigente,
                    la buena fe y el orden público.</p>

                <p>Queda prohibido el uso de la web, con fines ilícitos o lesivos, o que, de cualquier forma, puedan
                    causar
                    perjuicio o impedir el normal funcionamiento del sitio web. Respecto de los contenidos de esta web,
                    se
                    prohíbe:</p>
                <ul>
                    <li>Su reproducción, distribución o modificación, total o parcial, a menos que se cuente con la
                        autorización de Federación Galega de Atletismo como legítimo titular;</li>
                    <li>Cualquier vulneración de los derechos del prestador como legítimo titular;</li>
                    <li>Su utilización para fines comerciales o publicitarios.</li>
                </ul>
                <p>En la utilización de la web, https://www.sanmartino.gal el usuario se compromete a no llevar a
                    cabo
                    ninguna conducta que pudiera dañar la imagen, los intereses y los derechos de Federación Galega de
                    Atletismo o de terceros o que pudiera dañar, inutilizar o sobrecargar el portal
                    https://www.sanmartino.gal o que impidiera, de cualquier forma, la normal utilización de la
                    web.
                </p>

                <p>No obstante, el usuario debe ser consciente de que las medidas de seguridad de los sistemas
                    informáticos
                    en Internet no son enteramente fiables y que, por tanto https://www.sanmartino.gal no puede
                    garantizar la inexistencia de malware u otros elementos que puedan producir alteraciones en los
                    sistemas
                    informáticos (software y hardware) del usuario o en sus documentos electrónicos y ficheros
                    contenidos en
                    los mismos aunque pongo todos los medios necesarios y las medidas de seguridad oportunas para evitar
                    la
                    presencia de estos elementos dañinos.</p>
            </div>

            <div id="seguridad" class="mb-7">
                <h4>Medidas de seguridad</h4>

                <p>Los datos personales comunicados por el usuario a Federación Galega de Atletismo pueden ser
                    almacenados
                    en bases de datos automatizadas o no, cuya titularidad corresponde en exclusiva a Federación Galega
                    de
                    Atletismo, asumiendo ésta todas las medidas de índole técnica, organizativa y de seguridad que
                    garantizan la confidencialidad, integridad y calidad de la información contenida en las mismas de
                    acuerdo con lo establecido en la normativa vigente en protección de datos.La comunicación entre los
                    usuarios y https://www.sanmartino.gal utiliza un canal seguro, y los datos transmitidos son
                    cifrados gracias a protocolos a https, por tanto, garantizo las mejores condiciones de seguridad
                    para
                    que la confidencialidad de los usuarios esté garantizada.</p>
            </div>

            <div id="conflictos" class="mb-7">
                <h4>Plataforma de resolución de conflictos</h4>

                <p>Se pone también a disposición de los usuarios la plataforma de resolución de litigios que facilita la
                    Comisión Europea y que se encuentra disponible en el siguiente enlace:
                    http://ec.europa.eu/consumers/odr/</p>
            </div>

            <div id="propiedad-intelectual" class="mb-7">
                <h4>Derechos de propiedad intelectual e industrial</h4>

                <p>En virtud de lo dispuesto en los artículos 8 y 32.1, párrafo segundo, de la Ley de Propiedad
                    Intelectual,
                    quedan expresamente prohibidas la reproducción, la distribución y la comunicación pública, incluida
                    su
                    modalidad de puesta a disposición, de la totalidad o parte de los contenidos de esta página web, con
                    fines comerciales, en cualquier soporte y por cualquier medio técnico, sin la autorización de
                    Federación
                    Galega de Atletismo. El usuario se compromete a respetar los derechos de Propiedad Intelectual e
                    Industrial titularidad de Federación Galega de Atletismo.</p>

                <p>El usuario conoce y acepta que la totalidad del sitio web, conteniendo sin carácter exhaustivo el
                    texto,
                    software, contenidos (incluyendo estructura, selección, ordenación y presentación de los mismos)
                    podcast, fotografías, material audiovisual y gráficos, está protegida por marcas, derechos de autor
                    y
                    otros derechos legítimos, de acuerdo con los tratados internacionales en los que España es parte y
                    otros
                    derechos de propiedad y leyes de España.</p>

                <p>En el caso de que un usuario o un tercero consideren que se ha producido una violación de sus
                    legítimos
                    derechos de propiedad intelectual por la introducción de un determinado contenido en la web, deberá
                    notificar dicha circunstancia a Federación Galega de Atletismo indicando:</p>

                <ul>
                    <li>Datos personales del interesado titular de los derechos presuntamente infringidos, o indicar la
                        representación con la que actúa en caso de que la reclamación la presente un tercero distinto
                        del
                        interesado.</li>
                    <li>Señalar los contenidos protegidos por los derechos de propiedad intelectual y su ubicación en la
                        web, la acreditación de los derechos de propiedad intelectual señalados y declaración expresa en
                        la
                        que el interesado se responsabiliza de la veracidad de las informaciones facilitadas en la
                        notificación.</li>
                </ul>
            </div>

            <div id="enlaces" class="mb-7">
                <h4>Enlaces externos</h4>

                <p>Las páginas de la web https://www.sanmartino.gal proporciona enlaces a otros sitios web propios
                    y
                    contenidos que son propiedad de terceros tales como:</p>

                <ul>
                    <li>Contenido de otros blogs</li>
                    <li>Herramientas</li>
                    <li>Recursos</li>
                </ul>

            </div>

            <div id="exclusión-garantías" class="mb-7">
                <h4>Exclusión de garantías y responsable</h4>
                <p><strong>Federación Galega de Atletismo no otorga ninguna garantía ni se hace responsable, en ningún
                        caso,
                        de los daños y perjuicios de cualquier naturaleza que pudieran traer causa de:</strong></p>

                <ul>
                    <li>La falta de disponibilidad, mantenimiento y efectivo funcionamiento de la web, o de sus
                        servicios y
                        contenidos;</li>
                    <li>La existencia de malware, programas maliciosos o lesivos en los contenidos;</li>
                    <li>El uso ilícito, negligente, fraudulento o contrario a este Aviso Legal;</li>
                    <li>La falta de licitud, calidad, fiabilidad, utilidad y disponibilidad de los servicios prestados
                        por
                        terceros y puestos a disposición de los usuarios en el sitio web.</li>
                    <li>El prestador no se hace responsable bajo ningún concepto de los daños que pudieran dimanar del
                        uso
                        ilegal o indebido de la presente página web.</li>
                </ul>

            </div>

            <div id="ley" class="mb-7">
                <h4>Ley aplicable y jurisdicción</h4>

                <p>Con carácter general las relaciones entre Federación Galega de Atletismo con los usuarios de sus
                    servicios telemáticos, presentes en esta web se encuentran sometidas a la legislación y jurisdicción
                    españolas y a los tribunales de A Coruña.</p>

            </div>

            <div id="contacto" class="mb-7">
                <h4>Contacto</h4>

                <p>En caso de que cualquier usuario tuviese alguna duda acerca de estas Condiciones legales o cualquier
                    comentario sobre el portal https://www.sanmartino.gal, por favor diríjase a
                    info@carreirasgalegas.com.</p>

                <p>El presente aviso legal ha sido actualizado por última vez el 05-02-2019.</p>

            </div>

        </div>
        <!-- End Content -->
    </main>