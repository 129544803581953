import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LazyLoadEvent } from "primeng/api";
import { Observable } from "rxjs";
import { EnrollmentSearchBasicModel } from "src/app/core/models/enrollment";
import { environment } from "src/environments/environment";
import { HttpParamsBuilder } from "src/shared/http-params.builder";
import { PaginatedResult } from "src/shared/paginated-result";

@Injectable()
export class EnrollmentsService {

    constructor(private http: HttpClient,) { }

    getEnrollments(competitionId: string, event: LazyLoadEvent): Observable<PaginatedResult<EnrollmentSearchBasicModel>> {
        let params = HttpParamsBuilder.fromLazyEvent(event);
        return this.http.get<PaginatedResult<EnrollmentSearchBasicModel>>(
            `${environment.api}/competitions/${competitionId}/enrollments`, { params: params }
        )
    }
}

