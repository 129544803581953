import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Email } from './email';

@Injectable()
export class ContactService {

    constructor(private http: HttpClient) { }

    sendMail(competitionId: string, email: Email) {
        return this.http.post<any>(environment.api + '/competitions/' + competitionId + '/email', email);
    }

}
