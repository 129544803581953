import { HttpParams } from "@angular/common/http";
import { LazyLoadEvent } from "primeng/api";

export class HttpParamsBuilder {
  public static fromLazyEvent(e: LazyLoadEvent): HttpParams {
    let params = new HttpParams()
      .set("searchTerm", e.globalFilter ? e.globalFilter : '')
      .set("orderBy", e.sortField ? e.sortField : 'id')
      .set("order", this.mapOrder(e.sortOrder))
      .set("index", (e.first / e.rows + 1))
      .set("size", e.rows ? e.rows : 10)


    if (e.filters) {
      Object.keys(e.filters).forEach(function (key) {
        if (key != "global") {
          if (e.filters[key].matchMode == "array") {
            e.filters[key].value.forEach((item) => {
              params = params.append(key.toString(), item.toString());
            });
          }
          else {
            params = params.set(key, e.filters[key].value);
          }
        }
      });
    }

    return params;
  }

  private static mapOrder(order: number): OrderBy {
    return order > 0 ? OrderBy.Asc : OrderBy.Desc;
  }

  public static fromIds(ids: string[]): HttpParams {
    let params: HttpParams = new HttpParams();
    for (let i in ids) {
      params = params.append("ids", ids[i]);
    }
    return params;
  }
}
enum OrderBy {
  Asc = "asc",
  Desc = "desc"
}
