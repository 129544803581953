<div class="top-header-area d-none d-lg-block">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <ul class="top-header-left-side ps-0 mb-0 list-unstyled">
                    <li class="d-inline-block position-relative">
                        <i class='bx bx-phone-call'></i>
                        <a href="tel:+34981291683">+34 981 291 683</a>
                    </li>
                    <li class="d-inline-block position-relative">
                        <i class='bx bx-envelope'></i>
                        <a href="mailto:info@carreirasgalegas.com">info@carreirasgalegas.com</a>
                    </li>
                </ul>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="top-header-right-side">
                    <p *ngIf="state == 'register'">Queda pouco para que remate o periodo de inscrición <span
                            class="style">Inscríbete!</span>
                    <p *ngIf="state == 'race'">Queda pouco para o comezo da carreira <span class="style">Moita
                            sorte!</span>
                    <p *ngIf="state == 'results'">Carreira finalizada. Xa podes consultar os resultados.
                    </p>
                    <!--<ul class="social-links ps-0 mb-0 list-unstyled">
                        <li class="d-inline-block">
                            <a href="https://www.facebook.com/carreirasgalegas" target="_blank" class="d-block">
                                <i class="bx bxl-facebook"></i>
                            </a>
                        </li>
                        <li class="d-inline-block">
                            <a href="https://x.com/carreirasgalegas" target="_blank" class="d-block">
                                <i class="bx bxl-twitter"></i>
                            </a>
                        </li>
                        <li class="d-inline-block">
                            <a href="https://www.instagram.com/carreirasgalegas" target="_blank" class="d-block">
                                <i class='bx bxl-instagram'></i>
                            </a>
                        </li>
                    </ul>
                -->
                </div>
            </div>
        </div>
    </div>
</div>