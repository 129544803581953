import { FormArray, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { User } from "firebase/auth";


export function validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
        const control = formGroup.get(field);
        if (control instanceof UntypedFormControl) {
            control.markAsTouched({ onlySelf: true });
            control.markAsDirty({ onlySelf: true });
        } else if (control instanceof UntypedFormGroup) {
            validateAllFormFields(control);
        } else if (control instanceof FormArray) {
            control.controls.forEach(item => {
                if (item instanceof UntypedFormControl) {
                    item.markAsTouched({ onlySelf: true });
                    item.markAsDirty({ onlySelf: true });
                }
                else {
                    let itemGroup: UntypedFormGroup = item as UntypedFormGroup;
                    validateAllFormFields(itemGroup);
                }
            });
        }
    });
}


export function normalizeTime(time: string) {

    if (time.length > 2) {
        return time.substring(0, 2);
    }
    else if (time.length == 2) {
        return time;
    }
    else {
        return `0${time}`;
    }

}

export function toDateOnlyString(date: Date) {
    if (!date)
        return null;
    else
        return `${date.getFullYear()}-${returnMonth()}-${returnDay()}`;

    function returnDay() {
        let d = (date.getDate() < 10) ? "0" + (date.getDate()) : date.getDate();
        return d;
    }
    function returnMonth() {
        let m = (date.getMonth() + 1 < 10) ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
        return m;
    }
}

//dd/mm/yyyy
export function formatDate(inputDate: Date) {
    let date, month, year;

    date = inputDate.getDate();
    month = inputDate.getMonth() + 1;
    year = inputDate.getFullYear();

    date = date
        .toString()
        .padStart(2, '0');

    month = month
        .toString()
        .padStart(2, '0');

    return `${date}/${month}/${year}`;
}



export function removeFileExtension(fileName: string) {
    let indexExtension = fileName.lastIndexOf('.');
    if (indexExtension === -1) return fileName;
    else return fileName.substring(0, indexExtension);
}

export function getInitials(user: User) {
    let initials = "";
    let name = "";
    if (user.displayName) {
        name = user.displayName;
    }
    else {
        name = user.email ? user.email : "";
    }
    let initialsArray = name.split(' ');
    if (initialsArray.length > 1) {
        initials += initialsArray[0][0] + initialsArray[1][0];
    }
    else {
        initials += initialsArray[0][0] + initialsArray[0][1];
    }
    return initials;
}

export function getDisplayName(user: User) {
    if (user.displayName) {
        return user.displayName;
    }
    else {
        return user.email;
    }
}
export function orderByArray(values: any[], orderType: any, orderAsc?: boolean) {
    return values.sort((a, b) => {
        if (a[orderType] < b[orderType]) {
            return !orderAsc ? -1 : 1;
        }

        if (a[orderType] > b[orderType]) {
            return !orderAsc ? 1 : -1;
        }

        return 0
    });
}

export function normalize(time: string) {

    if (time.length > 2) {
        return time.substring(0, 2);
    }
    else if (time.length == 2) {
        return time;
    }
    else {
        return `0${time}`;
    }

}



export function normalizeTitleForUrl(title: string): string {
    // Reemplazar caracteres especiales y espacios con guiones
    const normalized = title
        ?.toLowerCase()
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, '-');

    // Eliminar guiones al principio y al final del título
    const trimmed = normalized?.replace(/^-+|-+$/g, '');

    return trimmed;
}


export function addNormalizedUrl(event: any) {
    return {
        ...event,
        normalizedName: normalizeTitleForUrl(event.name)
    }
}

export function titleCase(str) {
    // Lista de palabras que no deben capitalizarse
    const doNotCapitalize = ["el", "la", "los", "las", "a", "con", "de", "en", "por", "y", "e", "del", "al", "do", "da", "ao", "ó", "o", "u", "ou"];

    // Convertir la cadena en un array de palabras
    let words = str.toLowerCase().split(' ');

    // Mapear cada palabra del array
    let capitalizedWords = words.map((word, index) => {
        // Si la palabra está en la lista de palabras que no se deben capitalizar
        // y no es la primera palabra, déjala como está.
        // De lo contrario, convierte la primera letra a mayúsculas.
        return (doNotCapitalize.includes(word) && index !== 0) ? word : word.charAt(0).toUpperCase() + word.slice(1)
    });

    // Volver a unir las palabras en una cadena y devolver el resultado
    return capitalizedWords.join(' ');
}

export function isMinor(birthdate: Date, eventDate: Date) {
    let limitDate = new Date(eventDate);
    limitDate.setFullYear(limitDate.getFullYear() - 18);
    return birthdate >= limitDate;
}

export function getInfoRecords(actualIndex: number, size: number, totalRecords: number, itemsLabel: string): string {
    let infoRecords = `Mostrando ${actualIndex + 1} 
          a  ${(actualIndex + size > totalRecords ? totalRecords : actualIndex + size)} 
          de ${totalRecords} ${itemsLabel}`;

    return infoRecords
}