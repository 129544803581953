<div class="page-banner-area ptb-25" style="background-image: url(assets/images/shapes/page-banner-bg.jpg);">
    <div class="container">
        <div class="page-banner-content text-center">
            <h1>Seguimento da carreira</h1>
            <ul class="ps-0 mb-0 list-unstyled">
                <li class="d-inline-block position-relative"><a routerlink="/" href="/">Inicio</a></li>
                <li class="d-inline-block position-relative">Seguimento</li>
            </ul>
        </div>
    </div>
</div>
<div class="map" *ngIf="event">
    <div *ngIf="event?.races?.length>0" class="row align-items-center justify-content-center mb-5">
        <ng-container *ngFor="let race of event?.races">
            <div class="col-auto" *ngIf="race.routeGpxUrl">
                <a class="btn default-btn" [ngClass]="race.id == selectedRace?.id ? 'active' : ''"
                    (click)="selectRace(race)">{{race.name}}</a>
            </div>
        </ng-container>
        <div class="race-time text-center mt-4">
            <span *ngIf="raceTime">{{convertSeconds(raceTime)}}</span>
        </div>
        <ng-container *ngIf="selectedRace && selectedRace.routeGpxUrl && !loadingMap">
            <div class="row mt-4">

                <div class="col-12 col-lg-12">
                    <div class="row real-time">
                        <div class="col-md-11 mb-2">
                            <p-autoComplete [(ngModel)]="selectedItem" [suggestions]="suggestions"
                                (completeMethod)="search($event)" (onSelect)="selectBib($event)"
                                placeholder="Busca un dorsal ou un corredor para seguir">
                                <ng-template let-result pTemplate="item">
                                    <div class="flex align-items-center gap-2">
                                        <div>{{result.bib}} - {{ result.name }} {{result.surname}}</div>
                                    </div>
                                </ng-template>
                            </p-autoComplete>
                        </div>
                        <div class="col-md-12">
                            <div class="row mb-2">
                                <div class="col-2"><span class="bib">Posto</span></div>
                                <div class="col-5"><span class="bib">Corredor</span></div>
                                <div class="col-2"><span class="bib">Control</span></div>
                                <div class="col-2"><span class="bib">Tempo</span></div>
                                <div class="col-1"></div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <ng-container *ngFor="let runnerTracking of runnersTracking">
                                <div class="row mb-2">
                                    <div class="col-2"><span class="bib">{{runnerTracking.position
                                            ?
                                            runnerTracking.position : '-'}}</span></div>
                                    <div class="col-5"><span class="bib">
                                            {{runnerTracking.name}}
                                            {{runnerTracking.surname}} [{{runnerTracking.bib}}]</span></div>
                                    <div class="col-2"><span class="bib">{{getStep(runnerTracking)}}</span>
                                    </div>
                                    <div class="col-2"><span class="bib">{{runnerTracking.time}}</span>
                                    </div>
                                    <div class="col-auto remove">
                                        <a (click)="remove(runnerTracking)"><i
                                                class="fa fa-trash"></i><span>-</span></a>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-12">
                    <div class="row">
                        <div class="col-md-12">
                            <div leaflet [leafletOptions]="options" (leafletMapReady)="onMapReady($event)">
                            </div>
                            <canvas class="d-none d-lg-block" #chartRef *ngIf="chartLoaded" height="40" baseChart
                                [options]="chartOptions" [data]="lineChartData" [type]="'line'">
                            </canvas>
                            <canvas class="d-block d-lg-none" #chartRef *ngIf="chartLoaded" height="110" baseChart
                                [options]="chartOptions" [data]="lineChartData" [type]="'line'">
                            </canvas>
                        </div>
                    </div>
                </div>
                <h3>Rankings</h3>

                <div class="col-12">
                    <div class="row real-time">
                        <div class="col-12">
                            <h4>Top 10 masculino</h4>
                            <div class="row mb-2">
                                <div class="col-2"><span class="bib">Posto</span></div>
                                <div class="col-5"><span class="bib">Corredor</span></div>
                                <div class="col-2"><span class="bib">Control</span></div>
                                <div class="col-2"><span class="bib">Tempo</span></div>
                            </div>
                            <ng-container *ngFor="let result of resultsMale.items">
                                <div class="row mb-2">
                                    <div class="col-2"><span class="bib">{{result.position
                                            ?
                                            result.position : '-'}}</span></div>
                                    <div class="col-5"><span class="bib">
                                            {{result.name}}
                                            {{result.surname}} [{{result.bib}}]</span></div>
                                    <div class="col-2"><span class="bib">{{getStep(result)}}</span>
                                    </div>
                                    <div class="col-2"><span class="bib">{{result.provisionalTime}}</span>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <div class="col-12">
                            <h4>Top 10 feminino</h4>
                            <div class="row mb-2">
                                <div class="col-2"><span class="bib">Posto</span></div>
                                <div class="col-5"><span class="bib">Corredor</span></div>
                                <div class="col-2"><span class="bib">Control</span></div>
                                <div class="col-2"><span class="bib">Tempo</span></div>
                            </div>
                            <ng-container *ngFor="let result of resultsFemale.items">
                                <div class="row mb-2">
                                    <div class="col-2"><span class="bib">{{result.position
                                            ?
                                            result.position : '-'}}</span></div>
                                    <div class="col-5"><span class="bib">
                                            {{result.name}}
                                            {{result.surname}} [{{result.bib}}]</span></div>
                                    <div class="col-2"><span class="bib">{{getStep(result)}}</span>
                                    </div>
                                    <div class="col-2"><span class="bib">{{result.provisionalTime}}</span>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>