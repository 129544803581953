import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AppService } from '../app.service';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { CircuitModel } from '../core/models/circuit';
import { CircuitsService } from './circuits.service';
import { take } from 'rxjs';
import { Capacitor } from '@capacitor/core';


@Injectable({
    providedIn: 'root'
})
export class AppInitService {
    firestoreDoc: AngularFirestoreDocument<any> | undefined;

    constructor(@Inject(DOCUMENT) private document: Document,
        private router: Router,
        private appService: AppService,
        private firestore: AngularFirestore,
        private resultsService: CircuitsService,) {
    }

    init() {

        let hostname;

        // Detecta si la aplicación se está ejecutando en una plataforma Capacitor
        if (Capacitor.isNativePlatform()) {
            // Aquí puedes establecer el hostname basado en la plataforma móvil
            hostname = 'corresan.carreirasgalegas.com'; // Asume un tenant predeterminado o lógica para determinarlo
        } else {
            // Aquí mantienes tu lógica existente para navegadores web
            hostname = window.location.hostname;
            if (environment.hostname === 'localhost') {
                console.log("window.location", window.location.pathname);
                if (window.location.pathname !== "/" && !this.isSection(window.location.pathname)) {
                    hostname = 'corresan.carreirasgalegas.com' + window.location.pathname.replace('/', '|');
                }
                else {
                    hostname = 'corresan.carreirasgalegas.com';
                }
            } else {
                if (window.location.pathname !== "/" && !this.isSection(window.location.pathname)) {
                    hostname = hostname + window.location.pathname.replace('/', '|');
                }
            }
        }

        console.log("hostname init", hostname);

        this.loadData(hostname);
    }

    loadStyle(styles: string) {
        const head = this.document.getElementsByTagName('head')[0];

        const styleTag = this.document.createElement('style');
        styleTag.innerText = styles;
        head.appendChild(styleTag);
    }

    isSection(url: string) {
        let sections = ['', 'results', 'result', 'info', 'contact', 'faq', 'routes', 'bib', 'news', 'maps', 'bibs', 'privacy', 'legal', 'streaming', 'tracking'];
        let section = sections.filter(s => s == url.split('/')[1]);

        if (section.length > 0)
            return true;
        else
            return false;
    }

    loadData(hostname: string) {
        this.getCompetitonFB(hostname);
    }

    getCompetitonFB(hostname: string) {
        console.log("hostname", hostname);
        var tenant = hostname;
        if (hostname.includes('|')) {
            tenant = hostname.split('|')[1].split('/')[0];
        }
        console.log("tenant", tenant);
        let subsCompetitonsFB = this.firestore.collectionGroup<any>(environment.firebaseCollections.competitions,
            ref => ref.where('url', '==', tenant).limit(1)).valueChanges().subscribe({
                next: (data) => {
                    console.log("getCompetitonFB data", data);
                    subsCompetitonsFB.unsubscribe();
                    if (data && data.length == 1) {
                        let event = data[0];
                        localStorage.removeItem('circuit');
                        localStorage.removeItem('event');
                        localStorage.setItem('event', JSON.stringify(event));
                        localStorage.setItem('isCircuit', 'false');
                        this.loadStyle(event.styles);
                        this.appService.tenantLoaded();
                        this.router.initialNavigation();
                    }
                    else {
                        this.getCirucitFB(hostname);
                    }
                },
                error: (e) => {
                    console.log("error", e);
                    subsCompetitonsFB.unsubscribe();
                    this.getCirucitFB(hostname);
                }
            });
    }

    getCirucitFB(hostname: string) {
        let subsCircuitFB = this.firestore.collectionGroup<any>(environment.firebaseCollections.circuits,
            ref => ref.where('url', '==', hostname).limit(1)).valueChanges().subscribe({
                next: (data) => {
                    console.log("getCirucitFB data", data);
                    subsCircuitFB.unsubscribe();
                    if (data && data.length == 1) {
                        let circuit = data[0];
                        this.getCircuitCompetitionsBD(circuit);
                    }
                },
                error: (e) => {
                    console.log("error", e);
                    subsCircuitFB.unsubscribe();
                }
            });
    }

    getCircuitCompetitionsFByIds(ids: string[], circuit: CircuitModel) {
        let idGroups: string[][] = [];
        for (let i = 0; i < ids.length; i += 10) {
            idGroups.push(ids.slice(i, i + 10));
        }

        let promises = idGroups.map(group =>
            this.firestore.collectionGroup<any>(environment.firebaseCollections.competitions,
                ref => ref.where('id', 'in', group)).get().toPromise()
        );

        Promise.all(promises).then((snapshots) => {
            let data: any[] = [];
            snapshots.forEach(snapshot => {
                const snapshotData = snapshot?.docs.map((doc: any) => doc.data());
                data = data.concat(snapshotData);
            });

            console.log("getCircuitCompetitionsFByIds", data);
            if (data) {
                circuit.events = data;
            }

            localStorage.removeItem('event');
            localStorage.removeItem('circuit');
            localStorage.setItem('circuit', JSON.stringify(circuit));
            localStorage.setItem('isCircuit', 'true');
            this.loadStyle(circuit.styles);
            this.appService.tenantLoaded();
            this.router.initialNavigation();
        })
            .catch((e) => {
                console.log("error", e);
            });
    }



    getCircuitCompetitionsBD(circuit: CircuitModel) {
        let subsCompetitons = this.resultsService.getCompetitionsByCircuit(circuit.id).pipe(take(1)).subscribe({
            next: data => {
                console.log("getCircuitCompetitionsBD data", data);
                subsCompetitons.unsubscribe();
                this.getCircuitCompetitionsFByIds(data ? data.map(d => d.id) : [], circuit)
            },
            error: error => {
                console.log("error", error);
                subsCompetitons.unsubscribe();
            }
        })
    }

}
