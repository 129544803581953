<div class="page-banner-area ptb-25" style="background-image: url(assets/images/shapes/page-banner-bg.jpg);">
    <div class="container">
        <div class="page-banner-content text-center">
            <h1>Carreira en vivo</h1>
            <ul class="ps-0 mb-0 list-unstyled">
                <li class="d-inline-block position-relative"><a routerlink="/" href="/">Inicio</a></li>
                <li class="d-inline-block position-relative">Streaming</li>
            </ul>
        </div>
    </div>
</div>

<div class="p-5">
    <iframe width="100%" height="800" src="https://www.youtube.com/embed/81n7RuvXoN0?si=NeVGTBTiWbjiQ9lA"
        title="YouTube video player" frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen></iframe>
</div>